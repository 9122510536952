import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import TrackIcon from '@material-ui/icons/Audiotrack';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FeedbackIcon from '@material-ui/icons/Feedback';
import SoundIcon from '@material-ui/icons/GraphicEq';
import GroupIcon from '@material-ui/icons/Group';
import CollectionIcon from '@material-ui/icons/LibraryMusic';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TitleIcon from '@material-ui/icons/QueueMusic';
import SettingsIcon from '@material-ui/icons/Settings';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import {useDataProvider} from '@ra-data-prisma/dataprovider';
import {createUploadLink} from 'apollo-upload-client';
import {loader} from 'graphql.macro';
import {createHashHistory} from 'history';
import React, {useMemo} from 'react';
import {Admin, convertLegacyDataProvider, Loading, Resource} from 'react-admin';
import {createMuiTheme} from '@material-ui/core/styles';
import {AccessCreate, AccessEdit, AccessList} from './resources/access';
import {
  ActivationCreate,
  ActivationEdit,
  ActivationList,
} from './resources/activation';
import {
  CollectionCreate,
  CollectionEdit,
  CollectionList,
} from './resources/collections';
import {DiscountCreate, DiscountEdit, DiscountList} from './resources/discount';
import {FeedbackList} from './resources/feedback';
import {
  InAppPurchaseCreate,
  InAppPurchaseEdit,
  InAppPurchaseList,
} from './resources/inAppPurchase';
import {ParameterEdit, ParameterList} from './resources/parameter';
import {ProfileList} from './resources/profile';
import {PromoCreate, PromoEdit, PromoList} from './resources/promo';
import {PurchaseCreate, PurchaseList} from './resources/purchase';
import {QuestionCreate, QuestionEdit, QuestionList} from './resources/question';
import {SoundCreate, SoundEdit, SoundList} from './resources/sound';
import {
  MagentaSubscriptionCreate,
  MagentaSubscriptionEdit,
  MagentaSubscriptionList,
} from './resources/subscription';
import {TitleCreate, TitleEdit, TitleList} from './resources/titles';
import {TrackCreate, TrackEdit, TrackList} from './resources/tracks';
import createAuthProvider from './utils/authProvider';
import extendDataProvider from './utils/extendDataProvider';
import {
  InAppPurchaseActivationCreate,
  InAppPurchaseActivationEdit,
  InAppPurchaseActivationList,
} from './resources/inAppPurchaseActivation';

const uploadLink = createUploadLink({uri: process.env.REACT_APP_API_URI});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

const authProvider = createAuthProvider(client);

export const history = createHashHistory();

const theme = createMuiTheme({
  palette: {
    primary: {main: '#F16571'},
  },
});

const App: React.FC = () => {
  const dataProvider = useDataProvider({
    client,
    aliasPrefix: 'admin',
    resourceViews: {
      Track: {
        resource: 'Track',
        fragment: loader('./fragments/Track.gql'),
      },
      Sound: {
        resource: 'Sound',
        fragment: loader('./fragments/Sound.gql'),
      },
      Profile: {
        resource: 'Profile',
        fragment: loader('./fragments/Profile.gql'),
      },
      Purchase: {
        resource: 'Purchase',
        fragment: loader('./fragments/Purchase.gql'),
      },
      Activation: {
        resource: 'Activation',
        fragment: loader('./fragments/Activation.gql'),
      },
    },
  });

  const extendedDataProvider = useMemo(
    () =>
      dataProvider
        ? extendDataProvider(convertLegacyDataProvider(dataProvider!), client)
        : undefined,
    [dataProvider],
  );

  if (!extendedDataProvider) {
    return <Loading />;
  }

  return (
    <ApolloProvider client={client}>
      <Admin
        theme={theme}
        history={history}
        dataProvider={extendedDataProvider}
        authProvider={authProvider}>
        <Resource
          name="Collection"
          list={CollectionList}
          create={CollectionCreate}
          edit={CollectionEdit}
          icon={CollectionIcon}
        />
        <Resource
          name="Access"
          list={AccessList}
          edit={AccessEdit}
          create={AccessCreate}
        />
        <Resource
          name="MagentaSubscription"
          options={{label: 'Magenta subscription'}}
          edit={MagentaSubscriptionEdit}
          create={MagentaSubscriptionCreate}
          list={MagentaSubscriptionList}
        />
        <Resource
          name="InAppPurchase"
          options={{label: 'In-App Purchases'}}
          edit={InAppPurchaseEdit}
          create={InAppPurchaseCreate}
          list={InAppPurchaseList}
        />
        <Resource
          name="InAppPurchaseActivation"
          options={{label: 'In-App Purchase Activations'}}
          edit={InAppPurchaseActivationEdit}
          create={InAppPurchaseActivationCreate}
          list={InAppPurchaseActivationList}
        />
        <Resource
          name="Title"
          list={TitleList}
          create={TitleCreate}
          edit={TitleEdit}
          icon={TitleIcon}
        />
        <Resource
          name="Track"
          list={TrackList}
          create={TrackCreate}
          edit={TrackEdit}
          icon={TrackIcon}
        />
        <Resource
          name="Sound"
          list={SoundList}
          create={SoundCreate}
          edit={SoundEdit}
          icon={SoundIcon}
        />
        <Resource name="Feedback" list={FeedbackList} icon={FeedbackIcon} />
        <Resource
          name="Promo"
          list={PromoList}
          create={PromoCreate}
          edit={PromoEdit}
          icon={CropFreeIcon}
        />
        <Resource
          name="Purchase"
          options={{label: 'Users'}}
          list={PurchaseList}
          create={PurchaseCreate}
          icon={GroupIcon}
        />
        <Resource name="Profile" list={ProfileList} />
        <Resource
          name="Activation"
          options={{label: 'Subscriptions'}}
          list={ActivationList}
          create={ActivationCreate}
          edit={ActivationEdit}
          icon={SubscriptionsIcon}
        />
        <Resource
          name="Parameter"
          list={ParameterList}
          edit={ParameterEdit}
          icon={SettingsIcon}
        />
        <Resource
          name="Question"
          list={QuestionList}
          create={QuestionCreate}
          edit={QuestionEdit}
          icon={QuestionAnswerIcon}
        />
        <Resource
          name="Discount"
          options={{label: 'Discount or Upgrade'}}
          list={DiscountList}
          create={DiscountCreate}
          edit={DiscountEdit}
        />
      </Admin>
    </ApolloProvider>
  );
};

export default App;
