import React from 'react';
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {FormValuesContextProvider} from '../utils/FormValuesContext';

export const InAppPurchaseActivationList = (props: any) => (
  <HNList {...props} sort={{field: 'shouldCancel', order: 'DESC'}}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        reference="InAppPurchase"
        source="inAppPurchase"
        label="In-App Purchase">
        <ChipField source="name" />
      </ReferenceField>
      <BooleanField source="willRenew" />
      <BooleanField source="shouldCancel" />
      <TextField source="purchaseId" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const InAppPurchaseActivationTitle = ({record}: any) => (
  <span>
    {record && record.id
      ? `In-App Purchase Activation "${record.id}"`
      : 'Create In-App Purchase Activation'}
  </span>
);

export const InAppPurchaseActivationForm = (props: any) => (
  <HNForm {...props}>
    <HNReferenceInput source="inAppPurchase" reference="InAppPurchase" required>
      <SelectInput optionText="name" />
    </HNReferenceInput>
    <HNReferenceInput source="activation" reference="Activation" required>
      <SelectInput optionText="id" />
    </HNReferenceInput>
    <BooleanInput source="willRenew" isRequired defaultValue={false} />
    <BooleanInput source="shouldCancel" isRequired defaultValue={false} />
    <TextInput source="purchaseId" />
  </HNForm>
);

export const InAppPurchaseActivationCreate = (props: any) => (
  <Create title={<InAppPurchaseActivationTitle />} {...props}>
    <InAppPurchaseActivationForm />
  </Create>
);

export const InAppPurchaseActivationEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<InAppPurchaseActivationTitle />} {...props}>
      <InAppPurchaseActivationForm />
    </Edit>
  </FormValuesContextProvider>
);
