import {makeStyles} from '@material-ui/core';
import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Filter,
  required,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';

const useStyles = makeStyles({
  form: {
    marginTop: 0,
  },
});

const PurchaseFilter = (props: any) => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{form: classes.form}}>
      <HNReferenceInput source="title" reference="Title" alwaysOn>
        <SelectInput optionText="name" resettable />
      </HNReferenceInput>
      <TextInput source="profile.uid" label="UID" alwaysOn />
      <TextInput source="email" label="Non-registerred user's email" alwaysOn />
    </Filter>
  );
};

export const PurchaseList = (props: any) => (
  <HNList {...props} filters={<PurchaseFilter />}>
    <Datagrid>
      <TextField source="profile.uid" label="UID" />
      <TextField source="profile.firstName" label="First name" />
      <TextField source="profile.lastName" label="Last name" />
      <TextField source="profile.email" label="User email" />
      <TextField source="email" label="Purchase email" />
      <ChipField source="title.name" label="Title" />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateEmail = required();
const validateTitle = required();

const PurchaseForm = (props: any) => (
  <HNForm {...props}>
    <TextInput source="email" validateEmail={validateEmail} />
    <HNReferenceInput source="title" reference="Title" validate={validateTitle}>
      <SelectInput optionText="name" />
    </HNReferenceInput>
  </HNForm>
);

export const PurchaseCreate = (props: any) => (
  <Create title="Create purchase" {...props}>
    <PurchaseForm />
  </Create>
);
