import React from 'react';
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FileInput,
  minLength,
  NumberInput,
  required,
  TextField,
  TextInput,
} from 'react-admin';
import AudioFileField from '../components/AudioFileField';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import {validatePosition} from '../utils/validatePosition';

export const SoundList = (props: any) => (
  <HNList {...props} sort={{field: 'position', order: 'ASC'}}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="position" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateName = [required(), minLength(1)];
const validateFile = required();

const SoundForm = (props: any) => {
  return (
    <HNForm {...props}>
      <TextInput source="name" validate={validateName} />
      <NumberInput source="position" validate={validatePosition} />
      <FileInput source="file" validate={validateFile}>
        <AudioFileField title="name" source="url" />
      </FileInput>
    </HNForm>
  );
};

export const SoundCreate = (props: any) => (
  <Create title={<SoundTitle />} {...props}>
    <SoundForm />
  </Create>
);

const SoundTitle = ({record}: any) => {
  return (
    <span>
      {record && record.name ? `Sound "${record.name}"` : 'Create Sound'}
    </span>
  );
};

export const SoundEdit = (props: any) => (
  <Edit title={<SoundTitle />} {...props}>
    <SoundForm />
  </Edit>
);
