import Paper from '@material-ui/core/Paper';
import React from 'react';
import styled from 'styled-components';
import {useFormValues} from '../utils/FormValuesContext';

const CollectionPreview: React.FC = () => {
  const {name, color} = useFormValues();

  return (
    <PreviewContainer>
      <Card color={color} collection>
        <HeaderText>{name}</HeaderText>
        <ButtonText>See all</ButtonText>
      </Card>
      <Card
        style={{
          position: 'absolute',
          left: '100%',
          transform: 'translateX(-20px)',
          top: 20,
        }}
        color={color}
      />
    </PreviewContainer>
  );
};

const PreviewContainer = styled(Paper)`
  width: 375px;
  margin-left: 1em;
  padding: 20px 40px 20px 20px;
  position: relative;
  overflow: hidden;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
  padding: 27px 18px 27px 20px;
  border-radius: 10px;
  background-blend-mode: soft-light, normal;
  box-shadow: ${({collection}) =>
    `0px 10px 25px ${
      collection ? 'rgba(41, 71, 94, 0.2)' : 'rgba(90, 72, 88, 0.2)'
    }`};
  background: ${({color, collection}: {color?: string; collection?: boolean}) =>
    collection
      ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), ${color ||
          'white'}`
      : `linear-gradient(180deg, #ffffff 0%, #faf4f9 100%)`};
  border: ${({color, collection}) =>
    collection ? 'none' : `1px solid ${color}`};
`;

const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  color: #ffffff;
`;

const HeaderText = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`;

const ButtonText = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;

export default CollectionPreview;
