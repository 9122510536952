import {ApolloClient} from '@apollo/client';
import {AuthProvider} from 'ra-core';
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
} from '../generated/graphql';

const createAuthProvider = <TCacheShape>(
  client: ApolloClient<TCacheShape>,
): AuthProvider => ({
  login: async ({username, password}) => {
    const {data, errors} = await client.mutate<
      LoginMutation,
      LoginMutationVariables
    >({
      mutation: LoginDocument,
      variables: {username, password},
    });

    if (!data) {
      if (errors) {
        throw errors[0];
      } else {
        throw new Error('login failed');
      }
    }

    localStorage.setItem('token', data.login.token);

    return data.login;
  },
  logout: async () => {
    localStorage.removeItem('token');
    await client.resetStore();
  },
  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
});

export default createAuthProvider;
