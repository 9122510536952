import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  minLength,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import {ColorField, ColorInput} from 'react-admin-color-input';
import CollectionPreview from '../components/CollectionPreview';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import {
  FormValuesContextProvider,
  FormValuesObserver,
} from '../utils/FormValuesContext';
import validateColor from '../utils/validateColor';
import {validatePosition} from '../utils/validatePosition';

// const CollectionFilter = (props: any) => (
//   <Filter {...props}>
//     <TextInput label="Search" source="q" alwaysOn />
//     <SelectInput
//       source="published"
//       alwaysOn
//       allowEmpty
//       emptyText="All"
//       choices={[
//         {id: true, name: 'Published'},
//         {id: false, name: 'Not published'},
//       ]}
//     />
//   </Filter>
// );

export const CollectionList = (props: any) => (
  <HNList {...props} sort={{field: 'position', order: 'ASC'}}>
    <Datagrid>
      <TextField label="Collection Name" source="name" />
      <ColorField label="Colour" source="color" />
      <ReferenceArrayField label="Titles" source="titles" reference="Title">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="position" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateName = [required(), minLength(1)];

const CollectionForm = (props: any) => (
  <HNForm {...props}>
    <TextInput label="Collection Name" source="name" validate={validateName} />
    <ColorInput
      label="Colour"
      source="color"
      picker="Sketch"
      validate={validateColor}
    />
    <ReferenceArrayInput
      label="Titles"
      source="titles"
      reference="Title"
      perPage={300}>
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <NumberInput
      source="position"
      label="Position"
      validate={validatePosition}
    />
    <FormValuesObserver />
  </HNForm>
);

const CollectionTitle = ({record}: any) => (
  <span>
    {record && record.name
      ? `Collection "${record.name}"`
      : 'Create Collection'}
  </span>
);

export const CollectionCreate = (props: any) => (
  <FormValuesContextProvider>
    <Create
      title={<CollectionTitle />}
      aside={<CollectionPreview />}
      {...props}>
      <CollectionForm />
    </Create>
  </FormValuesContextProvider>
);

export const CollectionEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<CollectionTitle />} aside={<CollectionPreview />} {...props}>
      <CollectionForm />
    </Edit>
  </FormValuesContextProvider>
);
