import React from 'react';
import {SimpleForm} from 'react-admin';
import {history} from '../App';

const HNForm: React.FC<any> = ({children, ...props}: any) => (
  <SimpleForm {...props} redirect={history.goBack}>
    {children}
  </SimpleForm>
);

export default HNForm;
