import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import get from 'lodash/get';
import has from 'lodash/has';
import {Record} from 'ra-core';
import React, {FunctionComponent} from 'react';
import sanitizeRestProps from './sanitizeRestProps';
import AudioPlayer from 'material-ui-audio-player';
import Typography from '@material-ui/core/Typography';

type TextAlign = 'right' | 'left';
export interface FieldProps {
  addLabel?: boolean;
  sortBy?: string;
  source?: string;
  label?: string;
  sortable?: boolean;
  className?: string;
  cellClassName?: string;
  headerClassName?: string;
  textAlign?: TextAlign;
}

// Props injected by react-admin
export interface InjectedFieldProps {
  basePath?: string;
  record?: Record;
}

const useStyles = makeStyles({
  root: {},
});

interface Props extends FieldProps {
  url?: string;
  src?: string;
  title?: string;
  target?: string;
  classes?: object;
}

interface ItemProps {
  source: string;
  title: string;
}

const useAudioPlayerStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
}));

const Item: React.FC<ItemProps> = ({source, title}) => (
  <>
    <Typography variant="subtitle1" paragraph>
      {title}
    </Typography>
    <AudioPlayer src={source} download useStyles={useAudioPlayerStyles} />
  </>
);

const getOrPath = (
  object: object | undefined,
  path: string | undefined,
  defaultValue: string | undefined = path,
) => (path && get(object, path)) || defaultValue;

export const AudioFileField: FunctionComponent<Props & InjectedFieldProps> = ({
  className,
  classes: classesOverride,
  record,
  source,
  title,
  url,
  src,
  target,
  ...rest
}) => {
  const sourceValue = source ? get(record, source) : undefined;
  const classes = useStyles({classes: classesOverride});

  const rootProps = {
    className: classnames(classes.root, className),
    ...sanitizeRestProps(rest),
  };

  if (!sourceValue) {
    return <div {...rootProps} />;
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul {...rootProps}>
        {sourceValue.map((file, index) => (
          <li key={index}>
            <Item
              source={getOrPath(file, url)}
              title={getOrPath(file, title)}
            />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div {...rootProps}>
      <Item source={sourceValue} title={getOrPath(record, title)} />
    </div>
  );
};

AudioFileField.defaultProps = {
  addLabel: true,
};

export default AudioFileField;
