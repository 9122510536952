import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import {
  BooleanInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  FormDataConsumer,
  minLength,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import AddIcon from '@material-ui/icons/Add';

interface FieldProps {
  label: string;
}
interface FullNameProps {
  record?: {
    id: string;
  };
}
const FullNameField: React.FC<FieldProps & FullNameProps> = ({record}) => {
  return (
    <a
      href={`${process.env.REACT_APP_API_URI?.replace(
        '/graphql',
        '',
      )}/promocodes?id=${record?.id}&token=${localStorage.getItem('token')}`}
      download>
      <Button>
        <GetAppIcon />
      </Button>
    </a>
  );
};

const ShowInMyCollectionField: React.FC<any> = ({record}) =>
  record?.showInMyCollection ? <AddIcon /> : null;

export const PromoList = (props: any) => (
  <HNList {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField label="Template Name" source="template" />
      <TextField source="expiration" />
      <TextField source="name" />
      <TextField source="sku" />
      <ReferenceArrayField label="Titles" source="titles" reference="Title">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label="Expiration Date" source="expirationDate" />
      <ShowInMyCollectionField source="showInMyCollection" />
      <TextField label="Count" source="count" />
      <FullNameField label="Codes" />
      <EditButton />
    </Datagrid>
  </HNList>
);

const validateName = [required(), minLength(1)];
const validateExpire = (values: any) => {
  const errors: {[key: string]: string[]} = {};
  if (!values.expiration && !values.expirationDate) {
    errors.expiration = ['At least one expiration is required'];
    errors.expirationDate = ['At least one expiration is required'];
  }
  if (values.expirationDate && values.expirationDate < new Date()) {
    errors.expirationDate = ['Expiration date must be later then now'];
  }
  if (values.useBy && values.useBy < new Date()) {
    errors.useBy = ['Use by date must be later then now'];
  }
  return errors;
};

const PromoForm = ({edit, ...props}: any) => (
  <HNForm {...props} validate={validateExpire}>
    {!edit && (
      <FormDataConsumer>
        {({formData, ...rest}: {formData: any}) => (
          <>
            {!formData.sku && (
              <>
                <TextInput
                  label="Template"
                  fullWidth
                  source="template"
                  validate={validateName}
                />
                <NumberInput
                  label="Count"
                  fullWidth
                  source="count"
                  validate={validateName}
                />
              </>
            )}
            {!formData.template && (
              <TextInput source="sku" fullWidth validate={validateName} />
            )}
          </>
        )}
      </FormDataConsumer>
    )}
    <TextInput source="name" validate={required()} />
    <TextInput source="description" multiline fullWidth />

    <FormDataConsumer>
      {({formData, ...rest}: {formData: {expirationDate?: Date}}) =>
        (!formData.expirationDate ||
          formData.expirationDate.toString() === 'Invalid Date') && (
          <NumberInput label="Days available" source="expiration" />
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({formData, ...rest}: {formData: any}) =>
        !formData.useAllTitles && (
          <ReferenceArrayInput
            label="Titles"
            source="titles"
            reference="Title"
            perPage={300}>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        )
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({formData, ...rest}: {formData: any}) =>
        !(formData.titles && formData.titles.length) && (
          <BooleanInput source="useAllTitles" />
        )
      }
    </FormDataConsumer>
    <BooleanInput source="showInMyCollection" />

    <FormDataConsumer>
      {({formData, ...rest}: {formData: any}) =>
        formData.expiration ? (
          <DateTimeInput source="useBy" />
        ) : (
          <DateTimeInput source="expirationDate" />
        )
      }
    </FormDataConsumer>
  </HNForm>
);

export const PromoCreate = (props: any) => (
  <Create title="Create promo" {...props}>
    <PromoForm />
  </Create>
);

const PromoTitle = ({record}: any) => {
  return (
    <span>
      {record && record.name ? `Promo "${record.name}"` : 'Create Promo'}
    </span>
  );
};

export const PromoEdit = (props: any) => (
  <Edit title={<PromoTitle />} {...props}>
    <PromoForm edit />
  </Edit>
);
