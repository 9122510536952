import TrackIcon from '@material-ui/icons/Audiotrack';
import dayjs from 'dayjs';
import isUrl from 'is-url';
import {stringify} from 'query-string';
import React from 'react';
import {
  BooleanInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  minLength,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import {Link} from 'react-router-dom';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {validatePosition} from '../utils/validatePosition';

// const TitleFilter = (props: any) => {
//   const classes = useStyles();

//   return (
//     <Filter {...props} classes={{form: classes.form}}>
//       <ReferenceArrayInput source="collections" reference="Collection" alwaysOn>
//         <SelectArrayInput optionText="name" resettable />
//       </ReferenceArrayInput>
//     </Filter>
//   );
// };

const RelatedTracksButton = ({record}: any) => (
  <Button
    component={Link}
    to={{
      pathname: '/Track',
      search: stringify({
        page: 1,
        perPage: 25,
        filter: JSON.stringify({title: record?.id}),
      }),
    }}
    label="Tracks">
    <TrackIcon />
  </Button>
);

export const TitleList = (props: any) => (
  <HNList {...props} sort={{field: 'position', order: 'ASC'}}>
    <Datagrid>
      <TextField label="Title Name" source="name" />
      <ReferenceArrayField
        label="Collections"
        source="collections"
        reference="Collection">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="paywallId" />
      <TextField source="position" />
      <TextField source="lastPlayed" />
      <RelatedTracksButton />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </HNList>
);

const validateName = [required(), minLength(1)];

const validateUrl = (url?: string) => {
  if (!url) return undefined;
  if (isUrl(url)) return undefined;
  return 'Provide a valid url';
};

const validateLastPlayed = (lastPlayed?: Date) => {
  if (!lastPlayed) return undefined;
  if (dayjs(lastPlayed).isBefore(new Date())) return undefined;
  return 'Last played date should be earlier than now';
};

const TitleForm = (props: any) => (
  <HNForm {...props}>
    <TextInput label="Title Name" source="name" validate={validateName} />

    <BooleanInput label="Adults only" source="adultsOnly" />

    <BooleanInput label="Free" source="isFree" />

    <BooleanInput source="showWinner" />

    <ReferenceArrayInput
      label="Collections"
      source="collections"
      reference="Collection">
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>

    <HNReferenceInput
      label="Default Sound"
      source="defaultSound"
      reference="Sound"
      perPage={300}
      allowEmpty>
      <SelectInput optionText="name" />
    </HNReferenceInput>

    <NumberInput
      source="position"
      label="Position"
      validate={validatePosition}
    />

    <TextInput source="purchaseUrl" validate={validateUrl} />

    <TextInput source="subtitle" />

    <TextInput source="sku" label="SKU" />

    <TextInput source="paywallId" label="Purchasley Paywall Vendor ID" />

    <DateTimeInput
      source="lastPlayed"
      validate={validateLastPlayed}
      parse={(date?: Date) =>
        dayjs(date).isValid() ? dayjs(date).toDate() : undefined
      }
    />

    <TextInput source="header" />

    <TextInput source="description" multiline fullWidth />
  </HNForm>
);

export const TitleCreate = (props: any) => (
  <Create title={<TitleTitle />} {...props}>
    <TitleForm />
  </Create>
);

const TitleTitle = ({record}: any) => {
  return (
    <span>
      {record && record.name ? `Title "${record.name}"` : 'Create Title'}
    </span>
  );
};

export const TitleEdit = (props: any) => (
  <Edit title={<TitleTitle />} {...props}>
    <TitleForm />
  </Edit>
);
