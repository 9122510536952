import React, {
  ComponentPropsWithoutRef,
  createContext,
  ElementType,
  useContext,
  useEffect,
  useState,
} from 'react';
import {AnyObject, useFormState} from 'react-final-form';

interface FormValuesContextType<FormValues = AnyObject> {
  formValues: FormValues;
  setFormValues: (formValues: FormValues) => void;
}

const defaultFormContext: FormValuesContextType = {
  formValues: {},
  setFormValues: () => {},
};

const FormValuesContext = createContext<FormValuesContextType>(
  defaultFormContext,
);

export const FormValuesContextProvider = <T extends ElementType>(
  props: ComponentPropsWithoutRef<T>,
) => {
  const [formValues, setFormValues] = useState({});

  return (
    <FormValuesContext.Provider
      value={{formValues: formValues, setFormValues: setFormValues}}
      {...props}
    />
  );
};

export const FormValuesObserver = () => {
  const {setFormValues} = useContext(FormValuesContext);
  const state = useFormState();

  useEffect(() => setFormValues(state.values), [setFormValues, state.values]);

  return null;
};

export const useFormValues = () => {
  const {formValues} = useContext(FormValuesContext);

  return formValues;
};
