import {find} from 'ramda';
import React, {useCallback, useState} from 'react';
import {
  BooleanInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  NumberInput,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import {FormValuesContextProvider} from '../utils/FormValuesContext';

export const AccessList = (props: any) => {
  const [shownActivations, setShownActivations] = useState<any[]>([]);
  const onPress = useCallback((record: any) => {
    setShownActivations((data) => {
      data.push(record.id);
      return [...data];
    });
  }, []);
  const render = useCallback(
    (record) =>
      find((id) => id === record.id, shownActivations) ? (
        <ReferenceArrayField
          label="Subscriptions"
          source="activations"
          reference="Activation"
          pagination={<Pagination />}
          perPage={25}>
          <SingleFieldList>
            <FunctionField
              render={(record: any) =>
                record.email ? (
                  <ChipField source="email" />
                ) : (
                  <ChipField source="profile.email" />
                )
              }
            />
          </SingleFieldList>
        </ReferenceArrayField>
      ) : (
        <Button label="load" onClick={() => onPress(record)} />
      ),
    [onPress, shownActivations],
  );
  return (
    <HNList {...props} sort={{field: 'activatedAt', order: 'DESC'}}>
      <Datagrid
        style={{
          tableLayout: 'inherit',
        }}>
        <TextField source="name" />
        <TextField source="description" />
        <FunctionField
          label="Titles"
          render={(record: any) =>
            record.useAllTitles ? (
              'All titles'
            ) : (
              <ReferenceArrayField
                label="Titles"
                source="titles"
                reference="Title">
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            )
          }
        />
        <ReferenceField
          label="Magenta subscription"
          source="subscription"
          reference="MagentaSubscription">
          <ChipField source={'name'} />
        </ReferenceField>
        <ReferenceField
          label="In-App Purchase"
          source="inAppPurchase"
          reference="InAppPurchase">
          <ChipField source={'name'} />
        </ReferenceField>
        <ReferenceField label="Promo" source="promo" reference="Promo">
          <ChipField source={'name'} />
        </ReferenceField>
        <FunctionField source="activations" render={render} />
        <DateField source="useBy" />
        <TextField source="expiration" />
        <DateField source="expirationDate" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </HNList>
  );
};

const AccessTitle = ({record}: any) => (
  <span>{record ? `Access "${record.id}"` : 'Create Access'}</span>
);

export const AccessEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<AccessTitle />} {...props}>
      <HNForm {...props}>
        <TextInput source="name" />
        <TextInput source="description" />
        <ReferenceArrayInput
          label="Titles"
          source="titles"
          reference="Title"
          perPage={300}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="useAllTitles" />
        <BooleanInput source="showInMyCollection" />
        <TextInput
          source="remindAfterSubscription"
          label="Remind after subscription(1 5 10 30 100)"
        />
        <NumberInput source="expiration" label="duration in days" />
      </HNForm>
    </Edit>
  </FormValuesContextProvider>
);

export const AccessCreate = (props: any) => (
  <FormValuesContextProvider>
    <Create title={<AccessTitle />} {...props}>
      <HNForm {...props}>
        <TextInput source="name" />
        <TextInput source="description" />
        <DateInput source="useBy" />
        <DateInput source="expirationDate" />
        <ReferenceArrayInput
          label="Titles"
          source="titles"
          reference="Title"
          perPage={300}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="useAllTitles" />
        <BooleanInput source="showInMyCollection" />
        <NumberInput source="expiration" label="duration in days" />
      </HNForm>
    </Create>
  </FormValuesContextProvider>
);
