import React from 'react';
import {
  ArrayInput,
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {FormValuesContextProvider} from '../utils/FormValuesContext';

export const InAppPurchaseList = (props: any) => (
  <HNList {...props} sort={{field: 'activatedAt', order: 'DESC'}}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="planId" label="Plan vendor id`s" />
      <ReferenceField reference="Access" source="access" label="Access">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="remindBeforeResubscription" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const InAppPurchaseTitle = ({record}: any) => (
  <span>
    {record && record.access && record.name
      ? `In-App Purchase "${record.access.name}"`
      : 'Create In-App Purchase'}
  </span>
);

export const InAppPurchaseForm = (props: any) => (
  <HNForm {...props}>
    <TextInput source="name" required />
    <ArrayInput source="planId" label="Purchasley Plan Vendor ID">
      <SimpleFormIterator>
        <TextInput source="" />
      </SimpleFormIterator>
    </ArrayInput>
    <HNReferenceInput source="access" reference="Access" required>
      <SelectInput optionText="name" />
    </HNReferenceInput>
    <NumberInput
      source="remindBeforeResubscription"
      label="Remind before resubscription(days)"
    />
  </HNForm>
);

export const InAppPurchaseCreate = (props: any) => (
  <Create title={<InAppPurchaseTitle />} {...props}>
    <InAppPurchaseForm />
  </Create>
);

export const InAppPurchaseEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<InAppPurchaseTitle />} {...props}>
      <InAppPurchaseForm />
    </Edit>
  </FormValuesContextProvider>
);
