import {makeStyles} from '@material-ui/core';
import React, {cloneElement} from 'react';
import {
  BooleanInput,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ExportButton,
  FileInput,
  Filter,
  minLength,
  NumberInput,
  required,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import AudioFileField from '../components/AudioFileField';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {validatePosition} from '../utils/validatePosition';

const useStyles = makeStyles({
  form: {
    marginTop: 0,
  },
});

const TrackFilter = (props: any) => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{form: classes.form}}>
      <HNReferenceInput source="title" reference="Title" alwaysOn>
        <SelectInput optionText="name" resettable />
      </HNReferenceInput>
    </Filter>
  );
};

const TrackActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  total,
  ...rest
}: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {hasCreate && (
      <CreateButton
        to={{
          pathname: `${basePath}/create`,
          state: {record: filterValues},
        }}
      />
    )}
    {exporter !== false && (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{...filterValues, ...permanentFilter}}
        exporter={exporter}
      />
    )}
  </TopToolbar>
);

export const TrackList = (props: any) => (
  <HNList
    {...props}
    filters={<TrackFilter />}
    actions={<TrackActions />}
    sort={{field: 'position', order: 'ASC'}}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="position" />
      <ChipField source="title.name" label="Title" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateTitle = required();
const validateName = [required(), minLength(1)];
const validateFile = required();

const TrackForm = (props: any) => (
  <HNForm {...props}>
    <HNReferenceInput
      label="Title"
      source="title.id"
      reference="Title"
      validate={validateTitle}
      perPage={100}>
      <SelectInput optionText="name" />
    </HNReferenceInput>

    <TextInput source="name" validate={validateName} />
    <FileInput source="file" accept="audio/*" validate={validateFile}>
      <AudioFileField title="name" source="url" />
    </FileInput>
    <NumberInput
      source="position"
      label="Position"
      validate={validatePosition}
    />
    <TextInput
      source="positives"
      multiline
      label="Positive statements"
      parse={(positives?: string) => positives?.split('\n')}
      format={(positives?: string[]) => positives?.join('\n')}
    />

    <BooleanInput source="soundMuted" />
    <NumberInput source="silenceStart" label="Fade in duration" />
    <NumberInput source="silenceEnd" label="Fade out duration" />
  </HNForm>
);

export const TrackCreate = (props: any) => (
  <Create title={<TrackTitle />} {...props}>
    <TrackForm />
  </Create>
);

const TrackTitle = ({record}: any) => {
  return (
    <span>
      {record && record.name ? `Track "${record.name}"` : 'Create Track'}
    </span>
  );
};

export const TrackEdit = (props: any) => (
  <Edit title={<TrackTitle />} {...props}>
    <TrackForm />
  </Edit>
);
