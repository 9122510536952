import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import {RelativePositionEnum} from '../generated/graphql';
import {FormValuesContextProvider} from '../utils/FormValuesContext';

export const DiscountList = (props: any) => (
  <HNList {...props} sort={{field: 'position', order: 'DESC'}}>
    <Datagrid>
      <ReferenceArrayField
        label="Accesses"
        source="requiredAccesses"
        reference="Access">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <NumberField source="position" />
      <NumberField source="days" />
      <ReferenceArrayField
        label="Disabled Accesses"
        source="disabledAccesses"
        reference="Access">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="paywallId" label="Purchasley Paywall Vendor ID" />
      <TextField source="relativePosition" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const DiscountTitle = ({record}: any) => (
  <span>{record.id ? `Discount "${record.id}"` : 'Create Discount'}</span>
);

const DiscountForm = (props: any) => (
  <HNForm {...props}>
    <NumberInput source="quantity" label="Quantity of accesses" isRequired />
    <ReferenceArrayInput
      perPage={300}
      label="Required Accesses"
      source="requiredAccesses"
      fullWidth
      reference="Access">
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <NumberInput
      source="days"
      label="Days before/after relative position"
      isRequired
    />
    <SelectInput
      source="relativePosition"
      choices={[
        {
          id: RelativePositionEnum.BeforeSubscriptionEnd,
          name: 'Before subscription ends',
        },
        {
          id: RelativePositionEnum.AfterSubscriptionEnd,
          name: 'After subscription ends',
        },
        {
          id: RelativePositionEnum.AfterSubscriptionStart,
          name: 'After subscription start',
        },
      ]}
      allowEmpty={false}
    />
    <ReferenceArrayInput
      perPage={300}
      label="Do not show for Accesses"
      source="disabledAccesses"
      fullWidth
      reference="Access">
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <TextInput
      source="paywallId"
      label="Purchasley Paywall Vendor ID"
      isRequired
    />
    <NumberInput source="position" label="postion(order)" isRequired />
  </HNForm>
);

export const DiscountCreate = (props: any) => (
  <Create title={<DiscountTitle />} {...props}>
    <DiscountForm {...props} />
  </Create>
);

export const DiscountEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<DiscountTitle />} {...props}>
      <DiscountForm {...props} />
    </Edit>
  </FormValuesContextProvider>
);
