import {makeStyles} from '@material-ui/core';
import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {FormValuesContextProvider} from '../utils/FormValuesContext';

const useStyles = makeStyles({
  form: {
    marginTop: 0,
  },
});

const ActivationFilter = (props: any) => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{form: classes.form}}>
      <TextInput source="profile.firstName" label="First name" alwaysOn />
      <TextInput
        source="profile.email"
        label="Registerred user's email"
        alwaysOn
      />
      <TextInput source="email" label="Non-registerred user's email" alwaysOn />

      <HNReferenceInput
        source="access.id"
        reference="Access"
        label="Access"
        perPage={10000}
        alwaysOn>
        <SelectInput optionText="name" resettable />
      </HNReferenceInput>
    </Filter>
  );
};

export const ActivationList = (props: any) => (
  <HNList
    {...props}
    filters={<ActivationFilter />}
    sort={{field: 'activatedAt', order: 'DESC'}}>
    <Datagrid>
      <TextField source="profile.email" label="Profile email" />
      <TextField source="profile.firstName" label="First name" />
      <TextField source="profile.lastName" label="Last name" />
      <TextField source="email" label="Activation email" />
      <DateField source="activatedAt" />
      <DateField source="expiration" />
      <ReferenceField reference="Access" source="access.id" label="Access">
        <ChipField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateEmail = required();
const validateAccess = required();

const ActivationTitle = ({record}: any) => (
  <span>
    {record && record.access && record.access.name
      ? `Subscription "${record.access.name}"`
      : 'Create Subscription'}
  </span>
);

export const ActivationCreate = (props: any) => (
  <Create title={<ActivationTitle />} {...props}>
    <HNForm {...props}>
      <TextInput source="email" validate={validateEmail} />
      <HNReferenceInput
        source="access.id"
        reference="Access"
        label="Access"
        validate={validateAccess}>
        <SelectInput optionText="name" />
      </HNReferenceInput>
    </HNForm>
  </Create>
);

export const ActivationEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<ActivationTitle />} {...props}>
      <HNForm {...props}>
        <ReferenceInput
          label="Access"
          source="access.id"
          reference="Access"
          perPage={300}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Profile"
          source="profile.id"
          reference="Profile"
          perPage={300}>
          <SelectInput optionText="email" />
        </ReferenceInput>
        <TextInput source="email" />
        <DateTimeInput source="expiration" />
      </HNForm>
    </Edit>
  </FormValuesContextProvider>
);
