import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  DateTime: any;
  Decimal: any;
};



export type Collection = {
  __typename?: 'Collection';
  id: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  titles: Array<Title>;
  position?: Maybe<Scalars['Float']>;
};


export type CollectionTitlesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TitleWhereUniqueInput>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  mimetype: Scalars['String'];
  encoding?: Maybe<Scalars['String']>;
  shortId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  file: File;
  url: Scalars['String'];
};

export type Sound = {
  __typename?: 'Sound';
  id: Scalars['String'];
  name: Scalars['String'];
  file: File;
  position?: Maybe<Scalars['Float']>;
};

export type Title = {
  __typename?: 'Title';
  id: Scalars['String'];
  name: Scalars['String'];
  adultsOnly: Scalars['Boolean'];
  collections: Array<Collection>;
  tracks: Array<Track>;
  sku?: Maybe<Scalars['String']>;
  showWinner: Scalars['Boolean'];
  purchaseUrl?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<Sound>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  position?: Maybe<Scalars['Float']>;
  subtitle?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree: Scalars['Boolean'];
  paywallId?: Maybe<Scalars['String']>;
  accesses: Array<Access>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  expires?: Maybe<Scalars['DateTime']>;
  collectionColor?: Maybe<Scalars['String']>;
};


export type TitleCollectionsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CollectionWhereUniqueInput>;
};


export type TitleTracksArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TrackWhereUniqueInput>;
};


export type TitleAccessesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccessWhereUniqueInput>;
};

export type Track = {
  __typename?: 'Track';
  id: Scalars['String'];
  name: Scalars['String'];
  title: Title;
  file: File;
  favoriteBy: Array<Favorite>;
  listenings: Array<Listening>;
  positives: Array<Scalars['String']>;
  position?: Maybe<Scalars['Float']>;
  silenceEnd?: Maybe<Scalars['Float']>;
  silenceStart?: Maybe<Scalars['Float']>;
  soundMuted: Scalars['Boolean'];
  listenCount?: Maybe<Scalars['Int']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
};


export type TrackFavoriteByArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FavoriteWhereUniqueInput>;
};


export type TrackListeningsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ListeningWhereUniqueInput>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['String'];
  email: Scalars['String'];
  date: Scalars['DateTime'];
  message: Scalars['String'];
  phone: Scalars['String'];
};

export type Promo = {
  __typename?: 'Promo';
  codes: Array<Code>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  access?: Maybe<Access>;
  activations: Array<Activation>;
};


export type PromoCodesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CodeWhereUniqueInput>;
};


export type PromoActivationsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ActivationWhereUniqueInput>;
};

export type Code = {
  __typename?: 'Code';
  code: Scalars['String'];
  used?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  promo: Promo;
};

export type Opening = {
  __typename?: 'Opening';
  id: Scalars['String'];
  lastPlayed?: Maybe<Scalars['DateTime']>;
  profile?: Maybe<Profile>;
  title: Title;
};

export type Favorite = {
  __typename?: 'Favorite';
  addedDate: Scalars['DateTime'];
  id: Scalars['String'];
  profile: Profile;
  track: Track;
};

/** Type for Purchase or Activation */
export type PurchaseOrActivation = Purchase | Activation;

export type Purchase = {
  __typename?: 'Purchase';
  profile?: Maybe<Profile>;
  title: Title;
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type Activation = {
  __typename?: 'Activation';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  titles: Array<Title>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  expiration: Scalars['DateTime'];
  profile?: Maybe<Profile>;
  access?: Maybe<Access>;
  code?: Maybe<Code>;
  promo?: Maybe<Promo>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivation>;
};


export type ActivationTitlesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TitleWhereUniqueInput>;
};

export type ActivationCreateInput = {
  email: Scalars['String'];
  access: AccessCreateNestedOneWithoutActivationsInput;
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['String'];
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  favorites: Array<Favorite>;
  listenings: Array<Listening>;
  activations: Array<Activation>;
  anonymousId?: Maybe<Scalars['String']>;
};


export type ProfileFavoritesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FavoriteWhereUniqueInput>;
};


export type ProfileListeningsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ListeningWhereUniqueInput>;
};


export type ProfileActivationsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ActivationWhereUniqueInput>;
};

export type Listening = {
  __typename?: 'Listening';
  id: Scalars['String'];
  profile: Profile;
  track: Track;
  count: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  username: Scalars['String'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type Parameter = {
  __typename?: 'Parameter';
  id: Scalars['String'];
  key: ParameterKey;
  value: Scalars['String'];
  notUsed?: Maybe<Scalars['Boolean']>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['String'];
  body: Scalars['String'];
  position?: Maybe<Scalars['Float']>;
  parent?: Maybe<Question>;
  screen: QuestionScreen;
};

export type ProfilePayload = {
  __typename?: 'ProfilePayload';
  refreshToken: Scalars['String'];
  accessToken: Scalars['String'];
};

export type Verification = {
  __typename?: 'Verification';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  profile: Profile;
  profileId: Scalars['String'];
  value: Scalars['String'];
};

export type Access = {
  __typename?: 'Access';
  id: Scalars['String'];
  titles: Array<Title>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['Int']>;
  activations: Array<Activation>;
  promo?: Maybe<Promo>;
  inAppPurchase?: Maybe<InAppPurchase>;
  useBy?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MagentaSubscription>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type AccessTitlesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TitleWhereUniqueInput>;
};


export type AccessActivationsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ActivationWhereUniqueInput>;
};

export type InAppPurchase = {
  __typename?: 'InAppPurchase';
  id: Scalars['String'];
  access: Access;
  planId: Array<Scalars['String']>;
  name: Scalars['String'];
  remindBeforeResubscription?: Maybe<Scalars['Int']>;
};

export type MagentaSubscription = {
  __typename?: 'MagentaSubscription';
  id: Scalars['String'];
  access: Access;
  name: Scalars['String'];
  sku: Scalars['String'];
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['String'];
  paywallId: Scalars['String'];
  days: Scalars['Int'];
  discountProposals: Array<DiscountProposal>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Float']>;
  relativePosition: RelativePositionEnum;
  disabledAccesses: Array<Access>;
  requiredAccesses: Array<Access>;
};


export type DiscountDiscountProposalsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<DiscountProposalWhereUniqueInput>;
};


export type DiscountDisabledAccessesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccessWhereUniqueInput>;
};


export type DiscountRequiredAccessesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccessWhereUniqueInput>;
};

export type DiscountProposal = {
  __typename?: 'DiscountProposal';
  discount: Discount;
  id: Scalars['String'];
  profile?: Maybe<Profile>;
};

export type InAppPurchaseActivation = {
  __typename?: 'InAppPurchaseActivation';
  id: Scalars['String'];
  activation: Activation;
  inAppPurchase: InAppPurchase;
  purchaseId: Scalars['String'];
  willRenew: Scalars['Boolean'];
  shouldCancel?: Maybe<Scalars['Boolean']>;
};

export type VerificationExpiredError = {
  __typename?: 'VerificationExpiredError';
  verification: Scalars['String'];
};

export type InvalidVerificationError = {
  __typename?: 'InvalidVerificationError';
  verification: Scalars['String'];
};

export type InvalidRefreshTokenError = {
  __typename?: 'InvalidRefreshTokenError';
  refreshToken: Scalars['String'];
};

export type TokenResult = ProfilePayload | InvalidRefreshTokenError;

export type VerificationSent = {
  __typename?: 'VerificationSent';
  expirationDate: Scalars['String'];
};

export type VerificationLoginResult = ProfilePayload | InvalidVerificationError | VerificationExpiredError;

export type TitleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CollectionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CollectionWhereInput = {
  AND?: Maybe<Array<CollectionWhereInput>>;
  OR?: Maybe<Array<CollectionWhereInput>>;
  NOT?: Maybe<Array<CollectionWhereInput>>;
  id?: Maybe<StringFilter>;
  color?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  position?: Maybe<DecimalNullableFilter>;
  titles?: Maybe<TitleListRelationFilter>;
};

export type CollectionOrderByInput = {
  id?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
};

export type CollectionCreateInput = {
  id?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  titles?: Maybe<TitleCreateNestedManyWithoutCollectionsInput>;
};

export type CollectionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutCollectionsInput>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type CollectionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FileWhereInput = {
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  encoding?: Maybe<StringNullableFilter>;
  mimetype?: Maybe<StringFilter>;
  path?: Maybe<StringFilter>;
  shortId?: Maybe<StringNullableFilter>;
  duration?: Maybe<DecimalNullableFilter>;
  sounds?: Maybe<SoundListRelationFilter>;
  tracks?: Maybe<TrackListRelationFilter>;
};

export type FileOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  encoding?: Maybe<SortOrder>;
  mimetype?: Maybe<SortOrder>;
  path?: Maybe<SortOrder>;
  shortId?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
};

export type FileCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  encoding?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  path: Scalars['String'];
  shortId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Decimal']>;
  sounds?: Maybe<SoundCreateNestedManyWithoutFileInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutFileInput>;
};

export type FileUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  encoding?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mimetype?: Maybe<StringFieldUpdateOperationsInput>;
  path?: Maybe<StringFieldUpdateOperationsInput>;
  shortId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  duration?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  sounds?: Maybe<SoundUpdateManyWithoutFileInput>;
  tracks?: Maybe<TrackUpdateManyWithoutFileInput>;
};

export type FileUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  encoding?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mimetype?: Maybe<StringFieldUpdateOperationsInput>;
  path?: Maybe<StringFieldUpdateOperationsInput>;
  shortId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  duration?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type SoundWhereInput = {
  AND?: Maybe<Array<SoundWhereInput>>;
  OR?: Maybe<Array<SoundWhereInput>>;
  NOT?: Maybe<Array<SoundWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  position?: Maybe<DecimalNullableFilter>;
  file?: Maybe<FileWhereInput>;
  fileId?: Maybe<StringFilter>;
  titles?: Maybe<TitleListRelationFilter>;
};

export type SoundOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  fileId?: Maybe<SortOrder>;
};

export type SoundWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SoundCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  file: FileCreateNestedOneWithoutSoundsInput;
  titles?: Maybe<TitleCreateNestedManyWithoutDefaultSoundInput>;
};

export type SoundUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  file?: Maybe<FileUpdateOneRequiredWithoutSoundsInput>;
  titles?: Maybe<TitleUpdateManyWithoutDefaultSoundInput>;
};

export type SoundUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type TrackWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};


export type AccessWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TitleWhereInput = {
  AND?: Maybe<Array<TitleWhereInput>>;
  OR?: Maybe<Array<TitleWhereInput>>;
  NOT?: Maybe<Array<TitleWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  adultsOnly?: Maybe<BoolFilter>;
  header?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isFree?: Maybe<BoolFilter>;
  subtitle?: Maybe<StringNullableFilter>;
  position?: Maybe<DecimalNullableFilter>;
  purchaseUrl?: Maybe<StringNullableFilter>;
  sku?: Maybe<StringNullableFilter>;
  lastPlayed?: Maybe<DateTimeNullableFilter>;
  showWinner?: Maybe<BoolFilter>;
  paywallId?: Maybe<StringNullableFilter>;
  defaultSound?: Maybe<SoundWhereInput>;
  soundId?: Maybe<StringNullableFilter>;
  openings?: Maybe<OpeningListRelationFilter>;
  purchasedBy?: Maybe<PurchaseListRelationFilter>;
  tracks?: Maybe<TrackListRelationFilter>;
  accesses?: Maybe<AccessListRelationFilter>;
  activations?: Maybe<ActivationListRelationFilter>;
  collections?: Maybe<CollectionListRelationFilter>;
  promos?: Maybe<PromoListRelationFilter>;
};

export type TitleOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  adultsOnly?: Maybe<SortOrder>;
  header?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  isFree?: Maybe<SortOrder>;
  subtitle?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  purchaseUrl?: Maybe<SortOrder>;
  sku?: Maybe<SortOrder>;
  lastPlayed?: Maybe<SortOrder>;
  showWinner?: Maybe<SortOrder>;
  paywallId?: Maybe<SortOrder>;
  soundId?: Maybe<SortOrder>;
};

export type TitleCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type TitleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type FavoriteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ListeningWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TrackWhereInput = {
  AND?: Maybe<Array<TrackWhereInput>>;
  OR?: Maybe<Array<TrackWhereInput>>;
  NOT?: Maybe<Array<TrackWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  soundMuted?: Maybe<BoolFilter>;
  silenceStart?: Maybe<DecimalFilter>;
  silenceEnd?: Maybe<DecimalFilter>;
  positives?: Maybe<StringNullableListFilter>;
  position?: Maybe<DecimalNullableFilter>;
  file?: Maybe<FileWhereInput>;
  fileId?: Maybe<StringFilter>;
  title?: Maybe<TitleWhereInput>;
  titleId?: Maybe<StringFilter>;
  favoriteBy?: Maybe<FavoriteListRelationFilter>;
  listenings?: Maybe<ListeningListRelationFilter>;
};

export type TrackOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  soundMuted?: Maybe<SortOrder>;
  silenceStart?: Maybe<SortOrder>;
  silenceEnd?: Maybe<SortOrder>;
  positives?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  fileId?: Maybe<SortOrder>;
  titleId?: Maybe<SortOrder>;
};

export type TrackCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  positives?: Maybe<TrackCreatepositivesInput>;
  file: FileCreateNestedOneWithoutTracksInput;
  title: TitleCreateNestedOneWithoutTracksInput;
  favoriteBy?: Maybe<FavoriteCreateNestedManyWithoutTrackInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutTrackInput>;
};

export type TrackUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  soundMuted?: Maybe<BoolFieldUpdateOperationsInput>;
  silenceStart?: Maybe<DecimalFieldUpdateOperationsInput>;
  silenceEnd?: Maybe<DecimalFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  positives?: Maybe<TrackUpdatepositivesInput>;
  file?: Maybe<FileUpdateOneRequiredWithoutTracksInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutTracksInput>;
  favoriteBy?: Maybe<FavoriteUpdateManyWithoutTrackInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutTrackInput>;
};

export type TrackUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  soundMuted?: Maybe<BoolFieldUpdateOperationsInput>;
  silenceStart?: Maybe<DecimalFieldUpdateOperationsInput>;
  silenceEnd?: Maybe<DecimalFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  positives?: Maybe<TrackUpdatepositivesInput>;
};

export type FeedbackWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FeedbackWhereInput = {
  AND?: Maybe<Array<FeedbackWhereInput>>;
  OR?: Maybe<Array<FeedbackWhereInput>>;
  NOT?: Maybe<Array<FeedbackWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  message?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
};

export type FeedbackOrderByInput = {
  id?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  phone?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
};

export type FeedbackCreateInput = {
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  message: Scalars['String'];
  phone: Scalars['String'];
  date: Scalars['DateTime'];
};

export type FeedbackUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  message?: Maybe<StringFieldUpdateOperationsInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedbackUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  message?: Maybe<StringFieldUpdateOperationsInput>;
  phone?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CodeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ActivationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  codeId?: Maybe<Scalars['String']>;
};

export type PromoWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  accessId?: Maybe<Scalars['String']>;
};

export type PromoWhereInput = {
  AND?: Maybe<Array<PromoWhereInput>>;
  OR?: Maybe<Array<PromoWhereInput>>;
  NOT?: Maybe<Array<PromoWhereInput>>;
  id?: Maybe<StringFilter>;
  template?: Maybe<StringNullableFilter>;
  expiration?: Maybe<IntNullableFilter>;
  count?: Maybe<IntNullableFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  useBy?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  useAllTitles?: Maybe<BoolNullableFilter>;
  sku?: Maybe<StringNullableFilter>;
  showInMyCollection?: Maybe<BoolNullableFilter>;
  access?: Maybe<AccessWhereInput>;
  accessId?: Maybe<StringNullableFilter>;
  activations?: Maybe<ActivationListRelationFilter>;
  codes?: Maybe<CodeListRelationFilter>;
  titles?: Maybe<TitleListRelationFilter>;
};

export type PromoOrderByInput = {
  id?: Maybe<SortOrder>;
  template?: Maybe<SortOrder>;
  expiration?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  useBy?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  useAllTitles?: Maybe<SortOrder>;
  sku?: Maybe<SortOrder>;
  showInMyCollection?: Maybe<SortOrder>;
  accessId?: Maybe<SortOrder>;
};

export type PromoCreateInput = {
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  access?: Maybe<AccessCreateNestedOneWithoutPromoInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutPromoInput>;
  codes?: Maybe<CodeCreateNestedManyWithoutPromoInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutPromosInput>;
};

export type PromoUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  template?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  count?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  access?: Maybe<AccessUpdateOneWithoutPromoInput>;
  activations?: Maybe<ActivationUpdateManyWithoutPromoInput>;
  codes?: Maybe<CodeUpdateManyWithoutPromoInput>;
  titles?: Maybe<TitleUpdateManyWithoutPromosInput>;
};

export type PromoUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  template?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  count?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type CodeWhereInput = {
  AND?: Maybe<Array<CodeWhereInput>>;
  OR?: Maybe<Array<CodeWhereInput>>;
  NOT?: Maybe<Array<CodeWhereInput>>;
  id?: Maybe<StringFilter>;
  used?: Maybe<BoolNullableFilter>;
  code?: Maybe<StringFilter>;
  promoId?: Maybe<StringFilter>;
  promo?: Maybe<PromoWhereInput>;
  activation?: Maybe<ActivationWhereInput>;
};

export type CodeOrderByInput = {
  id?: Maybe<SortOrder>;
  used?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  promoId?: Maybe<SortOrder>;
};

export type CodeCreateInput = {
  id?: Maybe<Scalars['String']>;
  used?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  promo: PromoCreateNestedOneWithoutCodesInput;
  activation?: Maybe<ActivationCreateNestedOneWithoutCodeInput>;
};

export type CodeUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  used?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  promo?: Maybe<PromoUpdateOneRequiredWithoutCodesInput>;
  activation?: Maybe<ActivationUpdateOneWithoutCodeInput>;
};

export type CodeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  used?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
};

export type OpeningWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type OpeningWhereInput = {
  AND?: Maybe<Array<OpeningWhereInput>>;
  OR?: Maybe<Array<OpeningWhereInput>>;
  NOT?: Maybe<Array<OpeningWhereInput>>;
  id?: Maybe<StringFilter>;
  lastPlayed?: Maybe<DateTimeNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringNullableFilter>;
  title?: Maybe<TitleWhereInput>;
  titleId?: Maybe<StringFilter>;
};

export type OpeningOrderByInput = {
  id?: Maybe<SortOrder>;
  lastPlayed?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  profileId?: Maybe<SortOrder>;
  titleId?: Maybe<SortOrder>;
};

export type OpeningCreateInput = {
  id?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileCreateNestedOneWithoutOpeningsInput>;
  title: TitleCreateNestedOneWithoutOpeningsInput;
};

export type OpeningUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutOpeningsInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutOpeningsInput>;
};

export type OpeningUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type FavoriteWhereInput = {
  AND?: Maybe<Array<FavoriteWhereInput>>;
  OR?: Maybe<Array<FavoriteWhereInput>>;
  NOT?: Maybe<Array<FavoriteWhereInput>>;
  id?: Maybe<StringFilter>;
  addedDate?: Maybe<DateTimeFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringFilter>;
  track?: Maybe<TrackWhereInput>;
  trackId?: Maybe<StringFilter>;
};

export type FavoriteOrderByInput = {
  id?: Maybe<SortOrder>;
  addedDate?: Maybe<SortOrder>;
  profileId?: Maybe<SortOrder>;
  trackId?: Maybe<SortOrder>;
};

export type FavoriteCreateInput = {
  id?: Maybe<Scalars['String']>;
  addedDate: Scalars['DateTime'];
  profile: ProfileCreateNestedOneWithoutFavoritesInput;
  track: TrackCreateNestedOneWithoutFavoriteByInput;
};

export type FavoriteUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  addedDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneRequiredWithoutFavoritesInput>;
  track?: Maybe<TrackUpdateOneRequiredWithoutFavoriteByInput>;
};

export type FavoriteUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  addedDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PurchaseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PurchaseWhereInput = {
  AND?: Maybe<Array<PurchaseWhereInput>>;
  OR?: Maybe<Array<PurchaseWhereInput>>;
  NOT?: Maybe<Array<PurchaseWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringNullableFilter>;
  title?: Maybe<TitleWhereInput>;
  titleId?: Maybe<StringFilter>;
};

export type PurchaseOrderByInput = {
  id?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  profileId?: Maybe<SortOrder>;
  titleId?: Maybe<SortOrder>;
};

export type PurchaseCreateInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileCreateNestedOneWithoutPurchasesInput>;
  title: TitleCreateNestedOneWithoutPurchasedByInput;
};

export type PurchaseUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutPurchasesInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutPurchasedByInput>;
};

export type PurchaseUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ActivationWhereInput = {
  AND?: Maybe<Array<ActivationWhereInput>>;
  OR?: Maybe<Array<ActivationWhereInput>>;
  NOT?: Maybe<Array<ActivationWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  expiration?: Maybe<DateTimeFilter>;
  useAllTitles?: Maybe<BoolNullableFilter>;
  activatedAt?: Maybe<DateTimeFilter>;
  showInMyCollection?: Maybe<BoolNullableFilter>;
  codeId?: Maybe<StringNullableFilter>;
  profileId?: Maybe<StringNullableFilter>;
  promoId?: Maybe<StringNullableFilter>;
  accessId?: Maybe<StringNullableFilter>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationWhereInput>;
  access?: Maybe<AccessWhereInput>;
  code?: Maybe<CodeWhereInput>;
  profile?: Maybe<ProfileWhereInput>;
  promo?: Maybe<PromoWhereInput>;
  titles?: Maybe<TitleListRelationFilter>;
};

export type ActivationOrderByInput = {
  id?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  expiration?: Maybe<SortOrder>;
  useAllTitles?: Maybe<SortOrder>;
  activatedAt?: Maybe<SortOrder>;
  showInMyCollection?: Maybe<SortOrder>;
  codeId?: Maybe<SortOrder>;
  profileId?: Maybe<SortOrder>;
  promoId?: Maybe<SortOrder>;
  accessId?: Maybe<SortOrder>;
};

export type ActivationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateOneWithoutActivationInput>;
  access?: Maybe<AccessUpdateOneWithoutActivationsInput>;
  code?: Maybe<CodeUpdateOneWithoutActivationInput>;
  profile?: Maybe<ProfileUpdateOneWithoutActivationsInput>;
  promo?: Maybe<PromoUpdateOneWithoutActivationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutActivationsInput>;
};

export type ActivationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ProfileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
};

export type ProfileWhereInput = {
  AND?: Maybe<Array<ProfileWhereInput>>;
  OR?: Maybe<Array<ProfileWhereInput>>;
  NOT?: Maybe<Array<ProfileWhereInput>>;
  id?: Maybe<StringFilter>;
  uid?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  anonymousId?: Maybe<StringNullableFilter>;
  activations?: Maybe<ActivationListRelationFilter>;
  favorites?: Maybe<FavoriteListRelationFilter>;
  listenings?: Maybe<ListeningListRelationFilter>;
  openings?: Maybe<OpeningListRelationFilter>;
  purchases?: Maybe<PurchaseListRelationFilter>;
  sessions?: Maybe<SessionListRelationFilter>;
  verifications?: Maybe<VerificationListRelationFilter>;
  discountProposal?: Maybe<DiscountProposalWhereInput>;
};

export type ProfileOrderByInput = {
  id?: Maybe<SortOrder>;
  uid?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  anonymousId?: Maybe<SortOrder>;
};

export type ProfileCreateInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type ProfileUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ListeningWhereInput = {
  AND?: Maybe<Array<ListeningWhereInput>>;
  OR?: Maybe<Array<ListeningWhereInput>>;
  NOT?: Maybe<Array<ListeningWhereInput>>;
  id?: Maybe<StringFilter>;
  count?: Maybe<IntFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringFilter>;
  track?: Maybe<TrackWhereInput>;
  trackId?: Maybe<StringFilter>;
};

export type ListeningOrderByInput = {
  id?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
  profileId?: Maybe<SortOrder>;
  trackId?: Maybe<SortOrder>;
};

export type ListeningCreateInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  profile: ProfileCreateNestedOneWithoutListeningsInput;
  track: TrackCreateNestedOneWithoutListeningsInput;
};

export type ListeningUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneRequiredWithoutListeningsInput>;
  track?: Maybe<TrackUpdateOneRequiredWithoutListeningsInput>;
};

export type ListeningUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  Session?: Maybe<SessionListRelationFilter>;
};

export type UserOrderByInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
  Session?: Maybe<SessionCreateNestedManyWithoutUserInput>;
};

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  Session?: Maybe<SessionUpdateManyWithoutUserInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
};

export enum ParameterKey {
  AndroidSubscriptionMarkdown = 'android_subscription_markdown',
  CardColor = 'card_color',
  LoginConfirmationText = 'login_confirmation_text',
  ContactInfoText = 'contact_info_text',
  OnboardingTextSlide_1 = 'onboarding_text_slide_1',
  OnboardingTextSlide_2 = 'onboarding_text_slide_2',
  OnboardingTextSlide_3 = 'onboarding_text_slide_3',
  OnboardingTextSlide_4 = 'onboarding_text_slide_4',
  OnboardingTextSlide_5 = 'onboarding_text_slide_5',
  OnboardingDescriptionSlide_1 = 'onboarding_description_slide_1',
  OnboardingDescriptionSlide_2 = 'onboarding_description_slide_2',
  OnboardingDescriptionSlide_3 = 'onboarding_description_slide_3',
  OnboardingDescriptionSlide_4 = 'onboarding_description_slide_4',
  OnboardingDescriptionSlide_5 = 'onboarding_description_slide_5',
  HeaderText = 'header_text',
  HeaderFontSize = 'header_font_size',
  MyCollectionRemoveHeader = 'my_collection_remove_header',
  MyCollectionRemoveDescription = 'my_collection_remove_description',
  FeedbackEmail = 'feedback_email',
  PurchaseText = 'purchase_text',
  PlayerIntroDescription = 'player_intro_description',
  PlayerIntroHeader = 'player_intro_header',
  ShowPromo = 'show_promo',
  IosSubscriptionMarkdown = 'ios_subscription_markdown',
  LoginText = 'login_text',
  PadlockIcon = 'padlock_icon',
  DaysAfterLastDiscount = 'days_after_last_discount',
  FreeTitlesPopupTitle = 'free_titles_popup_title',
  FreeTitlesPopupMarkdown = 'free_titles_popup_markdown',
  FreeTitlesPopupDuration = 'free_titles_popup_duration',
  SubscriptionReminderTitle = 'subscription_reminder_title',
  SubscriptionReminderText = 'subscription_reminder_text',
  IapSubscriptionUpgradeTitle = 'iap_subscription_upgrade_title',
  IapSubscriptionUpgradeText = 'iap_subscription_upgrade_text',
  WebsiteSubscriptionUpgradeTitle = 'website_subscription_upgrade_title',
  WebsiteSubscriptionUpgradeText = 'website_subscription_upgrade_text',
  WebsiteSubscriptionAllTitlesTitle = 'website_subscription_all_titles_title',
  WebsiteSubscriptionAllTitlesText = 'website_subscription_all_titles_text',
  EnableWebsiteSubscriptionEmail = 'enable_website_subscription_email',
  LoginAfterIapHeader = 'login_after_iap_header',
  LoginAfterIapDescription = 'login_after_iap_description',
  ShowTutorial = 'show_tutorial',
  ManageSubscriptionsText = 'manage_subscriptions_text',
  DeleteAccountButton = 'delete_account_button',
  DeleteAccountTitle = 'delete_account_title',
  DeleteAccountDescription = 'delete_account_description',
  CodeVereficationText = 'code_verefication_text'
}

export type ParameterWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  key?: Maybe<ParameterKey>;
};

export type ParameterWhereInput = {
  AND?: Maybe<Array<ParameterWhereInput>>;
  OR?: Maybe<Array<ParameterWhereInput>>;
  NOT?: Maybe<Array<ParameterWhereInput>>;
  id?: Maybe<StringFilter>;
  key?: Maybe<EnumParameterKeyFilter>;
  value?: Maybe<StringFilter>;
  notUsed?: Maybe<BoolNullableFilter>;
};

export type ParameterOrderByInput = {
  id?: Maybe<SortOrder>;
  key?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  notUsed?: Maybe<SortOrder>;
};

export type ParameterCreateInput = {
  id?: Maybe<Scalars['String']>;
  key: ParameterKey;
  value: Scalars['String'];
  notUsed?: Maybe<Scalars['Boolean']>;
};

export type ParameterUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  key?: Maybe<EnumParameterKeyFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  notUsed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type ParameterUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  key?: Maybe<EnumParameterKeyFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  notUsed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export enum QuestionScreen {
  More = 'more',
  Story = 'story'
}

export type QuestionWhereInput = {
  AND?: Maybe<Array<QuestionWhereInput>>;
  OR?: Maybe<Array<QuestionWhereInput>>;
  NOT?: Maybe<Array<QuestionWhereInput>>;
  id?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  position?: Maybe<DecimalNullableFilter>;
  screen?: Maybe<EnumQuestionScreenFilter>;
  questionId?: Maybe<StringNullableFilter>;
  parent?: Maybe<QuestionWhereInput>;
  questions?: Maybe<QuestionListRelationFilter>;
};

export type QuestionOrderByInput = {
  id?: Maybe<SortOrder>;
  body?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  screen?: Maybe<SortOrder>;
  questionId?: Maybe<SortOrder>;
};

export type QuestionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type QuestionCreateInput = {
  id?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  screen?: Maybe<QuestionScreen>;
  parent?: Maybe<QuestionCreateNestedOneWithoutQuestionsInput>;
  questions?: Maybe<QuestionCreateNestedManyWithoutParentInput>;
};

export type QuestionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  screen?: Maybe<EnumQuestionScreenFieldUpdateOperationsInput>;
  parent?: Maybe<QuestionUpdateOneWithoutQuestionsInput>;
  questions?: Maybe<QuestionUpdateManyWithoutParentInput>;
};

export type QuestionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  screen?: Maybe<EnumQuestionScreenFieldUpdateOperationsInput>;
};

export type AccessWhereInput = {
  AND?: Maybe<Array<AccessWhereInput>>;
  OR?: Maybe<Array<AccessWhereInput>>;
  NOT?: Maybe<Array<AccessWhereInput>>;
  id?: Maybe<StringFilter>;
  expiration?: Maybe<IntNullableFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  useBy?: Maybe<DateTimeNullableFilter>;
  useAllTitles?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  showInMyCollection?: Maybe<BoolNullableFilter>;
  remindAfterSubscription?: Maybe<StringNullableFilter>;
  activations?: Maybe<ActivationListRelationFilter>;
  inAppPurchase?: Maybe<InAppPurchaseWhereInput>;
  promo?: Maybe<PromoWhereInput>;
  subscription?: Maybe<MagentaSubscriptionWhereInput>;
  titles?: Maybe<TitleListRelationFilter>;
  requiringDiscounts?: Maybe<DiscountListRelationFilter>;
  disablingDiscounts?: Maybe<DiscountListRelationFilter>;
};

export type AccessOrderByInput = {
  id?: Maybe<SortOrder>;
  expiration?: Maybe<SortOrder>;
  expirationDate?: Maybe<SortOrder>;
  useBy?: Maybe<SortOrder>;
  useAllTitles?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  showInMyCollection?: Maybe<SortOrder>;
  remindAfterSubscription?: Maybe<SortOrder>;
};

export type AccessCreateInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type AccessUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type InAppPurchaseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  planId?: Maybe<Array<Scalars['String']>>;
  accessId?: Maybe<Scalars['String']>;
};

export type InAppPurchaseWhereInput = {
  AND?: Maybe<Array<InAppPurchaseWhereInput>>;
  OR?: Maybe<Array<InAppPurchaseWhereInput>>;
  NOT?: Maybe<Array<InAppPurchaseWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  planId?: Maybe<StringNullableListFilter>;
  remindBeforeResubscription?: Maybe<IntNullableFilter>;
  access?: Maybe<AccessWhereInput>;
  accessId?: Maybe<StringFilter>;
  InAppPurchaseActivation?: Maybe<InAppPurchaseActivationListRelationFilter>;
};

export type InAppPurchaseOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  remindBeforeResubscription?: Maybe<SortOrder>;
  accessId?: Maybe<SortOrder>;
};

export type InAppPurchaseCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  remindBeforeResubscription?: Maybe<Scalars['Int']>;
  planId?: Maybe<InAppPurchaseCreateplanIdInput>;
  access: AccessCreateNestedOneWithoutInAppPurchaseInput;
  InAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedManyWithoutInAppPurchaseInput>;
};

export type InAppPurchaseUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  remindBeforeResubscription?: Maybe<NullableIntFieldUpdateOperationsInput>;
  planId?: Maybe<InAppPurchaseUpdateplanIdInput>;
  access?: Maybe<AccessUpdateOneRequiredWithoutInAppPurchaseInput>;
  InAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateManyWithoutInAppPurchaseInput>;
};

export type InAppPurchaseUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  remindBeforeResubscription?: Maybe<NullableIntFieldUpdateOperationsInput>;
  planId?: Maybe<InAppPurchaseUpdateplanIdInput>;
};

export type MagentaSubscriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  accessId?: Maybe<Scalars['String']>;
};

export type MagentaSubscriptionWhereInput = {
  AND?: Maybe<Array<MagentaSubscriptionWhereInput>>;
  OR?: Maybe<Array<MagentaSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MagentaSubscriptionWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  sku?: Maybe<StringFilter>;
  access?: Maybe<AccessWhereInput>;
  accessId?: Maybe<StringFilter>;
};

export type MagentaSubscriptionOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  sku?: Maybe<SortOrder>;
  accessId?: Maybe<SortOrder>;
};

export type MagentaSubscriptionCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sku: Scalars['String'];
  access: AccessCreateNestedOneWithoutSubscriptionInput;
};

export type MagentaSubscriptionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
  access?: Maybe<AccessUpdateOneRequiredWithoutSubscriptionInput>;
};

export type MagentaSubscriptionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
};

export type DiscountProposalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export enum RelativePositionEnum {
  BeforeSubscriptionEnd = 'before_subscription_end',
  AfterSubscriptionEnd = 'after_subscription_end',
  AfterSubscriptionStart = 'after_subscription_start'
}

export type DiscountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DiscountWhereInput = {
  AND?: Maybe<Array<DiscountWhereInput>>;
  OR?: Maybe<Array<DiscountWhereInput>>;
  NOT?: Maybe<Array<DiscountWhereInput>>;
  id?: Maybe<StringFilter>;
  quantity?: Maybe<IntNullableFilter>;
  paywallId?: Maybe<StringFilter>;
  days?: Maybe<IntFilter>;
  relativePosition?: Maybe<EnumRelativePositionEnumFilter>;
  position?: Maybe<IntFilter>;
  requiredAccesses?: Maybe<AccessListRelationFilter>;
  discountProposals?: Maybe<DiscountProposalListRelationFilter>;
  disabledAccesses?: Maybe<AccessListRelationFilter>;
};

export type DiscountOrderByInput = {
  id?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  paywallId?: Maybe<SortOrder>;
  days?: Maybe<SortOrder>;
  relativePosition?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
};

export type DiscountCreateInput = {
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  paywallId: Scalars['String'];
  days: Scalars['Int'];
  relativePosition: RelativePositionEnum;
  position: Scalars['Int'];
  requiredAccesses?: Maybe<AccessCreateNestedManyWithoutRequiringDiscountsInput>;
  discountProposals?: Maybe<DiscountProposalCreateNestedManyWithoutDiscountInput>;
  disabledAccesses?: Maybe<AccessCreateNestedManyWithoutDisablingDiscountsInput>;
};

export type DiscountUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  paywallId?: Maybe<StringFieldUpdateOperationsInput>;
  days?: Maybe<IntFieldUpdateOperationsInput>;
  relativePosition?: Maybe<EnumRelativePositionEnumFieldUpdateOperationsInput>;
  position?: Maybe<IntFieldUpdateOperationsInput>;
  requiredAccesses?: Maybe<AccessUpdateManyWithoutRequiringDiscountsInput>;
  discountProposals?: Maybe<DiscountProposalUpdateManyWithoutDiscountInput>;
  disabledAccesses?: Maybe<AccessUpdateManyWithoutDisablingDiscountsInput>;
};

export type DiscountUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  paywallId?: Maybe<StringFieldUpdateOperationsInput>;
  days?: Maybe<IntFieldUpdateOperationsInput>;
  relativePosition?: Maybe<EnumRelativePositionEnumFieldUpdateOperationsInput>;
  position?: Maybe<IntFieldUpdateOperationsInput>;
};

export type DiscountProposalWhereInput = {
  AND?: Maybe<Array<DiscountProposalWhereInput>>;
  OR?: Maybe<Array<DiscountProposalWhereInput>>;
  NOT?: Maybe<Array<DiscountProposalWhereInput>>;
  id?: Maybe<StringFilter>;
  discount?: Maybe<DiscountWhereInput>;
  discountId?: Maybe<StringFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringNullableFilter>;
};

export type DiscountProposalOrderByInput = {
  id?: Maybe<SortOrder>;
  discountId?: Maybe<SortOrder>;
  profileId?: Maybe<SortOrder>;
};

export type DiscountProposalCreateInput = {
  id?: Maybe<Scalars['String']>;
  discount: DiscountCreateNestedOneWithoutDiscountProposalsInput;
  profile?: Maybe<ProfileCreateNestedOneWithoutDiscountProposalInput>;
};

export type DiscountProposalUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<DiscountUpdateOneRequiredWithoutDiscountProposalsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutDiscountProposalInput>;
};

export type DiscountProposalUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type InAppPurchaseActivationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  activationId?: Maybe<Scalars['String']>;
};

export type InAppPurchaseActivationWhereInput = {
  AND?: Maybe<Array<InAppPurchaseActivationWhereInput>>;
  OR?: Maybe<Array<InAppPurchaseActivationWhereInput>>;
  NOT?: Maybe<Array<InAppPurchaseActivationWhereInput>>;
  id?: Maybe<StringFilter>;
  willRenew?: Maybe<BoolFilter>;
  shouldCancel?: Maybe<BoolNullableFilter>;
  purchaseId?: Maybe<StringFilter>;
  platform?: Maybe<EnumInAppPurchasePlatformFilter>;
  inAppPurchase?: Maybe<InAppPurchaseWhereInput>;
  inAppPurchaseId?: Maybe<StringFilter>;
  activation?: Maybe<ActivationWhereInput>;
  activationId?: Maybe<StringFilter>;
};

export type InAppPurchaseActivationOrderByInput = {
  id?: Maybe<SortOrder>;
  willRenew?: Maybe<SortOrder>;
  shouldCancel?: Maybe<SortOrder>;
  purchaseId?: Maybe<SortOrder>;
  platform?: Maybe<SortOrder>;
  inAppPurchaseId?: Maybe<SortOrder>;
  activationId?: Maybe<SortOrder>;
};

export type InAppPurchaseActivationCreateInput = {
  id?: Maybe<Scalars['String']>;
  willRenew: Scalars['Boolean'];
  shouldCancel?: Maybe<Scalars['Boolean']>;
  purchaseId: Scalars['String'];
  platform?: Maybe<InAppPurchasePlatform>;
  inAppPurchase: InAppPurchaseCreateNestedOneWithoutInAppPurchaseActivationInput;
  activation: ActivationCreateNestedOneWithoutInAppPurchaseActivationInput;
};

export type InAppPurchaseActivationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  willRenew?: Maybe<BoolFieldUpdateOperationsInput>;
  shouldCancel?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  purchaseId?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumInAppPurchasePlatformFieldUpdateOperationsInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneRequiredWithoutInAppPurchaseActivationInput>;
  activation?: Maybe<ActivationUpdateOneRequiredWithoutInAppPurchaseActivationInput>;
};

export type InAppPurchaseActivationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  willRenew?: Maybe<BoolFieldUpdateOperationsInput>;
  shouldCancel?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  purchaseId?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumInAppPurchasePlatformFieldUpdateOperationsInput>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type DecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
};

export type TitleListRelationFilter = {
  every?: Maybe<TitleWhereInput>;
  some?: Maybe<TitleWhereInput>;
  none?: Maybe<TitleWhereInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}


export type TitleCreateNestedManyWithoutCollectionsInput = {
  create?: Maybe<Array<TitleCreateWithoutCollectionsInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutCollectionsInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Decimal']>;
  increment?: Maybe<Scalars['Decimal']>;
  decrement?: Maybe<Scalars['Decimal']>;
  multiply?: Maybe<Scalars['Decimal']>;
  divide?: Maybe<Scalars['Decimal']>;
};

export type TitleUpdateManyWithoutCollectionsInput = {
  create?: Maybe<Array<TitleCreateWithoutCollectionsInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutCollectionsInput>>;
  upsert?: Maybe<Array<TitleUpsertWithWhereUniqueWithoutCollectionsInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
  set?: Maybe<Array<TitleWhereUniqueInput>>;
  disconnect?: Maybe<Array<TitleWhereUniqueInput>>;
  delete?: Maybe<Array<TitleWhereUniqueInput>>;
  update?: Maybe<Array<TitleUpdateWithWhereUniqueWithoutCollectionsInput>>;
  updateMany?: Maybe<Array<TitleUpdateManyWithWhereWithoutCollectionsInput>>;
  deleteMany?: Maybe<Array<TitleScalarWhereInput>>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type SoundListRelationFilter = {
  every?: Maybe<SoundWhereInput>;
  some?: Maybe<SoundWhereInput>;
  none?: Maybe<SoundWhereInput>;
};

export type TrackListRelationFilter = {
  every?: Maybe<TrackWhereInput>;
  some?: Maybe<TrackWhereInput>;
  none?: Maybe<TrackWhereInput>;
};

export type SoundCreateNestedManyWithoutFileInput = {
  create?: Maybe<Array<SoundCreateWithoutFileInput>>;
  connectOrCreate?: Maybe<Array<SoundCreateOrConnectWithoutFileInput>>;
  createMany?: Maybe<SoundCreateManyFileInputEnvelope>;
  connect?: Maybe<Array<SoundWhereUniqueInput>>;
};

export type TrackCreateNestedManyWithoutFileInput = {
  create?: Maybe<Array<TrackCreateWithoutFileInput>>;
  connectOrCreate?: Maybe<Array<TrackCreateOrConnectWithoutFileInput>>;
  createMany?: Maybe<TrackCreateManyFileInputEnvelope>;
  connect?: Maybe<Array<TrackWhereUniqueInput>>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type SoundUpdateManyWithoutFileInput = {
  create?: Maybe<Array<SoundCreateWithoutFileInput>>;
  connectOrCreate?: Maybe<Array<SoundCreateOrConnectWithoutFileInput>>;
  upsert?: Maybe<Array<SoundUpsertWithWhereUniqueWithoutFileInput>>;
  createMany?: Maybe<SoundCreateManyFileInputEnvelope>;
  connect?: Maybe<Array<SoundWhereUniqueInput>>;
  set?: Maybe<Array<SoundWhereUniqueInput>>;
  disconnect?: Maybe<Array<SoundWhereUniqueInput>>;
  delete?: Maybe<Array<SoundWhereUniqueInput>>;
  update?: Maybe<Array<SoundUpdateWithWhereUniqueWithoutFileInput>>;
  updateMany?: Maybe<Array<SoundUpdateManyWithWhereWithoutFileInput>>;
  deleteMany?: Maybe<Array<SoundScalarWhereInput>>;
};

export type TrackUpdateManyWithoutFileInput = {
  create?: Maybe<Array<TrackCreateWithoutFileInput>>;
  connectOrCreate?: Maybe<Array<TrackCreateOrConnectWithoutFileInput>>;
  upsert?: Maybe<Array<TrackUpsertWithWhereUniqueWithoutFileInput>>;
  createMany?: Maybe<TrackCreateManyFileInputEnvelope>;
  connect?: Maybe<Array<TrackWhereUniqueInput>>;
  set?: Maybe<Array<TrackWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrackWhereUniqueInput>>;
  delete?: Maybe<Array<TrackWhereUniqueInput>>;
  update?: Maybe<Array<TrackUpdateWithWhereUniqueWithoutFileInput>>;
  updateMany?: Maybe<Array<TrackUpdateManyWithWhereWithoutFileInput>>;
  deleteMany?: Maybe<Array<TrackScalarWhereInput>>;
};

export type FileCreateNestedOneWithoutSoundsInput = {
  create?: Maybe<FileCreateWithoutSoundsInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutSoundsInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type TitleCreateNestedManyWithoutDefaultSoundInput = {
  create?: Maybe<Array<TitleCreateWithoutDefaultSoundInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutDefaultSoundInput>>;
  createMany?: Maybe<TitleCreateManyDefaultSoundInputEnvelope>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
};

export type FileUpdateOneRequiredWithoutSoundsInput = {
  create?: Maybe<FileCreateWithoutSoundsInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutSoundsInput>;
  upsert?: Maybe<FileUpsertWithoutSoundsInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  update?: Maybe<FileUpdateWithoutSoundsInput>;
};

export type TitleUpdateManyWithoutDefaultSoundInput = {
  create?: Maybe<Array<TitleCreateWithoutDefaultSoundInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutDefaultSoundInput>>;
  upsert?: Maybe<Array<TitleUpsertWithWhereUniqueWithoutDefaultSoundInput>>;
  createMany?: Maybe<TitleCreateManyDefaultSoundInputEnvelope>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
  set?: Maybe<Array<TitleWhereUniqueInput>>;
  disconnect?: Maybe<Array<TitleWhereUniqueInput>>;
  delete?: Maybe<Array<TitleWhereUniqueInput>>;
  update?: Maybe<Array<TitleUpdateWithWhereUniqueWithoutDefaultSoundInput>>;
  updateMany?: Maybe<Array<TitleUpdateManyWithWhereWithoutDefaultSoundInput>>;
  deleteMany?: Maybe<Array<TitleScalarWhereInput>>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type OpeningListRelationFilter = {
  every?: Maybe<OpeningWhereInput>;
  some?: Maybe<OpeningWhereInput>;
  none?: Maybe<OpeningWhereInput>;
};

export type PurchaseListRelationFilter = {
  every?: Maybe<PurchaseWhereInput>;
  some?: Maybe<PurchaseWhereInput>;
  none?: Maybe<PurchaseWhereInput>;
};

export type AccessListRelationFilter = {
  every?: Maybe<AccessWhereInput>;
  some?: Maybe<AccessWhereInput>;
  none?: Maybe<AccessWhereInput>;
};

export type ActivationListRelationFilter = {
  every?: Maybe<ActivationWhereInput>;
  some?: Maybe<ActivationWhereInput>;
  none?: Maybe<ActivationWhereInput>;
};

export type CollectionListRelationFilter = {
  every?: Maybe<CollectionWhereInput>;
  some?: Maybe<CollectionWhereInput>;
  none?: Maybe<CollectionWhereInput>;
};

export type PromoListRelationFilter = {
  every?: Maybe<PromoWhereInput>;
  some?: Maybe<PromoWhereInput>;
  none?: Maybe<PromoWhereInput>;
};

export type SoundCreateNestedOneWithoutTitlesInput = {
  create?: Maybe<SoundCreateWithoutTitlesInput>;
  connectOrCreate?: Maybe<SoundCreateOrConnectWithoutTitlesInput>;
  connect?: Maybe<SoundWhereUniqueInput>;
};

export type OpeningCreateNestedManyWithoutTitleInput = {
  create?: Maybe<Array<OpeningCreateWithoutTitleInput>>;
  connectOrCreate?: Maybe<Array<OpeningCreateOrConnectWithoutTitleInput>>;
  createMany?: Maybe<OpeningCreateManyTitleInputEnvelope>;
  connect?: Maybe<Array<OpeningWhereUniqueInput>>;
};

export type PurchaseCreateNestedManyWithoutTitleInput = {
  create?: Maybe<Array<PurchaseCreateWithoutTitleInput>>;
  connectOrCreate?: Maybe<Array<PurchaseCreateOrConnectWithoutTitleInput>>;
  createMany?: Maybe<PurchaseCreateManyTitleInputEnvelope>;
  connect?: Maybe<Array<PurchaseWhereUniqueInput>>;
};

export type TrackCreateNestedManyWithoutTitleInput = {
  create?: Maybe<Array<TrackCreateWithoutTitleInput>>;
  connectOrCreate?: Maybe<Array<TrackCreateOrConnectWithoutTitleInput>>;
  createMany?: Maybe<TrackCreateManyTitleInputEnvelope>;
  connect?: Maybe<Array<TrackWhereUniqueInput>>;
};

export type AccessCreateNestedManyWithoutTitlesInput = {
  create?: Maybe<Array<AccessCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<AccessCreateOrConnectWithoutTitlesInput>>;
  connect?: Maybe<Array<AccessWhereUniqueInput>>;
};

export type ActivationCreateNestedManyWithoutTitlesInput = {
  create?: Maybe<Array<ActivationCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutTitlesInput>>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
};

export type CollectionCreateNestedManyWithoutTitlesInput = {
  create?: Maybe<Array<CollectionCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<CollectionCreateOrConnectWithoutTitlesInput>>;
  connect?: Maybe<Array<CollectionWhereUniqueInput>>;
};

export type PromoCreateNestedManyWithoutTitlesInput = {
  create?: Maybe<Array<PromoCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutTitlesInput>>;
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type SoundUpdateOneWithoutTitlesInput = {
  create?: Maybe<SoundCreateWithoutTitlesInput>;
  connectOrCreate?: Maybe<SoundCreateOrConnectWithoutTitlesInput>;
  upsert?: Maybe<SoundUpsertWithoutTitlesInput>;
  connect?: Maybe<SoundWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SoundUpdateWithoutTitlesInput>;
};

export type OpeningUpdateManyWithoutTitleInput = {
  create?: Maybe<Array<OpeningCreateWithoutTitleInput>>;
  connectOrCreate?: Maybe<Array<OpeningCreateOrConnectWithoutTitleInput>>;
  upsert?: Maybe<Array<OpeningUpsertWithWhereUniqueWithoutTitleInput>>;
  createMany?: Maybe<OpeningCreateManyTitleInputEnvelope>;
  connect?: Maybe<Array<OpeningWhereUniqueInput>>;
  set?: Maybe<Array<OpeningWhereUniqueInput>>;
  disconnect?: Maybe<Array<OpeningWhereUniqueInput>>;
  delete?: Maybe<Array<OpeningWhereUniqueInput>>;
  update?: Maybe<Array<OpeningUpdateWithWhereUniqueWithoutTitleInput>>;
  updateMany?: Maybe<Array<OpeningUpdateManyWithWhereWithoutTitleInput>>;
  deleteMany?: Maybe<Array<OpeningScalarWhereInput>>;
};

export type PurchaseUpdateManyWithoutTitleInput = {
  create?: Maybe<Array<PurchaseCreateWithoutTitleInput>>;
  connectOrCreate?: Maybe<Array<PurchaseCreateOrConnectWithoutTitleInput>>;
  upsert?: Maybe<Array<PurchaseUpsertWithWhereUniqueWithoutTitleInput>>;
  createMany?: Maybe<PurchaseCreateManyTitleInputEnvelope>;
  connect?: Maybe<Array<PurchaseWhereUniqueInput>>;
  set?: Maybe<Array<PurchaseWhereUniqueInput>>;
  disconnect?: Maybe<Array<PurchaseWhereUniqueInput>>;
  delete?: Maybe<Array<PurchaseWhereUniqueInput>>;
  update?: Maybe<Array<PurchaseUpdateWithWhereUniqueWithoutTitleInput>>;
  updateMany?: Maybe<Array<PurchaseUpdateManyWithWhereWithoutTitleInput>>;
  deleteMany?: Maybe<Array<PurchaseScalarWhereInput>>;
};

export type TrackUpdateManyWithoutTitleInput = {
  create?: Maybe<Array<TrackCreateWithoutTitleInput>>;
  connectOrCreate?: Maybe<Array<TrackCreateOrConnectWithoutTitleInput>>;
  upsert?: Maybe<Array<TrackUpsertWithWhereUniqueWithoutTitleInput>>;
  createMany?: Maybe<TrackCreateManyTitleInputEnvelope>;
  connect?: Maybe<Array<TrackWhereUniqueInput>>;
  set?: Maybe<Array<TrackWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrackWhereUniqueInput>>;
  delete?: Maybe<Array<TrackWhereUniqueInput>>;
  update?: Maybe<Array<TrackUpdateWithWhereUniqueWithoutTitleInput>>;
  updateMany?: Maybe<Array<TrackUpdateManyWithWhereWithoutTitleInput>>;
  deleteMany?: Maybe<Array<TrackScalarWhereInput>>;
};

export type AccessUpdateManyWithoutTitlesInput = {
  create?: Maybe<Array<AccessCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<AccessCreateOrConnectWithoutTitlesInput>>;
  upsert?: Maybe<Array<AccessUpsertWithWhereUniqueWithoutTitlesInput>>;
  connect?: Maybe<Array<AccessWhereUniqueInput>>;
  set?: Maybe<Array<AccessWhereUniqueInput>>;
  disconnect?: Maybe<Array<AccessWhereUniqueInput>>;
  delete?: Maybe<Array<AccessWhereUniqueInput>>;
  update?: Maybe<Array<AccessUpdateWithWhereUniqueWithoutTitlesInput>>;
  updateMany?: Maybe<Array<AccessUpdateManyWithWhereWithoutTitlesInput>>;
  deleteMany?: Maybe<Array<AccessScalarWhereInput>>;
};

export type ActivationUpdateManyWithoutTitlesInput = {
  create?: Maybe<Array<ActivationCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutTitlesInput>>;
  upsert?: Maybe<Array<ActivationUpsertWithWhereUniqueWithoutTitlesInput>>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
  set?: Maybe<Array<ActivationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActivationWhereUniqueInput>>;
  delete?: Maybe<Array<ActivationWhereUniqueInput>>;
  update?: Maybe<Array<ActivationUpdateWithWhereUniqueWithoutTitlesInput>>;
  updateMany?: Maybe<Array<ActivationUpdateManyWithWhereWithoutTitlesInput>>;
  deleteMany?: Maybe<Array<ActivationScalarWhereInput>>;
};

export type CollectionUpdateManyWithoutTitlesInput = {
  create?: Maybe<Array<CollectionCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<CollectionCreateOrConnectWithoutTitlesInput>>;
  upsert?: Maybe<Array<CollectionUpsertWithWhereUniqueWithoutTitlesInput>>;
  connect?: Maybe<Array<CollectionWhereUniqueInput>>;
  set?: Maybe<Array<CollectionWhereUniqueInput>>;
  disconnect?: Maybe<Array<CollectionWhereUniqueInput>>;
  delete?: Maybe<Array<CollectionWhereUniqueInput>>;
  update?: Maybe<Array<CollectionUpdateWithWhereUniqueWithoutTitlesInput>>;
  updateMany?: Maybe<Array<CollectionUpdateManyWithWhereWithoutTitlesInput>>;
  deleteMany?: Maybe<Array<CollectionScalarWhereInput>>;
};

export type PromoUpdateManyWithoutTitlesInput = {
  create?: Maybe<Array<PromoCreateWithoutTitlesInput>>;
  connectOrCreate?: Maybe<Array<PromoCreateOrConnectWithoutTitlesInput>>;
  upsert?: Maybe<Array<PromoUpsertWithWhereUniqueWithoutTitlesInput>>;
  connect?: Maybe<Array<PromoWhereUniqueInput>>;
  set?: Maybe<Array<PromoWhereUniqueInput>>;
  disconnect?: Maybe<Array<PromoWhereUniqueInput>>;
  delete?: Maybe<Array<PromoWhereUniqueInput>>;
  update?: Maybe<Array<PromoUpdateWithWhereUniqueWithoutTitlesInput>>;
  updateMany?: Maybe<Array<PromoUpdateManyWithWhereWithoutTitlesInput>>;
  deleteMany?: Maybe<Array<PromoScalarWhereInput>>;
};

export type DecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type FavoriteListRelationFilter = {
  every?: Maybe<FavoriteWhereInput>;
  some?: Maybe<FavoriteWhereInput>;
  none?: Maybe<FavoriteWhereInput>;
};

export type ListeningListRelationFilter = {
  every?: Maybe<ListeningWhereInput>;
  some?: Maybe<ListeningWhereInput>;
  none?: Maybe<ListeningWhereInput>;
};

export type TrackCreatepositivesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type FileCreateNestedOneWithoutTracksInput = {
  create?: Maybe<FileCreateWithoutTracksInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutTracksInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type TitleCreateNestedOneWithoutTracksInput = {
  create?: Maybe<TitleCreateWithoutTracksInput>;
  connectOrCreate?: Maybe<TitleCreateOrConnectWithoutTracksInput>;
  connect?: Maybe<TitleWhereUniqueInput>;
};

export type FavoriteCreateNestedManyWithoutTrackInput = {
  create?: Maybe<Array<FavoriteCreateWithoutTrackInput>>;
  connectOrCreate?: Maybe<Array<FavoriteCreateOrConnectWithoutTrackInput>>;
  createMany?: Maybe<FavoriteCreateManyTrackInputEnvelope>;
  connect?: Maybe<Array<FavoriteWhereUniqueInput>>;
};

export type ListeningCreateNestedManyWithoutTrackInput = {
  create?: Maybe<Array<ListeningCreateWithoutTrackInput>>;
  connectOrCreate?: Maybe<Array<ListeningCreateOrConnectWithoutTrackInput>>;
  createMany?: Maybe<ListeningCreateManyTrackInputEnvelope>;
  connect?: Maybe<Array<ListeningWhereUniqueInput>>;
};

export type DecimalFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Decimal']>;
  increment?: Maybe<Scalars['Decimal']>;
  decrement?: Maybe<Scalars['Decimal']>;
  multiply?: Maybe<Scalars['Decimal']>;
  divide?: Maybe<Scalars['Decimal']>;
};

export type TrackUpdatepositivesInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Scalars['String']>;
};

export type FileUpdateOneRequiredWithoutTracksInput = {
  create?: Maybe<FileCreateWithoutTracksInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutTracksInput>;
  upsert?: Maybe<FileUpsertWithoutTracksInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  update?: Maybe<FileUpdateWithoutTracksInput>;
};

export type TitleUpdateOneRequiredWithoutTracksInput = {
  create?: Maybe<TitleCreateWithoutTracksInput>;
  connectOrCreate?: Maybe<TitleCreateOrConnectWithoutTracksInput>;
  upsert?: Maybe<TitleUpsertWithoutTracksInput>;
  connect?: Maybe<TitleWhereUniqueInput>;
  update?: Maybe<TitleUpdateWithoutTracksInput>;
};

export type FavoriteUpdateManyWithoutTrackInput = {
  create?: Maybe<Array<FavoriteCreateWithoutTrackInput>>;
  connectOrCreate?: Maybe<Array<FavoriteCreateOrConnectWithoutTrackInput>>;
  upsert?: Maybe<Array<FavoriteUpsertWithWhereUniqueWithoutTrackInput>>;
  createMany?: Maybe<FavoriteCreateManyTrackInputEnvelope>;
  connect?: Maybe<Array<FavoriteWhereUniqueInput>>;
  set?: Maybe<Array<FavoriteWhereUniqueInput>>;
  disconnect?: Maybe<Array<FavoriteWhereUniqueInput>>;
  delete?: Maybe<Array<FavoriteWhereUniqueInput>>;
  update?: Maybe<Array<FavoriteUpdateWithWhereUniqueWithoutTrackInput>>;
  updateMany?: Maybe<Array<FavoriteUpdateManyWithWhereWithoutTrackInput>>;
  deleteMany?: Maybe<Array<FavoriteScalarWhereInput>>;
};

export type ListeningUpdateManyWithoutTrackInput = {
  create?: Maybe<Array<ListeningCreateWithoutTrackInput>>;
  connectOrCreate?: Maybe<Array<ListeningCreateOrConnectWithoutTrackInput>>;
  upsert?: Maybe<Array<ListeningUpsertWithWhereUniqueWithoutTrackInput>>;
  createMany?: Maybe<ListeningCreateManyTrackInputEnvelope>;
  connect?: Maybe<Array<ListeningWhereUniqueInput>>;
  set?: Maybe<Array<ListeningWhereUniqueInput>>;
  disconnect?: Maybe<Array<ListeningWhereUniqueInput>>;
  delete?: Maybe<Array<ListeningWhereUniqueInput>>;
  update?: Maybe<Array<ListeningUpdateWithWhereUniqueWithoutTrackInput>>;
  updateMany?: Maybe<Array<ListeningUpdateManyWithWhereWithoutTrackInput>>;
  deleteMany?: Maybe<Array<ListeningScalarWhereInput>>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type CodeListRelationFilter = {
  every?: Maybe<CodeWhereInput>;
  some?: Maybe<CodeWhereInput>;
  none?: Maybe<CodeWhereInput>;
};

export type AccessCreateNestedOneWithoutPromoInput = {
  create?: Maybe<AccessCreateWithoutPromoInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutPromoInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
};

export type ActivationCreateNestedManyWithoutPromoInput = {
  create?: Maybe<Array<ActivationCreateWithoutPromoInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutPromoInput>>;
  createMany?: Maybe<ActivationCreateManyPromoInputEnvelope>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
};

export type CodeCreateNestedManyWithoutPromoInput = {
  create?: Maybe<Array<CodeCreateWithoutPromoInput>>;
  connectOrCreate?: Maybe<Array<CodeCreateOrConnectWithoutPromoInput>>;
  createMany?: Maybe<CodeCreateManyPromoInputEnvelope>;
  connect?: Maybe<Array<CodeWhereUniqueInput>>;
};

export type TitleCreateNestedManyWithoutPromosInput = {
  create?: Maybe<Array<TitleCreateWithoutPromosInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutPromosInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type AccessUpdateOneWithoutPromoInput = {
  create?: Maybe<AccessCreateWithoutPromoInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutPromoInput>;
  upsert?: Maybe<AccessUpsertWithoutPromoInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccessUpdateWithoutPromoInput>;
};

export type ActivationUpdateManyWithoutPromoInput = {
  create?: Maybe<Array<ActivationCreateWithoutPromoInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutPromoInput>>;
  upsert?: Maybe<Array<ActivationUpsertWithWhereUniqueWithoutPromoInput>>;
  createMany?: Maybe<ActivationCreateManyPromoInputEnvelope>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
  set?: Maybe<Array<ActivationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActivationWhereUniqueInput>>;
  delete?: Maybe<Array<ActivationWhereUniqueInput>>;
  update?: Maybe<Array<ActivationUpdateWithWhereUniqueWithoutPromoInput>>;
  updateMany?: Maybe<Array<ActivationUpdateManyWithWhereWithoutPromoInput>>;
  deleteMany?: Maybe<Array<ActivationScalarWhereInput>>;
};

export type CodeUpdateManyWithoutPromoInput = {
  create?: Maybe<Array<CodeCreateWithoutPromoInput>>;
  connectOrCreate?: Maybe<Array<CodeCreateOrConnectWithoutPromoInput>>;
  upsert?: Maybe<Array<CodeUpsertWithWhereUniqueWithoutPromoInput>>;
  createMany?: Maybe<CodeCreateManyPromoInputEnvelope>;
  connect?: Maybe<Array<CodeWhereUniqueInput>>;
  set?: Maybe<Array<CodeWhereUniqueInput>>;
  disconnect?: Maybe<Array<CodeWhereUniqueInput>>;
  delete?: Maybe<Array<CodeWhereUniqueInput>>;
  update?: Maybe<Array<CodeUpdateWithWhereUniqueWithoutPromoInput>>;
  updateMany?: Maybe<Array<CodeUpdateManyWithWhereWithoutPromoInput>>;
  deleteMany?: Maybe<Array<CodeScalarWhereInput>>;
};

export type TitleUpdateManyWithoutPromosInput = {
  create?: Maybe<Array<TitleCreateWithoutPromosInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutPromosInput>>;
  upsert?: Maybe<Array<TitleUpsertWithWhereUniqueWithoutPromosInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
  set?: Maybe<Array<TitleWhereUniqueInput>>;
  disconnect?: Maybe<Array<TitleWhereUniqueInput>>;
  delete?: Maybe<Array<TitleWhereUniqueInput>>;
  update?: Maybe<Array<TitleUpdateWithWhereUniqueWithoutPromosInput>>;
  updateMany?: Maybe<Array<TitleUpdateManyWithWhereWithoutPromosInput>>;
  deleteMany?: Maybe<Array<TitleScalarWhereInput>>;
};

export type PromoCreateNestedOneWithoutCodesInput = {
  create?: Maybe<PromoCreateWithoutCodesInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutCodesInput>;
  connect?: Maybe<PromoWhereUniqueInput>;
};

export type ActivationCreateNestedOneWithoutCodeInput = {
  create?: Maybe<ActivationCreateWithoutCodeInput>;
  connectOrCreate?: Maybe<ActivationCreateOrConnectWithoutCodeInput>;
  connect?: Maybe<ActivationWhereUniqueInput>;
};

export type PromoUpdateOneRequiredWithoutCodesInput = {
  create?: Maybe<PromoCreateWithoutCodesInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutCodesInput>;
  upsert?: Maybe<PromoUpsertWithoutCodesInput>;
  connect?: Maybe<PromoWhereUniqueInput>;
  update?: Maybe<PromoUpdateWithoutCodesInput>;
};

export type ActivationUpdateOneWithoutCodeInput = {
  create?: Maybe<ActivationCreateWithoutCodeInput>;
  connectOrCreate?: Maybe<ActivationCreateOrConnectWithoutCodeInput>;
  upsert?: Maybe<ActivationUpsertWithoutCodeInput>;
  connect?: Maybe<ActivationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ActivationUpdateWithoutCodeInput>;
};

export type ProfileCreateNestedOneWithoutOpeningsInput = {
  create?: Maybe<ProfileCreateWithoutOpeningsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutOpeningsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type TitleCreateNestedOneWithoutOpeningsInput = {
  create?: Maybe<TitleCreateWithoutOpeningsInput>;
  connectOrCreate?: Maybe<TitleCreateOrConnectWithoutOpeningsInput>;
  connect?: Maybe<TitleWhereUniqueInput>;
};

export type ProfileUpdateOneWithoutOpeningsInput = {
  create?: Maybe<ProfileCreateWithoutOpeningsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutOpeningsInput>;
  upsert?: Maybe<ProfileUpsertWithoutOpeningsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProfileUpdateWithoutOpeningsInput>;
};

export type TitleUpdateOneRequiredWithoutOpeningsInput = {
  create?: Maybe<TitleCreateWithoutOpeningsInput>;
  connectOrCreate?: Maybe<TitleCreateOrConnectWithoutOpeningsInput>;
  upsert?: Maybe<TitleUpsertWithoutOpeningsInput>;
  connect?: Maybe<TitleWhereUniqueInput>;
  update?: Maybe<TitleUpdateWithoutOpeningsInput>;
};

export type ProfileCreateNestedOneWithoutFavoritesInput = {
  create?: Maybe<ProfileCreateWithoutFavoritesInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutFavoritesInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type TrackCreateNestedOneWithoutFavoriteByInput = {
  create?: Maybe<TrackCreateWithoutFavoriteByInput>;
  connectOrCreate?: Maybe<TrackCreateOrConnectWithoutFavoriteByInput>;
  connect?: Maybe<TrackWhereUniqueInput>;
};

export type ProfileUpdateOneRequiredWithoutFavoritesInput = {
  create?: Maybe<ProfileCreateWithoutFavoritesInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutFavoritesInput>;
  upsert?: Maybe<ProfileUpsertWithoutFavoritesInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  update?: Maybe<ProfileUpdateWithoutFavoritesInput>;
};

export type TrackUpdateOneRequiredWithoutFavoriteByInput = {
  create?: Maybe<TrackCreateWithoutFavoriteByInput>;
  connectOrCreate?: Maybe<TrackCreateOrConnectWithoutFavoriteByInput>;
  upsert?: Maybe<TrackUpsertWithoutFavoriteByInput>;
  connect?: Maybe<TrackWhereUniqueInput>;
  update?: Maybe<TrackUpdateWithoutFavoriteByInput>;
};

export type ProfileCreateNestedOneWithoutPurchasesInput = {
  create?: Maybe<ProfileCreateWithoutPurchasesInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutPurchasesInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type TitleCreateNestedOneWithoutPurchasedByInput = {
  create?: Maybe<TitleCreateWithoutPurchasedByInput>;
  connectOrCreate?: Maybe<TitleCreateOrConnectWithoutPurchasedByInput>;
  connect?: Maybe<TitleWhereUniqueInput>;
};

export type ProfileUpdateOneWithoutPurchasesInput = {
  create?: Maybe<ProfileCreateWithoutPurchasesInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutPurchasesInput>;
  upsert?: Maybe<ProfileUpsertWithoutPurchasesInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProfileUpdateWithoutPurchasesInput>;
};

export type TitleUpdateOneRequiredWithoutPurchasedByInput = {
  create?: Maybe<TitleCreateWithoutPurchasedByInput>;
  connectOrCreate?: Maybe<TitleCreateOrConnectWithoutPurchasedByInput>;
  upsert?: Maybe<TitleUpsertWithoutPurchasedByInput>;
  connect?: Maybe<TitleWhereUniqueInput>;
  update?: Maybe<TitleUpdateWithoutPurchasedByInput>;
};

export type InAppPurchaseActivationUpdateOneWithoutActivationInput = {
  create?: Maybe<InAppPurchaseActivationCreateWithoutActivationInput>;
  connectOrCreate?: Maybe<InAppPurchaseActivationCreateOrConnectWithoutActivationInput>;
  upsert?: Maybe<InAppPurchaseActivationUpsertWithoutActivationInput>;
  connect?: Maybe<InAppPurchaseActivationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InAppPurchaseActivationUpdateWithoutActivationInput>;
};

export type AccessUpdateOneWithoutActivationsInput = {
  create?: Maybe<AccessCreateWithoutActivationsInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutActivationsInput>;
  upsert?: Maybe<AccessUpsertWithoutActivationsInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AccessUpdateWithoutActivationsInput>;
};

export type CodeUpdateOneWithoutActivationInput = {
  create?: Maybe<CodeCreateWithoutActivationInput>;
  connectOrCreate?: Maybe<CodeCreateOrConnectWithoutActivationInput>;
  upsert?: Maybe<CodeUpsertWithoutActivationInput>;
  connect?: Maybe<CodeWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CodeUpdateWithoutActivationInput>;
};

export type ProfileUpdateOneWithoutActivationsInput = {
  create?: Maybe<ProfileCreateWithoutActivationsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutActivationsInput>;
  upsert?: Maybe<ProfileUpsertWithoutActivationsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProfileUpdateWithoutActivationsInput>;
};

export type PromoUpdateOneWithoutActivationsInput = {
  create?: Maybe<PromoCreateWithoutActivationsInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutActivationsInput>;
  upsert?: Maybe<PromoUpsertWithoutActivationsInput>;
  connect?: Maybe<PromoWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PromoUpdateWithoutActivationsInput>;
};

export type TitleUpdateManyWithoutActivationsInput = {
  create?: Maybe<Array<TitleCreateWithoutActivationsInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutActivationsInput>>;
  upsert?: Maybe<Array<TitleUpsertWithWhereUniqueWithoutActivationsInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
  set?: Maybe<Array<TitleWhereUniqueInput>>;
  disconnect?: Maybe<Array<TitleWhereUniqueInput>>;
  delete?: Maybe<Array<TitleWhereUniqueInput>>;
  update?: Maybe<Array<TitleUpdateWithWhereUniqueWithoutActivationsInput>>;
  updateMany?: Maybe<Array<TitleUpdateManyWithWhereWithoutActivationsInput>>;
  deleteMany?: Maybe<Array<TitleScalarWhereInput>>;
};

export type SessionListRelationFilter = {
  every?: Maybe<SessionWhereInput>;
  some?: Maybe<SessionWhereInput>;
  none?: Maybe<SessionWhereInput>;
};

export type VerificationListRelationFilter = {
  every?: Maybe<VerificationWhereInput>;
  some?: Maybe<VerificationWhereInput>;
  none?: Maybe<VerificationWhereInput>;
};

export type ActivationCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<ActivationCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<ActivationCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
};

export type FavoriteCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<FavoriteCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<FavoriteCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<FavoriteCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<FavoriteWhereUniqueInput>>;
};

export type ListeningCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<ListeningCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<ListeningCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<ListeningCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<ListeningWhereUniqueInput>>;
};

export type OpeningCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<OpeningCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<OpeningCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<OpeningCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<OpeningWhereUniqueInput>>;
};

export type PurchaseCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<PurchaseCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<PurchaseCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<PurchaseCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<PurchaseWhereUniqueInput>>;
};

export type SessionCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<SessionCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<SessionCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
};

export type VerificationCreateNestedManyWithoutProfileInput = {
  create?: Maybe<Array<VerificationCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<VerificationCreateOrConnectWithoutProfileInput>>;
  createMany?: Maybe<VerificationCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<VerificationWhereUniqueInput>>;
};

export type DiscountProposalCreateNestedOneWithoutProfileInput = {
  create?: Maybe<DiscountProposalCreateWithoutProfileInput>;
  connectOrCreate?: Maybe<DiscountProposalCreateOrConnectWithoutProfileInput>;
  connect?: Maybe<DiscountProposalWhereUniqueInput>;
};

export type ActivationUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<ActivationCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<ActivationUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<ActivationCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
  set?: Maybe<Array<ActivationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActivationWhereUniqueInput>>;
  delete?: Maybe<Array<ActivationWhereUniqueInput>>;
  update?: Maybe<Array<ActivationUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<ActivationUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<ActivationScalarWhereInput>>;
};

export type FavoriteUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<FavoriteCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<FavoriteCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<FavoriteUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<FavoriteCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<FavoriteWhereUniqueInput>>;
  set?: Maybe<Array<FavoriteWhereUniqueInput>>;
  disconnect?: Maybe<Array<FavoriteWhereUniqueInput>>;
  delete?: Maybe<Array<FavoriteWhereUniqueInput>>;
  update?: Maybe<Array<FavoriteUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<FavoriteUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<FavoriteScalarWhereInput>>;
};

export type ListeningUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<ListeningCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<ListeningCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<ListeningUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<ListeningCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<ListeningWhereUniqueInput>>;
  set?: Maybe<Array<ListeningWhereUniqueInput>>;
  disconnect?: Maybe<Array<ListeningWhereUniqueInput>>;
  delete?: Maybe<Array<ListeningWhereUniqueInput>>;
  update?: Maybe<Array<ListeningUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<ListeningUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<ListeningScalarWhereInput>>;
};

export type OpeningUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<OpeningCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<OpeningCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<OpeningUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<OpeningCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<OpeningWhereUniqueInput>>;
  set?: Maybe<Array<OpeningWhereUniqueInput>>;
  disconnect?: Maybe<Array<OpeningWhereUniqueInput>>;
  delete?: Maybe<Array<OpeningWhereUniqueInput>>;
  update?: Maybe<Array<OpeningUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<OpeningUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<OpeningScalarWhereInput>>;
};

export type PurchaseUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<PurchaseCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<PurchaseCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<PurchaseUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<PurchaseCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<PurchaseWhereUniqueInput>>;
  set?: Maybe<Array<PurchaseWhereUniqueInput>>;
  disconnect?: Maybe<Array<PurchaseWhereUniqueInput>>;
  delete?: Maybe<Array<PurchaseWhereUniqueInput>>;
  update?: Maybe<Array<PurchaseUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<PurchaseUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<PurchaseScalarWhereInput>>;
};

export type SessionUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<SessionCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<SessionUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<SessionCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
  set?: Maybe<Array<SessionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SessionWhereUniqueInput>>;
  delete?: Maybe<Array<SessionWhereUniqueInput>>;
  update?: Maybe<Array<SessionUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<SessionUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<SessionScalarWhereInput>>;
};

export type VerificationUpdateManyWithoutProfileInput = {
  create?: Maybe<Array<VerificationCreateWithoutProfileInput>>;
  connectOrCreate?: Maybe<Array<VerificationCreateOrConnectWithoutProfileInput>>;
  upsert?: Maybe<Array<VerificationUpsertWithWhereUniqueWithoutProfileInput>>;
  createMany?: Maybe<VerificationCreateManyProfileInputEnvelope>;
  connect?: Maybe<Array<VerificationWhereUniqueInput>>;
  set?: Maybe<Array<VerificationWhereUniqueInput>>;
  disconnect?: Maybe<Array<VerificationWhereUniqueInput>>;
  delete?: Maybe<Array<VerificationWhereUniqueInput>>;
  update?: Maybe<Array<VerificationUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: Maybe<Array<VerificationUpdateManyWithWhereWithoutProfileInput>>;
  deleteMany?: Maybe<Array<VerificationScalarWhereInput>>;
};

export type DiscountProposalUpdateOneWithoutProfileInput = {
  create?: Maybe<DiscountProposalCreateWithoutProfileInput>;
  connectOrCreate?: Maybe<DiscountProposalCreateOrConnectWithoutProfileInput>;
  upsert?: Maybe<DiscountProposalUpsertWithoutProfileInput>;
  connect?: Maybe<DiscountProposalWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DiscountProposalUpdateWithoutProfileInput>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type ProfileCreateNestedOneWithoutListeningsInput = {
  create?: Maybe<ProfileCreateWithoutListeningsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutListeningsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type TrackCreateNestedOneWithoutListeningsInput = {
  create?: Maybe<TrackCreateWithoutListeningsInput>;
  connectOrCreate?: Maybe<TrackCreateOrConnectWithoutListeningsInput>;
  connect?: Maybe<TrackWhereUniqueInput>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type ProfileUpdateOneRequiredWithoutListeningsInput = {
  create?: Maybe<ProfileCreateWithoutListeningsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutListeningsInput>;
  upsert?: Maybe<ProfileUpsertWithoutListeningsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  update?: Maybe<ProfileUpdateWithoutListeningsInput>;
};

export type TrackUpdateOneRequiredWithoutListeningsInput = {
  create?: Maybe<TrackCreateWithoutListeningsInput>;
  connectOrCreate?: Maybe<TrackCreateOrConnectWithoutListeningsInput>;
  upsert?: Maybe<TrackUpsertWithoutListeningsInput>;
  connect?: Maybe<TrackWhereUniqueInput>;
  update?: Maybe<TrackUpdateWithoutListeningsInput>;
};

export type SessionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<SessionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<SessionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
};

export type SessionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<SessionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<SessionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<SessionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
  set?: Maybe<Array<SessionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SessionWhereUniqueInput>>;
  delete?: Maybe<Array<SessionWhereUniqueInput>>;
  update?: Maybe<Array<SessionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<SessionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<SessionScalarWhereInput>>;
};

export type EnumParameterKeyFilter = {
  equals?: Maybe<ParameterKey>;
  in?: Maybe<Array<ParameterKey>>;
  notIn?: Maybe<Array<ParameterKey>>;
  not?: Maybe<NestedEnumParameterKeyFilter>;
};

export type EnumParameterKeyFieldUpdateOperationsInput = {
  set?: Maybe<ParameterKey>;
};

export type EnumQuestionScreenFilter = {
  equals?: Maybe<QuestionScreen>;
  in?: Maybe<Array<QuestionScreen>>;
  notIn?: Maybe<Array<QuestionScreen>>;
  not?: Maybe<NestedEnumQuestionScreenFilter>;
};

export type QuestionListRelationFilter = {
  every?: Maybe<QuestionWhereInput>;
  some?: Maybe<QuestionWhereInput>;
  none?: Maybe<QuestionWhereInput>;
};

export type QuestionCreateNestedOneWithoutQuestionsInput = {
  create?: Maybe<QuestionCreateWithoutQuestionsInput>;
  connectOrCreate?: Maybe<QuestionCreateOrConnectWithoutQuestionsInput>;
  connect?: Maybe<QuestionWhereUniqueInput>;
};

export type QuestionCreateNestedManyWithoutParentInput = {
  create?: Maybe<Array<QuestionCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<QuestionCreateOrConnectWithoutParentInput>>;
  createMany?: Maybe<QuestionCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<QuestionWhereUniqueInput>>;
};

export type EnumQuestionScreenFieldUpdateOperationsInput = {
  set?: Maybe<QuestionScreen>;
};

export type QuestionUpdateOneWithoutQuestionsInput = {
  create?: Maybe<QuestionCreateWithoutQuestionsInput>;
  connectOrCreate?: Maybe<QuestionCreateOrConnectWithoutQuestionsInput>;
  upsert?: Maybe<QuestionUpsertWithoutQuestionsInput>;
  connect?: Maybe<QuestionWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<QuestionUpdateWithoutQuestionsInput>;
};

export type QuestionUpdateManyWithoutParentInput = {
  create?: Maybe<Array<QuestionCreateWithoutParentInput>>;
  connectOrCreate?: Maybe<Array<QuestionCreateOrConnectWithoutParentInput>>;
  upsert?: Maybe<Array<QuestionUpsertWithWhereUniqueWithoutParentInput>>;
  createMany?: Maybe<QuestionCreateManyParentInputEnvelope>;
  connect?: Maybe<Array<QuestionWhereUniqueInput>>;
  set?: Maybe<Array<QuestionWhereUniqueInput>>;
  disconnect?: Maybe<Array<QuestionWhereUniqueInput>>;
  delete?: Maybe<Array<QuestionWhereUniqueInput>>;
  update?: Maybe<Array<QuestionUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: Maybe<Array<QuestionUpdateManyWithWhereWithoutParentInput>>;
  deleteMany?: Maybe<Array<QuestionScalarWhereInput>>;
};

export type DiscountListRelationFilter = {
  every?: Maybe<DiscountWhereInput>;
  some?: Maybe<DiscountWhereInput>;
  none?: Maybe<DiscountWhereInput>;
};

export type ActivationCreateNestedManyWithoutAccessInput = {
  create?: Maybe<Array<ActivationCreateWithoutAccessInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutAccessInput>>;
  createMany?: Maybe<ActivationCreateManyAccessInputEnvelope>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
};

export type InAppPurchaseCreateNestedOneWithoutAccessInput = {
  create?: Maybe<InAppPurchaseCreateWithoutAccessInput>;
  connectOrCreate?: Maybe<InAppPurchaseCreateOrConnectWithoutAccessInput>;
  connect?: Maybe<InAppPurchaseWhereUniqueInput>;
};

export type PromoCreateNestedOneWithoutAccessInput = {
  create?: Maybe<PromoCreateWithoutAccessInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutAccessInput>;
  connect?: Maybe<PromoWhereUniqueInput>;
};

export type MagentaSubscriptionCreateNestedOneWithoutAccessInput = {
  create?: Maybe<MagentaSubscriptionCreateWithoutAccessInput>;
  connectOrCreate?: Maybe<MagentaSubscriptionCreateOrConnectWithoutAccessInput>;
  connect?: Maybe<MagentaSubscriptionWhereUniqueInput>;
};

export type TitleCreateNestedManyWithoutAccessesInput = {
  create?: Maybe<Array<TitleCreateWithoutAccessesInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutAccessesInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
};

export type DiscountCreateNestedManyWithoutRequiredAccessesInput = {
  create?: Maybe<Array<DiscountCreateWithoutRequiredAccessesInput>>;
  connectOrCreate?: Maybe<Array<DiscountCreateOrConnectWithoutRequiredAccessesInput>>;
  connect?: Maybe<Array<DiscountWhereUniqueInput>>;
};

export type DiscountCreateNestedManyWithoutDisabledAccessesInput = {
  create?: Maybe<Array<DiscountCreateWithoutDisabledAccessesInput>>;
  connectOrCreate?: Maybe<Array<DiscountCreateOrConnectWithoutDisabledAccessesInput>>;
  connect?: Maybe<Array<DiscountWhereUniqueInput>>;
};

export type ActivationUpdateManyWithoutAccessInput = {
  create?: Maybe<Array<ActivationCreateWithoutAccessInput>>;
  connectOrCreate?: Maybe<Array<ActivationCreateOrConnectWithoutAccessInput>>;
  upsert?: Maybe<Array<ActivationUpsertWithWhereUniqueWithoutAccessInput>>;
  createMany?: Maybe<ActivationCreateManyAccessInputEnvelope>;
  connect?: Maybe<Array<ActivationWhereUniqueInput>>;
  set?: Maybe<Array<ActivationWhereUniqueInput>>;
  disconnect?: Maybe<Array<ActivationWhereUniqueInput>>;
  delete?: Maybe<Array<ActivationWhereUniqueInput>>;
  update?: Maybe<Array<ActivationUpdateWithWhereUniqueWithoutAccessInput>>;
  updateMany?: Maybe<Array<ActivationUpdateManyWithWhereWithoutAccessInput>>;
  deleteMany?: Maybe<Array<ActivationScalarWhereInput>>;
};

export type InAppPurchaseUpdateOneWithoutAccessInput = {
  create?: Maybe<InAppPurchaseCreateWithoutAccessInput>;
  connectOrCreate?: Maybe<InAppPurchaseCreateOrConnectWithoutAccessInput>;
  upsert?: Maybe<InAppPurchaseUpsertWithoutAccessInput>;
  connect?: Maybe<InAppPurchaseWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InAppPurchaseUpdateWithoutAccessInput>;
};

export type PromoUpdateOneWithoutAccessInput = {
  create?: Maybe<PromoCreateWithoutAccessInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutAccessInput>;
  upsert?: Maybe<PromoUpsertWithoutAccessInput>;
  connect?: Maybe<PromoWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PromoUpdateWithoutAccessInput>;
};

export type MagentaSubscriptionUpdateOneWithoutAccessInput = {
  create?: Maybe<MagentaSubscriptionCreateWithoutAccessInput>;
  connectOrCreate?: Maybe<MagentaSubscriptionCreateOrConnectWithoutAccessInput>;
  upsert?: Maybe<MagentaSubscriptionUpsertWithoutAccessInput>;
  connect?: Maybe<MagentaSubscriptionWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MagentaSubscriptionUpdateWithoutAccessInput>;
};

export type TitleUpdateManyWithoutAccessesInput = {
  create?: Maybe<Array<TitleCreateWithoutAccessesInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutAccessesInput>>;
  upsert?: Maybe<Array<TitleUpsertWithWhereUniqueWithoutAccessesInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
  set?: Maybe<Array<TitleWhereUniqueInput>>;
  disconnect?: Maybe<Array<TitleWhereUniqueInput>>;
  delete?: Maybe<Array<TitleWhereUniqueInput>>;
  update?: Maybe<Array<TitleUpdateWithWhereUniqueWithoutAccessesInput>>;
  updateMany?: Maybe<Array<TitleUpdateManyWithWhereWithoutAccessesInput>>;
  deleteMany?: Maybe<Array<TitleScalarWhereInput>>;
};

export type DiscountUpdateManyWithoutRequiredAccessesInput = {
  create?: Maybe<Array<DiscountCreateWithoutRequiredAccessesInput>>;
  connectOrCreate?: Maybe<Array<DiscountCreateOrConnectWithoutRequiredAccessesInput>>;
  upsert?: Maybe<Array<DiscountUpsertWithWhereUniqueWithoutRequiredAccessesInput>>;
  connect?: Maybe<Array<DiscountWhereUniqueInput>>;
  set?: Maybe<Array<DiscountWhereUniqueInput>>;
  disconnect?: Maybe<Array<DiscountWhereUniqueInput>>;
  delete?: Maybe<Array<DiscountWhereUniqueInput>>;
  update?: Maybe<Array<DiscountUpdateWithWhereUniqueWithoutRequiredAccessesInput>>;
  updateMany?: Maybe<Array<DiscountUpdateManyWithWhereWithoutRequiredAccessesInput>>;
  deleteMany?: Maybe<Array<DiscountScalarWhereInput>>;
};

export type DiscountUpdateManyWithoutDisabledAccessesInput = {
  create?: Maybe<Array<DiscountCreateWithoutDisabledAccessesInput>>;
  connectOrCreate?: Maybe<Array<DiscountCreateOrConnectWithoutDisabledAccessesInput>>;
  upsert?: Maybe<Array<DiscountUpsertWithWhereUniqueWithoutDisabledAccessesInput>>;
  connect?: Maybe<Array<DiscountWhereUniqueInput>>;
  set?: Maybe<Array<DiscountWhereUniqueInput>>;
  disconnect?: Maybe<Array<DiscountWhereUniqueInput>>;
  delete?: Maybe<Array<DiscountWhereUniqueInput>>;
  update?: Maybe<Array<DiscountUpdateWithWhereUniqueWithoutDisabledAccessesInput>>;
  updateMany?: Maybe<Array<DiscountUpdateManyWithWhereWithoutDisabledAccessesInput>>;
  deleteMany?: Maybe<Array<DiscountScalarWhereInput>>;
};

export type InAppPurchaseActivationListRelationFilter = {
  every?: Maybe<InAppPurchaseActivationWhereInput>;
  some?: Maybe<InAppPurchaseActivationWhereInput>;
  none?: Maybe<InAppPurchaseActivationWhereInput>;
};

export type InAppPurchaseCreateplanIdInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type AccessCreateNestedOneWithoutInAppPurchaseInput = {
  create?: Maybe<AccessCreateWithoutInAppPurchaseInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutInAppPurchaseInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
};

export type InAppPurchaseActivationCreateNestedManyWithoutInAppPurchaseInput = {
  create?: Maybe<Array<InAppPurchaseActivationCreateWithoutInAppPurchaseInput>>;
  connectOrCreate?: Maybe<Array<InAppPurchaseActivationCreateOrConnectWithoutInAppPurchaseInput>>;
  createMany?: Maybe<InAppPurchaseActivationCreateManyInAppPurchaseInputEnvelope>;
  connect?: Maybe<Array<InAppPurchaseActivationWhereUniqueInput>>;
};

export type InAppPurchaseUpdateplanIdInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Scalars['String']>;
};

export type AccessUpdateOneRequiredWithoutInAppPurchaseInput = {
  create?: Maybe<AccessCreateWithoutInAppPurchaseInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutInAppPurchaseInput>;
  upsert?: Maybe<AccessUpsertWithoutInAppPurchaseInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
  update?: Maybe<AccessUpdateWithoutInAppPurchaseInput>;
};

export type InAppPurchaseActivationUpdateManyWithoutInAppPurchaseInput = {
  create?: Maybe<Array<InAppPurchaseActivationCreateWithoutInAppPurchaseInput>>;
  connectOrCreate?: Maybe<Array<InAppPurchaseActivationCreateOrConnectWithoutInAppPurchaseInput>>;
  upsert?: Maybe<Array<InAppPurchaseActivationUpsertWithWhereUniqueWithoutInAppPurchaseInput>>;
  createMany?: Maybe<InAppPurchaseActivationCreateManyInAppPurchaseInputEnvelope>;
  connect?: Maybe<Array<InAppPurchaseActivationWhereUniqueInput>>;
  set?: Maybe<Array<InAppPurchaseActivationWhereUniqueInput>>;
  disconnect?: Maybe<Array<InAppPurchaseActivationWhereUniqueInput>>;
  delete?: Maybe<Array<InAppPurchaseActivationWhereUniqueInput>>;
  update?: Maybe<Array<InAppPurchaseActivationUpdateWithWhereUniqueWithoutInAppPurchaseInput>>;
  updateMany?: Maybe<Array<InAppPurchaseActivationUpdateManyWithWhereWithoutInAppPurchaseInput>>;
  deleteMany?: Maybe<Array<InAppPurchaseActivationScalarWhereInput>>;
};

export type AccessCreateNestedOneWithoutSubscriptionInput = {
  create?: Maybe<AccessCreateWithoutSubscriptionInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutSubscriptionInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
};

export type AccessUpdateOneRequiredWithoutSubscriptionInput = {
  create?: Maybe<AccessCreateWithoutSubscriptionInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutSubscriptionInput>;
  upsert?: Maybe<AccessUpsertWithoutSubscriptionInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
  update?: Maybe<AccessUpdateWithoutSubscriptionInput>;
};

export type EnumRelativePositionEnumFilter = {
  equals?: Maybe<RelativePositionEnum>;
  in?: Maybe<Array<RelativePositionEnum>>;
  notIn?: Maybe<Array<RelativePositionEnum>>;
  not?: Maybe<NestedEnumRelativePositionEnumFilter>;
};

export type DiscountProposalListRelationFilter = {
  every?: Maybe<DiscountProposalWhereInput>;
  some?: Maybe<DiscountProposalWhereInput>;
  none?: Maybe<DiscountProposalWhereInput>;
};

export type AccessCreateNestedManyWithoutRequiringDiscountsInput = {
  create?: Maybe<Array<AccessCreateWithoutRequiringDiscountsInput>>;
  connectOrCreate?: Maybe<Array<AccessCreateOrConnectWithoutRequiringDiscountsInput>>;
  connect?: Maybe<Array<AccessWhereUniqueInput>>;
};

export type DiscountProposalCreateNestedManyWithoutDiscountInput = {
  create?: Maybe<Array<DiscountProposalCreateWithoutDiscountInput>>;
  connectOrCreate?: Maybe<Array<DiscountProposalCreateOrConnectWithoutDiscountInput>>;
  createMany?: Maybe<DiscountProposalCreateManyDiscountInputEnvelope>;
  connect?: Maybe<Array<DiscountProposalWhereUniqueInput>>;
};

export type AccessCreateNestedManyWithoutDisablingDiscountsInput = {
  create?: Maybe<Array<AccessCreateWithoutDisablingDiscountsInput>>;
  connectOrCreate?: Maybe<Array<AccessCreateOrConnectWithoutDisablingDiscountsInput>>;
  connect?: Maybe<Array<AccessWhereUniqueInput>>;
};

export type EnumRelativePositionEnumFieldUpdateOperationsInput = {
  set?: Maybe<RelativePositionEnum>;
};

export type AccessUpdateManyWithoutRequiringDiscountsInput = {
  create?: Maybe<Array<AccessCreateWithoutRequiringDiscountsInput>>;
  connectOrCreate?: Maybe<Array<AccessCreateOrConnectWithoutRequiringDiscountsInput>>;
  upsert?: Maybe<Array<AccessUpsertWithWhereUniqueWithoutRequiringDiscountsInput>>;
  connect?: Maybe<Array<AccessWhereUniqueInput>>;
  set?: Maybe<Array<AccessWhereUniqueInput>>;
  disconnect?: Maybe<Array<AccessWhereUniqueInput>>;
  delete?: Maybe<Array<AccessWhereUniqueInput>>;
  update?: Maybe<Array<AccessUpdateWithWhereUniqueWithoutRequiringDiscountsInput>>;
  updateMany?: Maybe<Array<AccessUpdateManyWithWhereWithoutRequiringDiscountsInput>>;
  deleteMany?: Maybe<Array<AccessScalarWhereInput>>;
};

export type DiscountProposalUpdateManyWithoutDiscountInput = {
  create?: Maybe<Array<DiscountProposalCreateWithoutDiscountInput>>;
  connectOrCreate?: Maybe<Array<DiscountProposalCreateOrConnectWithoutDiscountInput>>;
  upsert?: Maybe<Array<DiscountProposalUpsertWithWhereUniqueWithoutDiscountInput>>;
  createMany?: Maybe<DiscountProposalCreateManyDiscountInputEnvelope>;
  connect?: Maybe<Array<DiscountProposalWhereUniqueInput>>;
  set?: Maybe<Array<DiscountProposalWhereUniqueInput>>;
  disconnect?: Maybe<Array<DiscountProposalWhereUniqueInput>>;
  delete?: Maybe<Array<DiscountProposalWhereUniqueInput>>;
  update?: Maybe<Array<DiscountProposalUpdateWithWhereUniqueWithoutDiscountInput>>;
  updateMany?: Maybe<Array<DiscountProposalUpdateManyWithWhereWithoutDiscountInput>>;
  deleteMany?: Maybe<Array<DiscountProposalScalarWhereInput>>;
};

export type AccessUpdateManyWithoutDisablingDiscountsInput = {
  create?: Maybe<Array<AccessCreateWithoutDisablingDiscountsInput>>;
  connectOrCreate?: Maybe<Array<AccessCreateOrConnectWithoutDisablingDiscountsInput>>;
  upsert?: Maybe<Array<AccessUpsertWithWhereUniqueWithoutDisablingDiscountsInput>>;
  connect?: Maybe<Array<AccessWhereUniqueInput>>;
  set?: Maybe<Array<AccessWhereUniqueInput>>;
  disconnect?: Maybe<Array<AccessWhereUniqueInput>>;
  delete?: Maybe<Array<AccessWhereUniqueInput>>;
  update?: Maybe<Array<AccessUpdateWithWhereUniqueWithoutDisablingDiscountsInput>>;
  updateMany?: Maybe<Array<AccessUpdateManyWithWhereWithoutDisablingDiscountsInput>>;
  deleteMany?: Maybe<Array<AccessScalarWhereInput>>;
};

export type DiscountCreateNestedOneWithoutDiscountProposalsInput = {
  create?: Maybe<DiscountCreateWithoutDiscountProposalsInput>;
  connectOrCreate?: Maybe<DiscountCreateOrConnectWithoutDiscountProposalsInput>;
  connect?: Maybe<DiscountWhereUniqueInput>;
};

export type ProfileCreateNestedOneWithoutDiscountProposalInput = {
  create?: Maybe<ProfileCreateWithoutDiscountProposalInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutDiscountProposalInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type DiscountUpdateOneRequiredWithoutDiscountProposalsInput = {
  create?: Maybe<DiscountCreateWithoutDiscountProposalsInput>;
  connectOrCreate?: Maybe<DiscountCreateOrConnectWithoutDiscountProposalsInput>;
  upsert?: Maybe<DiscountUpsertWithoutDiscountProposalsInput>;
  connect?: Maybe<DiscountWhereUniqueInput>;
  update?: Maybe<DiscountUpdateWithoutDiscountProposalsInput>;
};

export type ProfileUpdateOneWithoutDiscountProposalInput = {
  create?: Maybe<ProfileCreateWithoutDiscountProposalInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutDiscountProposalInput>;
  upsert?: Maybe<ProfileUpsertWithoutDiscountProposalInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProfileUpdateWithoutDiscountProposalInput>;
};

export type EnumInAppPurchasePlatformFilter = {
  equals?: Maybe<InAppPurchasePlatform>;
  in?: Maybe<Array<InAppPurchasePlatform>>;
  notIn?: Maybe<Array<InAppPurchasePlatform>>;
  not?: Maybe<NestedEnumInAppPurchasePlatformFilter>;
};

export enum InAppPurchasePlatform {
  Ios = 'IOS',
  Android = 'Android'
}

export type InAppPurchaseCreateNestedOneWithoutInAppPurchaseActivationInput = {
  create?: Maybe<InAppPurchaseCreateWithoutInAppPurchaseActivationInput>;
  connectOrCreate?: Maybe<InAppPurchaseCreateOrConnectWithoutInAppPurchaseActivationInput>;
  connect?: Maybe<InAppPurchaseWhereUniqueInput>;
};

export type ActivationCreateNestedOneWithoutInAppPurchaseActivationInput = {
  create?: Maybe<ActivationCreateWithoutInAppPurchaseActivationInput>;
  connectOrCreate?: Maybe<ActivationCreateOrConnectWithoutInAppPurchaseActivationInput>;
  connect?: Maybe<ActivationWhereUniqueInput>;
};

export type EnumInAppPurchasePlatformFieldUpdateOperationsInput = {
  set?: Maybe<InAppPurchasePlatform>;
};

export type InAppPurchaseUpdateOneRequiredWithoutInAppPurchaseActivationInput = {
  create?: Maybe<InAppPurchaseCreateWithoutInAppPurchaseActivationInput>;
  connectOrCreate?: Maybe<InAppPurchaseCreateOrConnectWithoutInAppPurchaseActivationInput>;
  upsert?: Maybe<InAppPurchaseUpsertWithoutInAppPurchaseActivationInput>;
  connect?: Maybe<InAppPurchaseWhereUniqueInput>;
  update?: Maybe<InAppPurchaseUpdateWithoutInAppPurchaseActivationInput>;
};

export type ActivationUpdateOneRequiredWithoutInAppPurchaseActivationInput = {
  create?: Maybe<ActivationCreateWithoutInAppPurchaseActivationInput>;
  connectOrCreate?: Maybe<ActivationCreateOrConnectWithoutInAppPurchaseActivationInput>;
  upsert?: Maybe<ActivationUpsertWithoutInAppPurchaseActivationInput>;
  connect?: Maybe<ActivationWhereUniqueInput>;
  update?: Maybe<ActivationUpdateWithoutInAppPurchaseActivationInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedDecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
};

export type TitleCreateWithoutCollectionsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutCollectionsInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutCollectionsInput;
};

export type TitleUpsertWithWhereUniqueWithoutCollectionsInput = {
  where: TitleWhereUniqueInput;
  update: TitleUpdateWithoutCollectionsInput;
  create: TitleCreateWithoutCollectionsInput;
};

export type TitleUpdateWithWhereUniqueWithoutCollectionsInput = {
  where: TitleWhereUniqueInput;
  data: TitleUpdateWithoutCollectionsInput;
};

export type TitleUpdateManyWithWhereWithoutCollectionsInput = {
  where: TitleScalarWhereInput;
  data: TitleUpdateManyMutationInput;
};

export type TitleScalarWhereInput = {
  AND?: Maybe<Array<TitleScalarWhereInput>>;
  OR?: Maybe<Array<TitleScalarWhereInput>>;
  NOT?: Maybe<Array<TitleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  adultsOnly?: Maybe<BoolFilter>;
  header?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isFree?: Maybe<BoolFilter>;
  subtitle?: Maybe<StringNullableFilter>;
  position?: Maybe<DecimalNullableFilter>;
  purchaseUrl?: Maybe<StringNullableFilter>;
  sku?: Maybe<StringNullableFilter>;
  lastPlayed?: Maybe<DateTimeNullableFilter>;
  showWinner?: Maybe<BoolFilter>;
  paywallId?: Maybe<StringNullableFilter>;
  soundId?: Maybe<StringNullableFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type SoundCreateWithoutFileInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  titles?: Maybe<TitleCreateNestedManyWithoutDefaultSoundInput>;
};

export type SoundCreateOrConnectWithoutFileInput = {
  where: SoundWhereUniqueInput;
  create: SoundCreateWithoutFileInput;
};

export type SoundCreateManyFileInputEnvelope = {
  data?: Maybe<Array<SoundCreateManyFileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrackCreateWithoutFileInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  positives?: Maybe<TrackCreatepositivesInput>;
  title: TitleCreateNestedOneWithoutTracksInput;
  favoriteBy?: Maybe<FavoriteCreateNestedManyWithoutTrackInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutTrackInput>;
};

export type TrackCreateOrConnectWithoutFileInput = {
  where: TrackWhereUniqueInput;
  create: TrackCreateWithoutFileInput;
};

export type TrackCreateManyFileInputEnvelope = {
  data?: Maybe<Array<TrackCreateManyFileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SoundUpsertWithWhereUniqueWithoutFileInput = {
  where: SoundWhereUniqueInput;
  update: SoundUpdateWithoutFileInput;
  create: SoundCreateWithoutFileInput;
};

export type SoundUpdateWithWhereUniqueWithoutFileInput = {
  where: SoundWhereUniqueInput;
  data: SoundUpdateWithoutFileInput;
};

export type SoundUpdateManyWithWhereWithoutFileInput = {
  where: SoundScalarWhereInput;
  data: SoundUpdateManyMutationInput;
};

export type SoundScalarWhereInput = {
  AND?: Maybe<Array<SoundScalarWhereInput>>;
  OR?: Maybe<Array<SoundScalarWhereInput>>;
  NOT?: Maybe<Array<SoundScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  position?: Maybe<DecimalNullableFilter>;
  fileId?: Maybe<StringFilter>;
};

export type TrackUpsertWithWhereUniqueWithoutFileInput = {
  where: TrackWhereUniqueInput;
  update: TrackUpdateWithoutFileInput;
  create: TrackCreateWithoutFileInput;
};

export type TrackUpdateWithWhereUniqueWithoutFileInput = {
  where: TrackWhereUniqueInput;
  data: TrackUpdateWithoutFileInput;
};

export type TrackUpdateManyWithWhereWithoutFileInput = {
  where: TrackScalarWhereInput;
  data: TrackUpdateManyMutationInput;
};

export type TrackScalarWhereInput = {
  AND?: Maybe<Array<TrackScalarWhereInput>>;
  OR?: Maybe<Array<TrackScalarWhereInput>>;
  NOT?: Maybe<Array<TrackScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  soundMuted?: Maybe<BoolFilter>;
  silenceStart?: Maybe<DecimalFilter>;
  silenceEnd?: Maybe<DecimalFilter>;
  positives?: Maybe<StringNullableListFilter>;
  position?: Maybe<DecimalNullableFilter>;
  fileId?: Maybe<StringFilter>;
  titleId?: Maybe<StringFilter>;
};

export type FileCreateWithoutSoundsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  encoding?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  path: Scalars['String'];
  shortId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Decimal']>;
  tracks?: Maybe<TrackCreateNestedManyWithoutFileInput>;
};

export type FileCreateOrConnectWithoutSoundsInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutSoundsInput;
};

export type TitleCreateWithoutDefaultSoundInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutDefaultSoundInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutDefaultSoundInput;
};

export type TitleCreateManyDefaultSoundInputEnvelope = {
  data?: Maybe<Array<TitleCreateManyDefaultSoundInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileUpsertWithoutSoundsInput = {
  update: FileUpdateWithoutSoundsInput;
  create: FileCreateWithoutSoundsInput;
};

export type FileUpdateWithoutSoundsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  encoding?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mimetype?: Maybe<StringFieldUpdateOperationsInput>;
  path?: Maybe<StringFieldUpdateOperationsInput>;
  shortId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  duration?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  tracks?: Maybe<TrackUpdateManyWithoutFileInput>;
};

export type TitleUpsertWithWhereUniqueWithoutDefaultSoundInput = {
  where: TitleWhereUniqueInput;
  update: TitleUpdateWithoutDefaultSoundInput;
  create: TitleCreateWithoutDefaultSoundInput;
};

export type TitleUpdateWithWhereUniqueWithoutDefaultSoundInput = {
  where: TitleWhereUniqueInput;
  data: TitleUpdateWithoutDefaultSoundInput;
};

export type TitleUpdateManyWithWhereWithoutDefaultSoundInput = {
  where: TitleScalarWhereInput;
  data: TitleUpdateManyMutationInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type SoundCreateWithoutTitlesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  file: FileCreateNestedOneWithoutSoundsInput;
};

export type SoundCreateOrConnectWithoutTitlesInput = {
  where: SoundWhereUniqueInput;
  create: SoundCreateWithoutTitlesInput;
};

export type OpeningCreateWithoutTitleInput = {
  id?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileCreateNestedOneWithoutOpeningsInput>;
};

export type OpeningCreateOrConnectWithoutTitleInput = {
  where: OpeningWhereUniqueInput;
  create: OpeningCreateWithoutTitleInput;
};

export type OpeningCreateManyTitleInputEnvelope = {
  data?: Maybe<Array<OpeningCreateManyTitleInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PurchaseCreateWithoutTitleInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileCreateNestedOneWithoutPurchasesInput>;
};

export type PurchaseCreateOrConnectWithoutTitleInput = {
  where: PurchaseWhereUniqueInput;
  create: PurchaseCreateWithoutTitleInput;
};

export type PurchaseCreateManyTitleInputEnvelope = {
  data?: Maybe<Array<PurchaseCreateManyTitleInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrackCreateWithoutTitleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  positives?: Maybe<TrackCreatepositivesInput>;
  file: FileCreateNestedOneWithoutTracksInput;
  favoriteBy?: Maybe<FavoriteCreateNestedManyWithoutTrackInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutTrackInput>;
};

export type TrackCreateOrConnectWithoutTitleInput = {
  where: TrackWhereUniqueInput;
  create: TrackCreateWithoutTitleInput;
};

export type TrackCreateManyTitleInputEnvelope = {
  data?: Maybe<Array<TrackCreateManyTitleInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccessCreateWithoutTitlesInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessCreateOrConnectWithoutTitlesInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutTitlesInput;
};

export type ActivationCreateWithoutTitlesInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedOneWithoutActivationInput>;
  access?: Maybe<AccessCreateNestedOneWithoutActivationsInput>;
  code?: Maybe<CodeCreateNestedOneWithoutActivationInput>;
  profile?: Maybe<ProfileCreateNestedOneWithoutActivationsInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutActivationsInput>;
};

export type ActivationCreateOrConnectWithoutTitlesInput = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateWithoutTitlesInput;
};

export type CollectionCreateWithoutTitlesInput = {
  id?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
};

export type CollectionCreateOrConnectWithoutTitlesInput = {
  where: CollectionWhereUniqueInput;
  create: CollectionCreateWithoutTitlesInput;
};

export type PromoCreateWithoutTitlesInput = {
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  access?: Maybe<AccessCreateNestedOneWithoutPromoInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutPromoInput>;
  codes?: Maybe<CodeCreateNestedManyWithoutPromoInput>;
};

export type PromoCreateOrConnectWithoutTitlesInput = {
  where: PromoWhereUniqueInput;
  create: PromoCreateWithoutTitlesInput;
};

export type SoundUpsertWithoutTitlesInput = {
  update: SoundUpdateWithoutTitlesInput;
  create: SoundCreateWithoutTitlesInput;
};

export type SoundUpdateWithoutTitlesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  file?: Maybe<FileUpdateOneRequiredWithoutSoundsInput>;
};

export type OpeningUpsertWithWhereUniqueWithoutTitleInput = {
  where: OpeningWhereUniqueInput;
  update: OpeningUpdateWithoutTitleInput;
  create: OpeningCreateWithoutTitleInput;
};

export type OpeningUpdateWithWhereUniqueWithoutTitleInput = {
  where: OpeningWhereUniqueInput;
  data: OpeningUpdateWithoutTitleInput;
};

export type OpeningUpdateManyWithWhereWithoutTitleInput = {
  where: OpeningScalarWhereInput;
  data: OpeningUpdateManyMutationInput;
};

export type OpeningScalarWhereInput = {
  AND?: Maybe<Array<OpeningScalarWhereInput>>;
  OR?: Maybe<Array<OpeningScalarWhereInput>>;
  NOT?: Maybe<Array<OpeningScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  lastPlayed?: Maybe<DateTimeNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  profileId?: Maybe<StringNullableFilter>;
  titleId?: Maybe<StringFilter>;
};

export type PurchaseUpsertWithWhereUniqueWithoutTitleInput = {
  where: PurchaseWhereUniqueInput;
  update: PurchaseUpdateWithoutTitleInput;
  create: PurchaseCreateWithoutTitleInput;
};

export type PurchaseUpdateWithWhereUniqueWithoutTitleInput = {
  where: PurchaseWhereUniqueInput;
  data: PurchaseUpdateWithoutTitleInput;
};

export type PurchaseUpdateManyWithWhereWithoutTitleInput = {
  where: PurchaseScalarWhereInput;
  data: PurchaseUpdateManyMutationInput;
};

export type PurchaseScalarWhereInput = {
  AND?: Maybe<Array<PurchaseScalarWhereInput>>;
  OR?: Maybe<Array<PurchaseScalarWhereInput>>;
  NOT?: Maybe<Array<PurchaseScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  profileId?: Maybe<StringNullableFilter>;
  titleId?: Maybe<StringFilter>;
};

export type TrackUpsertWithWhereUniqueWithoutTitleInput = {
  where: TrackWhereUniqueInput;
  update: TrackUpdateWithoutTitleInput;
  create: TrackCreateWithoutTitleInput;
};

export type TrackUpdateWithWhereUniqueWithoutTitleInput = {
  where: TrackWhereUniqueInput;
  data: TrackUpdateWithoutTitleInput;
};

export type TrackUpdateManyWithWhereWithoutTitleInput = {
  where: TrackScalarWhereInput;
  data: TrackUpdateManyMutationInput;
};

export type AccessUpsertWithWhereUniqueWithoutTitlesInput = {
  where: AccessWhereUniqueInput;
  update: AccessUpdateWithoutTitlesInput;
  create: AccessCreateWithoutTitlesInput;
};

export type AccessUpdateWithWhereUniqueWithoutTitlesInput = {
  where: AccessWhereUniqueInput;
  data: AccessUpdateWithoutTitlesInput;
};

export type AccessUpdateManyWithWhereWithoutTitlesInput = {
  where: AccessScalarWhereInput;
  data: AccessUpdateManyMutationInput;
};

export type AccessScalarWhereInput = {
  AND?: Maybe<Array<AccessScalarWhereInput>>;
  OR?: Maybe<Array<AccessScalarWhereInput>>;
  NOT?: Maybe<Array<AccessScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  expiration?: Maybe<IntNullableFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  useBy?: Maybe<DateTimeNullableFilter>;
  useAllTitles?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  showInMyCollection?: Maybe<BoolNullableFilter>;
  remindAfterSubscription?: Maybe<StringNullableFilter>;
};

export type ActivationUpsertWithWhereUniqueWithoutTitlesInput = {
  where: ActivationWhereUniqueInput;
  update: ActivationUpdateWithoutTitlesInput;
  create: ActivationCreateWithoutTitlesInput;
};

export type ActivationUpdateWithWhereUniqueWithoutTitlesInput = {
  where: ActivationWhereUniqueInput;
  data: ActivationUpdateWithoutTitlesInput;
};

export type ActivationUpdateManyWithWhereWithoutTitlesInput = {
  where: ActivationScalarWhereInput;
  data: ActivationUpdateManyMutationInput;
};

export type ActivationScalarWhereInput = {
  AND?: Maybe<Array<ActivationScalarWhereInput>>;
  OR?: Maybe<Array<ActivationScalarWhereInput>>;
  NOT?: Maybe<Array<ActivationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  expiration?: Maybe<DateTimeFilter>;
  useAllTitles?: Maybe<BoolNullableFilter>;
  activatedAt?: Maybe<DateTimeFilter>;
  showInMyCollection?: Maybe<BoolNullableFilter>;
  codeId?: Maybe<StringNullableFilter>;
  profileId?: Maybe<StringNullableFilter>;
  promoId?: Maybe<StringNullableFilter>;
  accessId?: Maybe<StringNullableFilter>;
};

export type CollectionUpsertWithWhereUniqueWithoutTitlesInput = {
  where: CollectionWhereUniqueInput;
  update: CollectionUpdateWithoutTitlesInput;
  create: CollectionCreateWithoutTitlesInput;
};

export type CollectionUpdateWithWhereUniqueWithoutTitlesInput = {
  where: CollectionWhereUniqueInput;
  data: CollectionUpdateWithoutTitlesInput;
};

export type CollectionUpdateManyWithWhereWithoutTitlesInput = {
  where: CollectionScalarWhereInput;
  data: CollectionUpdateManyMutationInput;
};

export type CollectionScalarWhereInput = {
  AND?: Maybe<Array<CollectionScalarWhereInput>>;
  OR?: Maybe<Array<CollectionScalarWhereInput>>;
  NOT?: Maybe<Array<CollectionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  color?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  position?: Maybe<DecimalNullableFilter>;
};

export type PromoUpsertWithWhereUniqueWithoutTitlesInput = {
  where: PromoWhereUniqueInput;
  update: PromoUpdateWithoutTitlesInput;
  create: PromoCreateWithoutTitlesInput;
};

export type PromoUpdateWithWhereUniqueWithoutTitlesInput = {
  where: PromoWhereUniqueInput;
  data: PromoUpdateWithoutTitlesInput;
};

export type PromoUpdateManyWithWhereWithoutTitlesInput = {
  where: PromoScalarWhereInput;
  data: PromoUpdateManyMutationInput;
};

export type PromoScalarWhereInput = {
  AND?: Maybe<Array<PromoScalarWhereInput>>;
  OR?: Maybe<Array<PromoScalarWhereInput>>;
  NOT?: Maybe<Array<PromoScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  template?: Maybe<StringNullableFilter>;
  expiration?: Maybe<IntNullableFilter>;
  count?: Maybe<IntNullableFilter>;
  expirationDate?: Maybe<DateTimeNullableFilter>;
  useBy?: Maybe<DateTimeNullableFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringNullableFilter>;
  useAllTitles?: Maybe<BoolNullableFilter>;
  sku?: Maybe<StringNullableFilter>;
  showInMyCollection?: Maybe<BoolNullableFilter>;
  accessId?: Maybe<StringNullableFilter>;
};

export type NestedDecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type FileCreateWithoutTracksInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  encoding?: Maybe<Scalars['String']>;
  mimetype: Scalars['String'];
  path: Scalars['String'];
  shortId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Decimal']>;
  sounds?: Maybe<SoundCreateNestedManyWithoutFileInput>;
};

export type FileCreateOrConnectWithoutTracksInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutTracksInput;
};

export type TitleCreateWithoutTracksInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutTracksInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutTracksInput;
};

export type FavoriteCreateWithoutTrackInput = {
  id?: Maybe<Scalars['String']>;
  addedDate: Scalars['DateTime'];
  profile: ProfileCreateNestedOneWithoutFavoritesInput;
};

export type FavoriteCreateOrConnectWithoutTrackInput = {
  where: FavoriteWhereUniqueInput;
  create: FavoriteCreateWithoutTrackInput;
};

export type FavoriteCreateManyTrackInputEnvelope = {
  data?: Maybe<Array<FavoriteCreateManyTrackInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ListeningCreateWithoutTrackInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  profile: ProfileCreateNestedOneWithoutListeningsInput;
};

export type ListeningCreateOrConnectWithoutTrackInput = {
  where: ListeningWhereUniqueInput;
  create: ListeningCreateWithoutTrackInput;
};

export type ListeningCreateManyTrackInputEnvelope = {
  data?: Maybe<Array<ListeningCreateManyTrackInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileUpsertWithoutTracksInput = {
  update: FileUpdateWithoutTracksInput;
  create: FileCreateWithoutTracksInput;
};

export type FileUpdateWithoutTracksInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  encoding?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mimetype?: Maybe<StringFieldUpdateOperationsInput>;
  path?: Maybe<StringFieldUpdateOperationsInput>;
  shortId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  duration?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  sounds?: Maybe<SoundUpdateManyWithoutFileInput>;
};

export type TitleUpsertWithoutTracksInput = {
  update: TitleUpdateWithoutTracksInput;
  create: TitleCreateWithoutTracksInput;
};

export type TitleUpdateWithoutTracksInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type FavoriteUpsertWithWhereUniqueWithoutTrackInput = {
  where: FavoriteWhereUniqueInput;
  update: FavoriteUpdateWithoutTrackInput;
  create: FavoriteCreateWithoutTrackInput;
};

export type FavoriteUpdateWithWhereUniqueWithoutTrackInput = {
  where: FavoriteWhereUniqueInput;
  data: FavoriteUpdateWithoutTrackInput;
};

export type FavoriteUpdateManyWithWhereWithoutTrackInput = {
  where: FavoriteScalarWhereInput;
  data: FavoriteUpdateManyMutationInput;
};

export type FavoriteScalarWhereInput = {
  AND?: Maybe<Array<FavoriteScalarWhereInput>>;
  OR?: Maybe<Array<FavoriteScalarWhereInput>>;
  NOT?: Maybe<Array<FavoriteScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  addedDate?: Maybe<DateTimeFilter>;
  profileId?: Maybe<StringFilter>;
  trackId?: Maybe<StringFilter>;
};

export type ListeningUpsertWithWhereUniqueWithoutTrackInput = {
  where: ListeningWhereUniqueInput;
  update: ListeningUpdateWithoutTrackInput;
  create: ListeningCreateWithoutTrackInput;
};

export type ListeningUpdateWithWhereUniqueWithoutTrackInput = {
  where: ListeningWhereUniqueInput;
  data: ListeningUpdateWithoutTrackInput;
};

export type ListeningUpdateManyWithWhereWithoutTrackInput = {
  where: ListeningScalarWhereInput;
  data: ListeningUpdateManyMutationInput;
};

export type ListeningScalarWhereInput = {
  AND?: Maybe<Array<ListeningScalarWhereInput>>;
  OR?: Maybe<Array<ListeningScalarWhereInput>>;
  NOT?: Maybe<Array<ListeningScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  count?: Maybe<IntFilter>;
  profileId?: Maybe<StringFilter>;
  trackId?: Maybe<StringFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type AccessCreateWithoutPromoInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessCreateOrConnectWithoutPromoInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutPromoInput;
};

export type ActivationCreateWithoutPromoInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedOneWithoutActivationInput>;
  access?: Maybe<AccessCreateNestedOneWithoutActivationsInput>;
  code?: Maybe<CodeCreateNestedOneWithoutActivationInput>;
  profile?: Maybe<ProfileCreateNestedOneWithoutActivationsInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutActivationsInput>;
};

export type ActivationCreateOrConnectWithoutPromoInput = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateWithoutPromoInput;
};

export type ActivationCreateManyPromoInputEnvelope = {
  data?: Maybe<Array<ActivationCreateManyPromoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CodeCreateWithoutPromoInput = {
  id?: Maybe<Scalars['String']>;
  used?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  activation?: Maybe<ActivationCreateNestedOneWithoutCodeInput>;
};

export type CodeCreateOrConnectWithoutPromoInput = {
  where: CodeWhereUniqueInput;
  create: CodeCreateWithoutPromoInput;
};

export type CodeCreateManyPromoInputEnvelope = {
  data?: Maybe<Array<CodeCreateManyPromoInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TitleCreateWithoutPromosInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutPromosInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutPromosInput;
};

export type AccessUpsertWithoutPromoInput = {
  update: AccessUpdateWithoutPromoInput;
  create: AccessCreateWithoutPromoInput;
};

export type AccessUpdateWithoutPromoInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type ActivationUpsertWithWhereUniqueWithoutPromoInput = {
  where: ActivationWhereUniqueInput;
  update: ActivationUpdateWithoutPromoInput;
  create: ActivationCreateWithoutPromoInput;
};

export type ActivationUpdateWithWhereUniqueWithoutPromoInput = {
  where: ActivationWhereUniqueInput;
  data: ActivationUpdateWithoutPromoInput;
};

export type ActivationUpdateManyWithWhereWithoutPromoInput = {
  where: ActivationScalarWhereInput;
  data: ActivationUpdateManyMutationInput;
};

export type CodeUpsertWithWhereUniqueWithoutPromoInput = {
  where: CodeWhereUniqueInput;
  update: CodeUpdateWithoutPromoInput;
  create: CodeCreateWithoutPromoInput;
};

export type CodeUpdateWithWhereUniqueWithoutPromoInput = {
  where: CodeWhereUniqueInput;
  data: CodeUpdateWithoutPromoInput;
};

export type CodeUpdateManyWithWhereWithoutPromoInput = {
  where: CodeScalarWhereInput;
  data: CodeUpdateManyMutationInput;
};

export type CodeScalarWhereInput = {
  AND?: Maybe<Array<CodeScalarWhereInput>>;
  OR?: Maybe<Array<CodeScalarWhereInput>>;
  NOT?: Maybe<Array<CodeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  used?: Maybe<BoolNullableFilter>;
  code?: Maybe<StringFilter>;
  promoId?: Maybe<StringFilter>;
};

export type TitleUpsertWithWhereUniqueWithoutPromosInput = {
  where: TitleWhereUniqueInput;
  update: TitleUpdateWithoutPromosInput;
  create: TitleCreateWithoutPromosInput;
};

export type TitleUpdateWithWhereUniqueWithoutPromosInput = {
  where: TitleWhereUniqueInput;
  data: TitleUpdateWithoutPromosInput;
};

export type TitleUpdateManyWithWhereWithoutPromosInput = {
  where: TitleScalarWhereInput;
  data: TitleUpdateManyMutationInput;
};

export type PromoCreateWithoutCodesInput = {
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  access?: Maybe<AccessCreateNestedOneWithoutPromoInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutPromoInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutPromosInput>;
};

export type PromoCreateOrConnectWithoutCodesInput = {
  where: PromoWhereUniqueInput;
  create: PromoCreateWithoutCodesInput;
};

export type ActivationCreateWithoutCodeInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedOneWithoutActivationInput>;
  access?: Maybe<AccessCreateNestedOneWithoutActivationsInput>;
  profile?: Maybe<ProfileCreateNestedOneWithoutActivationsInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutActivationsInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutActivationsInput>;
};

export type ActivationCreateOrConnectWithoutCodeInput = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateWithoutCodeInput;
};

export type PromoUpsertWithoutCodesInput = {
  update: PromoUpdateWithoutCodesInput;
  create: PromoCreateWithoutCodesInput;
};

export type PromoUpdateWithoutCodesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  template?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  count?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  access?: Maybe<AccessUpdateOneWithoutPromoInput>;
  activations?: Maybe<ActivationUpdateManyWithoutPromoInput>;
  titles?: Maybe<TitleUpdateManyWithoutPromosInput>;
};

export type ActivationUpsertWithoutCodeInput = {
  update: ActivationUpdateWithoutCodeInput;
  create: ActivationCreateWithoutCodeInput;
};

export type ActivationUpdateWithoutCodeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateOneWithoutActivationInput>;
  access?: Maybe<AccessUpdateOneWithoutActivationsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutActivationsInput>;
  promo?: Maybe<PromoUpdateOneWithoutActivationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutActivationsInput>;
};

export type ProfileCreateWithoutOpeningsInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutOpeningsInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutOpeningsInput;
};

export type TitleCreateWithoutOpeningsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutOpeningsInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutOpeningsInput;
};

export type ProfileUpsertWithoutOpeningsInput = {
  update: ProfileUpdateWithoutOpeningsInput;
  create: ProfileCreateWithoutOpeningsInput;
};

export type ProfileUpdateWithoutOpeningsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type TitleUpsertWithoutOpeningsInput = {
  update: TitleUpdateWithoutOpeningsInput;
  create: TitleCreateWithoutOpeningsInput;
};

export type TitleUpdateWithoutOpeningsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type ProfileCreateWithoutFavoritesInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutFavoritesInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutFavoritesInput;
};

export type TrackCreateWithoutFavoriteByInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  positives?: Maybe<TrackCreatepositivesInput>;
  file: FileCreateNestedOneWithoutTracksInput;
  title: TitleCreateNestedOneWithoutTracksInput;
  listenings?: Maybe<ListeningCreateNestedManyWithoutTrackInput>;
};

export type TrackCreateOrConnectWithoutFavoriteByInput = {
  where: TrackWhereUniqueInput;
  create: TrackCreateWithoutFavoriteByInput;
};

export type ProfileUpsertWithoutFavoritesInput = {
  update: ProfileUpdateWithoutFavoritesInput;
  create: ProfileCreateWithoutFavoritesInput;
};

export type ProfileUpdateWithoutFavoritesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type TrackUpsertWithoutFavoriteByInput = {
  update: TrackUpdateWithoutFavoriteByInput;
  create: TrackCreateWithoutFavoriteByInput;
};

export type TrackUpdateWithoutFavoriteByInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  soundMuted?: Maybe<BoolFieldUpdateOperationsInput>;
  silenceStart?: Maybe<DecimalFieldUpdateOperationsInput>;
  silenceEnd?: Maybe<DecimalFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  positives?: Maybe<TrackUpdatepositivesInput>;
  file?: Maybe<FileUpdateOneRequiredWithoutTracksInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutTracksInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutTrackInput>;
};

export type ProfileCreateWithoutPurchasesInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutPurchasesInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutPurchasesInput;
};

export type TitleCreateWithoutPurchasedByInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutPurchasedByInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutPurchasedByInput;
};

export type ProfileUpsertWithoutPurchasesInput = {
  update: ProfileUpdateWithoutPurchasesInput;
  create: ProfileCreateWithoutPurchasesInput;
};

export type ProfileUpdateWithoutPurchasesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type TitleUpsertWithoutPurchasedByInput = {
  update: TitleUpdateWithoutPurchasedByInput;
  create: TitleCreateWithoutPurchasedByInput;
};

export type TitleUpdateWithoutPurchasedByInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type InAppPurchaseActivationCreateWithoutActivationInput = {
  id?: Maybe<Scalars['String']>;
  willRenew: Scalars['Boolean'];
  shouldCancel?: Maybe<Scalars['Boolean']>;
  purchaseId: Scalars['String'];
  platform?: Maybe<InAppPurchasePlatform>;
  inAppPurchase: InAppPurchaseCreateNestedOneWithoutInAppPurchaseActivationInput;
};

export type InAppPurchaseActivationCreateOrConnectWithoutActivationInput = {
  where: InAppPurchaseActivationWhereUniqueInput;
  create: InAppPurchaseActivationCreateWithoutActivationInput;
};

export type InAppPurchaseActivationUpsertWithoutActivationInput = {
  update: InAppPurchaseActivationUpdateWithoutActivationInput;
  create: InAppPurchaseActivationCreateWithoutActivationInput;
};

export type InAppPurchaseActivationUpdateWithoutActivationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  willRenew?: Maybe<BoolFieldUpdateOperationsInput>;
  shouldCancel?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  purchaseId?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumInAppPurchasePlatformFieldUpdateOperationsInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneRequiredWithoutInAppPurchaseActivationInput>;
};

export type AccessCreateWithoutActivationsInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessCreateOrConnectWithoutActivationsInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutActivationsInput;
};

export type AccessUpsertWithoutActivationsInput = {
  update: AccessUpdateWithoutActivationsInput;
  create: AccessCreateWithoutActivationsInput;
};

export type AccessUpdateWithoutActivationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type CodeCreateWithoutActivationInput = {
  id?: Maybe<Scalars['String']>;
  used?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  promo: PromoCreateNestedOneWithoutCodesInput;
};

export type CodeCreateOrConnectWithoutActivationInput = {
  where: CodeWhereUniqueInput;
  create: CodeCreateWithoutActivationInput;
};

export type CodeUpsertWithoutActivationInput = {
  update: CodeUpdateWithoutActivationInput;
  create: CodeCreateWithoutActivationInput;
};

export type CodeUpdateWithoutActivationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  used?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  promo?: Maybe<PromoUpdateOneRequiredWithoutCodesInput>;
};

export type ProfileCreateWithoutActivationsInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutActivationsInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutActivationsInput;
};

export type ProfileUpsertWithoutActivationsInput = {
  update: ProfileUpdateWithoutActivationsInput;
  create: ProfileCreateWithoutActivationsInput;
};

export type ProfileUpdateWithoutActivationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type PromoCreateWithoutActivationsInput = {
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  access?: Maybe<AccessCreateNestedOneWithoutPromoInput>;
  codes?: Maybe<CodeCreateNestedManyWithoutPromoInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutPromosInput>;
};

export type PromoCreateOrConnectWithoutActivationsInput = {
  where: PromoWhereUniqueInput;
  create: PromoCreateWithoutActivationsInput;
};

export type PromoUpsertWithoutActivationsInput = {
  update: PromoUpdateWithoutActivationsInput;
  create: PromoCreateWithoutActivationsInput;
};

export type PromoUpdateWithoutActivationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  template?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  count?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  access?: Maybe<AccessUpdateOneWithoutPromoInput>;
  codes?: Maybe<CodeUpdateManyWithoutPromoInput>;
  titles?: Maybe<TitleUpdateManyWithoutPromosInput>;
};

export type TitleCreateWithoutActivationsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  accesses?: Maybe<AccessCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutActivationsInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutActivationsInput;
};

export type TitleUpsertWithWhereUniqueWithoutActivationsInput = {
  where: TitleWhereUniqueInput;
  update: TitleUpdateWithoutActivationsInput;
  create: TitleCreateWithoutActivationsInput;
};

export type TitleUpdateWithWhereUniqueWithoutActivationsInput = {
  where: TitleWhereUniqueInput;
  data: TitleUpdateWithoutActivationsInput;
};

export type TitleUpdateManyWithWhereWithoutActivationsInput = {
  where: TitleScalarWhereInput;
  data: TitleUpdateManyMutationInput;
};

export type SessionWhereInput = {
  AND?: Maybe<Array<SessionWhereInput>>;
  OR?: Maybe<Array<SessionWhereInput>>;
  NOT?: Maybe<Array<SessionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  token?: Maybe<StringFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringFilter>;
  User?: Maybe<UserWhereInput>;
  userId?: Maybe<StringNullableFilter>;
};

export type VerificationWhereInput = {
  AND?: Maybe<Array<VerificationWhereInput>>;
  OR?: Maybe<Array<VerificationWhereInput>>;
  NOT?: Maybe<Array<VerificationWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumVerificationTypeFilter>;
  value?: Maybe<StringFilter>;
  profile?: Maybe<ProfileWhereInput>;
  profileId?: Maybe<StringFilter>;
};

export type ActivationCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedOneWithoutActivationInput>;
  access?: Maybe<AccessCreateNestedOneWithoutActivationsInput>;
  code?: Maybe<CodeCreateNestedOneWithoutActivationInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutActivationsInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutActivationsInput>;
};

export type ActivationCreateOrConnectWithoutProfileInput = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateWithoutProfileInput;
};

export type ActivationCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<ActivationCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FavoriteCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  addedDate: Scalars['DateTime'];
  track: TrackCreateNestedOneWithoutFavoriteByInput;
};

export type FavoriteCreateOrConnectWithoutProfileInput = {
  where: FavoriteWhereUniqueInput;
  create: FavoriteCreateWithoutProfileInput;
};

export type FavoriteCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<FavoriteCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ListeningCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  track: TrackCreateNestedOneWithoutListeningsInput;
};

export type ListeningCreateOrConnectWithoutProfileInput = {
  where: ListeningWhereUniqueInput;
  create: ListeningCreateWithoutProfileInput;
};

export type ListeningCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<ListeningCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type OpeningCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  title: TitleCreateNestedOneWithoutOpeningsInput;
};

export type OpeningCreateOrConnectWithoutProfileInput = {
  where: OpeningWhereUniqueInput;
  create: OpeningCreateWithoutProfileInput;
};

export type OpeningCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<OpeningCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PurchaseCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title: TitleCreateNestedOneWithoutPurchasedByInput;
};

export type PurchaseCreateOrConnectWithoutProfileInput = {
  where: PurchaseWhereUniqueInput;
  create: PurchaseCreateWithoutProfileInput;
};

export type PurchaseCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<PurchaseCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SessionCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  User?: Maybe<UserCreateNestedOneWithoutSessionInput>;
};

export type SessionCreateOrConnectWithoutProfileInput = {
  where: SessionWhereUniqueInput;
  create: SessionCreateWithoutProfileInput;
};

export type SessionCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<SessionCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SessionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type VerificationCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: VerificationType;
  value: Scalars['String'];
};

export type VerificationCreateOrConnectWithoutProfileInput = {
  where: VerificationWhereUniqueInput;
  create: VerificationCreateWithoutProfileInput;
};

export type VerificationCreateManyProfileInputEnvelope = {
  data?: Maybe<Array<VerificationCreateManyProfileInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type VerificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DiscountProposalCreateWithoutProfileInput = {
  id?: Maybe<Scalars['String']>;
  discount: DiscountCreateNestedOneWithoutDiscountProposalsInput;
};

export type DiscountProposalCreateOrConnectWithoutProfileInput = {
  where: DiscountProposalWhereUniqueInput;
  create: DiscountProposalCreateWithoutProfileInput;
};

export type ActivationUpsertWithWhereUniqueWithoutProfileInput = {
  where: ActivationWhereUniqueInput;
  update: ActivationUpdateWithoutProfileInput;
  create: ActivationCreateWithoutProfileInput;
};

export type ActivationUpdateWithWhereUniqueWithoutProfileInput = {
  where: ActivationWhereUniqueInput;
  data: ActivationUpdateWithoutProfileInput;
};

export type ActivationUpdateManyWithWhereWithoutProfileInput = {
  where: ActivationScalarWhereInput;
  data: ActivationUpdateManyMutationInput;
};

export type FavoriteUpsertWithWhereUniqueWithoutProfileInput = {
  where: FavoriteWhereUniqueInput;
  update: FavoriteUpdateWithoutProfileInput;
  create: FavoriteCreateWithoutProfileInput;
};

export type FavoriteUpdateWithWhereUniqueWithoutProfileInput = {
  where: FavoriteWhereUniqueInput;
  data: FavoriteUpdateWithoutProfileInput;
};

export type FavoriteUpdateManyWithWhereWithoutProfileInput = {
  where: FavoriteScalarWhereInput;
  data: FavoriteUpdateManyMutationInput;
};

export type ListeningUpsertWithWhereUniqueWithoutProfileInput = {
  where: ListeningWhereUniqueInput;
  update: ListeningUpdateWithoutProfileInput;
  create: ListeningCreateWithoutProfileInput;
};

export type ListeningUpdateWithWhereUniqueWithoutProfileInput = {
  where: ListeningWhereUniqueInput;
  data: ListeningUpdateWithoutProfileInput;
};

export type ListeningUpdateManyWithWhereWithoutProfileInput = {
  where: ListeningScalarWhereInput;
  data: ListeningUpdateManyMutationInput;
};

export type OpeningUpsertWithWhereUniqueWithoutProfileInput = {
  where: OpeningWhereUniqueInput;
  update: OpeningUpdateWithoutProfileInput;
  create: OpeningCreateWithoutProfileInput;
};

export type OpeningUpdateWithWhereUniqueWithoutProfileInput = {
  where: OpeningWhereUniqueInput;
  data: OpeningUpdateWithoutProfileInput;
};

export type OpeningUpdateManyWithWhereWithoutProfileInput = {
  where: OpeningScalarWhereInput;
  data: OpeningUpdateManyMutationInput;
};

export type PurchaseUpsertWithWhereUniqueWithoutProfileInput = {
  where: PurchaseWhereUniqueInput;
  update: PurchaseUpdateWithoutProfileInput;
  create: PurchaseCreateWithoutProfileInput;
};

export type PurchaseUpdateWithWhereUniqueWithoutProfileInput = {
  where: PurchaseWhereUniqueInput;
  data: PurchaseUpdateWithoutProfileInput;
};

export type PurchaseUpdateManyWithWhereWithoutProfileInput = {
  where: PurchaseScalarWhereInput;
  data: PurchaseUpdateManyMutationInput;
};

export type SessionUpsertWithWhereUniqueWithoutProfileInput = {
  where: SessionWhereUniqueInput;
  update: SessionUpdateWithoutProfileInput;
  create: SessionCreateWithoutProfileInput;
};

export type SessionUpdateWithWhereUniqueWithoutProfileInput = {
  where: SessionWhereUniqueInput;
  data: SessionUpdateWithoutProfileInput;
};

export type SessionUpdateManyWithWhereWithoutProfileInput = {
  where: SessionScalarWhereInput;
  data: SessionUpdateManyMutationInput;
};

export type SessionScalarWhereInput = {
  AND?: Maybe<Array<SessionScalarWhereInput>>;
  OR?: Maybe<Array<SessionScalarWhereInput>>;
  NOT?: Maybe<Array<SessionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  token?: Maybe<StringFilter>;
  profileId?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type VerificationUpsertWithWhereUniqueWithoutProfileInput = {
  where: VerificationWhereUniqueInput;
  update: VerificationUpdateWithoutProfileInput;
  create: VerificationCreateWithoutProfileInput;
};

export type VerificationUpdateWithWhereUniqueWithoutProfileInput = {
  where: VerificationWhereUniqueInput;
  data: VerificationUpdateWithoutProfileInput;
};

export type VerificationUpdateManyWithWhereWithoutProfileInput = {
  where: VerificationScalarWhereInput;
  data: VerificationUpdateManyMutationInput;
};

export type VerificationScalarWhereInput = {
  AND?: Maybe<Array<VerificationScalarWhereInput>>;
  OR?: Maybe<Array<VerificationScalarWhereInput>>;
  NOT?: Maybe<Array<VerificationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumVerificationTypeFilter>;
  value?: Maybe<StringFilter>;
  profileId?: Maybe<StringFilter>;
};

export type DiscountProposalUpsertWithoutProfileInput = {
  update: DiscountProposalUpdateWithoutProfileInput;
  create: DiscountProposalCreateWithoutProfileInput;
};

export type DiscountProposalUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  discount?: Maybe<DiscountUpdateOneRequiredWithoutDiscountProposalsInput>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type ProfileCreateWithoutListeningsInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutListeningsInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutListeningsInput;
};

export type TrackCreateWithoutListeningsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  positives?: Maybe<TrackCreatepositivesInput>;
  file: FileCreateNestedOneWithoutTracksInput;
  title: TitleCreateNestedOneWithoutTracksInput;
  favoriteBy?: Maybe<FavoriteCreateNestedManyWithoutTrackInput>;
};

export type TrackCreateOrConnectWithoutListeningsInput = {
  where: TrackWhereUniqueInput;
  create: TrackCreateWithoutListeningsInput;
};

export type ProfileUpsertWithoutListeningsInput = {
  update: ProfileUpdateWithoutListeningsInput;
  create: ProfileCreateWithoutListeningsInput;
};

export type ProfileUpdateWithoutListeningsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type TrackUpsertWithoutListeningsInput = {
  update: TrackUpdateWithoutListeningsInput;
  create: TrackCreateWithoutListeningsInput;
};

export type TrackUpdateWithoutListeningsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  soundMuted?: Maybe<BoolFieldUpdateOperationsInput>;
  silenceStart?: Maybe<DecimalFieldUpdateOperationsInput>;
  silenceEnd?: Maybe<DecimalFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  positives?: Maybe<TrackUpdatepositivesInput>;
  file?: Maybe<FileUpdateOneRequiredWithoutTracksInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutTracksInput>;
  favoriteBy?: Maybe<FavoriteUpdateManyWithoutTrackInput>;
};

export type SessionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  profile: ProfileCreateNestedOneWithoutSessionsInput;
};

export type SessionCreateOrConnectWithoutUserInput = {
  where: SessionWhereUniqueInput;
  create: SessionCreateWithoutUserInput;
};

export type SessionCreateManyUserInputEnvelope = {
  data?: Maybe<Array<SessionCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SessionUpsertWithWhereUniqueWithoutUserInput = {
  where: SessionWhereUniqueInput;
  update: SessionUpdateWithoutUserInput;
  create: SessionCreateWithoutUserInput;
};

export type SessionUpdateWithWhereUniqueWithoutUserInput = {
  where: SessionWhereUniqueInput;
  data: SessionUpdateWithoutUserInput;
};

export type SessionUpdateManyWithWhereWithoutUserInput = {
  where: SessionScalarWhereInput;
  data: SessionUpdateManyMutationInput;
};

export type NestedEnumParameterKeyFilter = {
  equals?: Maybe<ParameterKey>;
  in?: Maybe<Array<ParameterKey>>;
  notIn?: Maybe<Array<ParameterKey>>;
  not?: Maybe<NestedEnumParameterKeyFilter>;
};

export type NestedEnumQuestionScreenFilter = {
  equals?: Maybe<QuestionScreen>;
  in?: Maybe<Array<QuestionScreen>>;
  notIn?: Maybe<Array<QuestionScreen>>;
  not?: Maybe<NestedEnumQuestionScreenFilter>;
};

export type QuestionCreateWithoutQuestionsInput = {
  id?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  screen?: Maybe<QuestionScreen>;
  parent?: Maybe<QuestionCreateNestedOneWithoutQuestionsInput>;
};

export type QuestionCreateOrConnectWithoutQuestionsInput = {
  where: QuestionWhereUniqueInput;
  create: QuestionCreateWithoutQuestionsInput;
};

export type QuestionCreateWithoutParentInput = {
  id?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  screen?: Maybe<QuestionScreen>;
  questions?: Maybe<QuestionCreateNestedManyWithoutParentInput>;
};

export type QuestionCreateOrConnectWithoutParentInput = {
  where: QuestionWhereUniqueInput;
  create: QuestionCreateWithoutParentInput;
};

export type QuestionCreateManyParentInputEnvelope = {
  data?: Maybe<Array<QuestionCreateManyParentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type QuestionUpsertWithoutQuestionsInput = {
  update: QuestionUpdateWithoutQuestionsInput;
  create: QuestionCreateWithoutQuestionsInput;
};

export type QuestionUpdateWithoutQuestionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  screen?: Maybe<EnumQuestionScreenFieldUpdateOperationsInput>;
  parent?: Maybe<QuestionUpdateOneWithoutQuestionsInput>;
};

export type QuestionUpsertWithWhereUniqueWithoutParentInput = {
  where: QuestionWhereUniqueInput;
  update: QuestionUpdateWithoutParentInput;
  create: QuestionCreateWithoutParentInput;
};

export type QuestionUpdateWithWhereUniqueWithoutParentInput = {
  where: QuestionWhereUniqueInput;
  data: QuestionUpdateWithoutParentInput;
};

export type QuestionUpdateManyWithWhereWithoutParentInput = {
  where: QuestionScalarWhereInput;
  data: QuestionUpdateManyMutationInput;
};

export type QuestionScalarWhereInput = {
  AND?: Maybe<Array<QuestionScalarWhereInput>>;
  OR?: Maybe<Array<QuestionScalarWhereInput>>;
  NOT?: Maybe<Array<QuestionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  position?: Maybe<DecimalNullableFilter>;
  screen?: Maybe<EnumQuestionScreenFilter>;
  questionId?: Maybe<StringNullableFilter>;
};

export type ActivationCreateWithoutAccessInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedOneWithoutActivationInput>;
  code?: Maybe<CodeCreateNestedOneWithoutActivationInput>;
  profile?: Maybe<ProfileCreateNestedOneWithoutActivationsInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutActivationsInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutActivationsInput>;
};

export type ActivationCreateOrConnectWithoutAccessInput = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateWithoutAccessInput;
};

export type ActivationCreateManyAccessInputEnvelope = {
  data?: Maybe<Array<ActivationCreateManyAccessInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InAppPurchaseCreateWithoutAccessInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  remindBeforeResubscription?: Maybe<Scalars['Int']>;
  planId?: Maybe<InAppPurchaseCreateplanIdInput>;
  InAppPurchaseActivation?: Maybe<InAppPurchaseActivationCreateNestedManyWithoutInAppPurchaseInput>;
};

export type InAppPurchaseCreateOrConnectWithoutAccessInput = {
  where: InAppPurchaseWhereUniqueInput;
  create: InAppPurchaseCreateWithoutAccessInput;
};

export type PromoCreateWithoutAccessInput = {
  id?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutPromoInput>;
  codes?: Maybe<CodeCreateNestedManyWithoutPromoInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutPromosInput>;
};

export type PromoCreateOrConnectWithoutAccessInput = {
  where: PromoWhereUniqueInput;
  create: PromoCreateWithoutAccessInput;
};

export type MagentaSubscriptionCreateWithoutAccessInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sku: Scalars['String'];
};

export type MagentaSubscriptionCreateOrConnectWithoutAccessInput = {
  where: MagentaSubscriptionWhereUniqueInput;
  create: MagentaSubscriptionCreateWithoutAccessInput;
};

export type TitleCreateWithoutAccessesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
  defaultSound?: Maybe<SoundCreateNestedOneWithoutTitlesInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseCreateNestedManyWithoutTitleInput>;
  tracks?: Maybe<TrackCreateNestedManyWithoutTitleInput>;
  activations?: Maybe<ActivationCreateNestedManyWithoutTitlesInput>;
  collections?: Maybe<CollectionCreateNestedManyWithoutTitlesInput>;
  promos?: Maybe<PromoCreateNestedManyWithoutTitlesInput>;
};

export type TitleCreateOrConnectWithoutAccessesInput = {
  where: TitleWhereUniqueInput;
  create: TitleCreateWithoutAccessesInput;
};

export type DiscountCreateWithoutRequiredAccessesInput = {
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  paywallId: Scalars['String'];
  days: Scalars['Int'];
  relativePosition: RelativePositionEnum;
  position: Scalars['Int'];
  discountProposals?: Maybe<DiscountProposalCreateNestedManyWithoutDiscountInput>;
  disabledAccesses?: Maybe<AccessCreateNestedManyWithoutDisablingDiscountsInput>;
};

export type DiscountCreateOrConnectWithoutRequiredAccessesInput = {
  where: DiscountWhereUniqueInput;
  create: DiscountCreateWithoutRequiredAccessesInput;
};

export type DiscountCreateWithoutDisabledAccessesInput = {
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  paywallId: Scalars['String'];
  days: Scalars['Int'];
  relativePosition: RelativePositionEnum;
  position: Scalars['Int'];
  requiredAccesses?: Maybe<AccessCreateNestedManyWithoutRequiringDiscountsInput>;
  discountProposals?: Maybe<DiscountProposalCreateNestedManyWithoutDiscountInput>;
};

export type DiscountCreateOrConnectWithoutDisabledAccessesInput = {
  where: DiscountWhereUniqueInput;
  create: DiscountCreateWithoutDisabledAccessesInput;
};

export type ActivationUpsertWithWhereUniqueWithoutAccessInput = {
  where: ActivationWhereUniqueInput;
  update: ActivationUpdateWithoutAccessInput;
  create: ActivationCreateWithoutAccessInput;
};

export type ActivationUpdateWithWhereUniqueWithoutAccessInput = {
  where: ActivationWhereUniqueInput;
  data: ActivationUpdateWithoutAccessInput;
};

export type ActivationUpdateManyWithWhereWithoutAccessInput = {
  where: ActivationScalarWhereInput;
  data: ActivationUpdateManyMutationInput;
};

export type InAppPurchaseUpsertWithoutAccessInput = {
  update: InAppPurchaseUpdateWithoutAccessInput;
  create: InAppPurchaseCreateWithoutAccessInput;
};

export type InAppPurchaseUpdateWithoutAccessInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  remindBeforeResubscription?: Maybe<NullableIntFieldUpdateOperationsInput>;
  planId?: Maybe<InAppPurchaseUpdateplanIdInput>;
  InAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateManyWithoutInAppPurchaseInput>;
};

export type PromoUpsertWithoutAccessInput = {
  update: PromoUpdateWithoutAccessInput;
  create: PromoCreateWithoutAccessInput;
};

export type PromoUpdateWithoutAccessInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  template?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  count?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutPromoInput>;
  codes?: Maybe<CodeUpdateManyWithoutPromoInput>;
  titles?: Maybe<TitleUpdateManyWithoutPromosInput>;
};

export type MagentaSubscriptionUpsertWithoutAccessInput = {
  update: MagentaSubscriptionUpdateWithoutAccessInput;
  create: MagentaSubscriptionCreateWithoutAccessInput;
};

export type MagentaSubscriptionUpdateWithoutAccessInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  sku?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TitleUpsertWithWhereUniqueWithoutAccessesInput = {
  where: TitleWhereUniqueInput;
  update: TitleUpdateWithoutAccessesInput;
  create: TitleCreateWithoutAccessesInput;
};

export type TitleUpdateWithWhereUniqueWithoutAccessesInput = {
  where: TitleWhereUniqueInput;
  data: TitleUpdateWithoutAccessesInput;
};

export type TitleUpdateManyWithWhereWithoutAccessesInput = {
  where: TitleScalarWhereInput;
  data: TitleUpdateManyMutationInput;
};

export type DiscountUpsertWithWhereUniqueWithoutRequiredAccessesInput = {
  where: DiscountWhereUniqueInput;
  update: DiscountUpdateWithoutRequiredAccessesInput;
  create: DiscountCreateWithoutRequiredAccessesInput;
};

export type DiscountUpdateWithWhereUniqueWithoutRequiredAccessesInput = {
  where: DiscountWhereUniqueInput;
  data: DiscountUpdateWithoutRequiredAccessesInput;
};

export type DiscountUpdateManyWithWhereWithoutRequiredAccessesInput = {
  where: DiscountScalarWhereInput;
  data: DiscountUpdateManyMutationInput;
};

export type DiscountScalarWhereInput = {
  AND?: Maybe<Array<DiscountScalarWhereInput>>;
  OR?: Maybe<Array<DiscountScalarWhereInput>>;
  NOT?: Maybe<Array<DiscountScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  quantity?: Maybe<IntNullableFilter>;
  paywallId?: Maybe<StringFilter>;
  days?: Maybe<IntFilter>;
  relativePosition?: Maybe<EnumRelativePositionEnumFilter>;
  position?: Maybe<IntFilter>;
};

export type DiscountUpsertWithWhereUniqueWithoutDisabledAccessesInput = {
  where: DiscountWhereUniqueInput;
  update: DiscountUpdateWithoutDisabledAccessesInput;
  create: DiscountCreateWithoutDisabledAccessesInput;
};

export type DiscountUpdateWithWhereUniqueWithoutDisabledAccessesInput = {
  where: DiscountWhereUniqueInput;
  data: DiscountUpdateWithoutDisabledAccessesInput;
};

export type DiscountUpdateManyWithWhereWithoutDisabledAccessesInput = {
  where: DiscountScalarWhereInput;
  data: DiscountUpdateManyMutationInput;
};

export type AccessCreateWithoutInAppPurchaseInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessCreateOrConnectWithoutInAppPurchaseInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutInAppPurchaseInput;
};

export type InAppPurchaseActivationCreateWithoutInAppPurchaseInput = {
  id?: Maybe<Scalars['String']>;
  willRenew: Scalars['Boolean'];
  shouldCancel?: Maybe<Scalars['Boolean']>;
  purchaseId: Scalars['String'];
  platform?: Maybe<InAppPurchasePlatform>;
  activation: ActivationCreateNestedOneWithoutInAppPurchaseActivationInput;
};

export type InAppPurchaseActivationCreateOrConnectWithoutInAppPurchaseInput = {
  where: InAppPurchaseActivationWhereUniqueInput;
  create: InAppPurchaseActivationCreateWithoutInAppPurchaseInput;
};

export type InAppPurchaseActivationCreateManyInAppPurchaseInputEnvelope = {
  data?: Maybe<Array<InAppPurchaseActivationCreateManyInAppPurchaseInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccessUpsertWithoutInAppPurchaseInput = {
  update: AccessUpdateWithoutInAppPurchaseInput;
  create: AccessCreateWithoutInAppPurchaseInput;
};

export type AccessUpdateWithoutInAppPurchaseInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type InAppPurchaseActivationUpsertWithWhereUniqueWithoutInAppPurchaseInput = {
  where: InAppPurchaseActivationWhereUniqueInput;
  update: InAppPurchaseActivationUpdateWithoutInAppPurchaseInput;
  create: InAppPurchaseActivationCreateWithoutInAppPurchaseInput;
};

export type InAppPurchaseActivationUpdateWithWhereUniqueWithoutInAppPurchaseInput = {
  where: InAppPurchaseActivationWhereUniqueInput;
  data: InAppPurchaseActivationUpdateWithoutInAppPurchaseInput;
};

export type InAppPurchaseActivationUpdateManyWithWhereWithoutInAppPurchaseInput = {
  where: InAppPurchaseActivationScalarWhereInput;
  data: InAppPurchaseActivationUpdateManyMutationInput;
};

export type InAppPurchaseActivationScalarWhereInput = {
  AND?: Maybe<Array<InAppPurchaseActivationScalarWhereInput>>;
  OR?: Maybe<Array<InAppPurchaseActivationScalarWhereInput>>;
  NOT?: Maybe<Array<InAppPurchaseActivationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  willRenew?: Maybe<BoolFilter>;
  shouldCancel?: Maybe<BoolNullableFilter>;
  purchaseId?: Maybe<StringFilter>;
  platform?: Maybe<EnumInAppPurchasePlatformFilter>;
  inAppPurchaseId?: Maybe<StringFilter>;
  activationId?: Maybe<StringFilter>;
};

export type AccessCreateWithoutSubscriptionInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessCreateOrConnectWithoutSubscriptionInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutSubscriptionInput;
};

export type AccessUpsertWithoutSubscriptionInput = {
  update: AccessUpdateWithoutSubscriptionInput;
  create: AccessCreateWithoutSubscriptionInput;
};

export type AccessUpdateWithoutSubscriptionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type NestedEnumRelativePositionEnumFilter = {
  equals?: Maybe<RelativePositionEnum>;
  in?: Maybe<Array<RelativePositionEnum>>;
  notIn?: Maybe<Array<RelativePositionEnum>>;
  not?: Maybe<NestedEnumRelativePositionEnumFilter>;
};

export type AccessCreateWithoutRequiringDiscountsInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  disablingDiscounts?: Maybe<DiscountCreateNestedManyWithoutDisabledAccessesInput>;
};

export type AccessCreateOrConnectWithoutRequiringDiscountsInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutRequiringDiscountsInput;
};

export type DiscountProposalCreateWithoutDiscountInput = {
  id?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileCreateNestedOneWithoutDiscountProposalInput>;
};

export type DiscountProposalCreateOrConnectWithoutDiscountInput = {
  where: DiscountProposalWhereUniqueInput;
  create: DiscountProposalCreateWithoutDiscountInput;
};

export type DiscountProposalCreateManyDiscountInputEnvelope = {
  data?: Maybe<Array<DiscountProposalCreateManyDiscountInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccessCreateWithoutDisablingDiscountsInput = {
  id?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Int']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  useBy?: Maybe<Scalars['DateTime']>;
  useAllTitles?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  remindAfterSubscription?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseCreateNestedOneWithoutAccessInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionCreateNestedOneWithoutAccessInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountCreateNestedManyWithoutRequiredAccessesInput>;
};

export type AccessCreateOrConnectWithoutDisablingDiscountsInput = {
  where: AccessWhereUniqueInput;
  create: AccessCreateWithoutDisablingDiscountsInput;
};

export type AccessUpsertWithWhereUniqueWithoutRequiringDiscountsInput = {
  where: AccessWhereUniqueInput;
  update: AccessUpdateWithoutRequiringDiscountsInput;
  create: AccessCreateWithoutRequiringDiscountsInput;
};

export type AccessUpdateWithWhereUniqueWithoutRequiringDiscountsInput = {
  where: AccessWhereUniqueInput;
  data: AccessUpdateWithoutRequiringDiscountsInput;
};

export type AccessUpdateManyWithWhereWithoutRequiringDiscountsInput = {
  where: AccessScalarWhereInput;
  data: AccessUpdateManyMutationInput;
};

export type DiscountProposalUpsertWithWhereUniqueWithoutDiscountInput = {
  where: DiscountProposalWhereUniqueInput;
  update: DiscountProposalUpdateWithoutDiscountInput;
  create: DiscountProposalCreateWithoutDiscountInput;
};

export type DiscountProposalUpdateWithWhereUniqueWithoutDiscountInput = {
  where: DiscountProposalWhereUniqueInput;
  data: DiscountProposalUpdateWithoutDiscountInput;
};

export type DiscountProposalUpdateManyWithWhereWithoutDiscountInput = {
  where: DiscountProposalScalarWhereInput;
  data: DiscountProposalUpdateManyMutationInput;
};

export type DiscountProposalScalarWhereInput = {
  AND?: Maybe<Array<DiscountProposalScalarWhereInput>>;
  OR?: Maybe<Array<DiscountProposalScalarWhereInput>>;
  NOT?: Maybe<Array<DiscountProposalScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  discountId?: Maybe<StringFilter>;
  profileId?: Maybe<StringNullableFilter>;
};

export type AccessUpsertWithWhereUniqueWithoutDisablingDiscountsInput = {
  where: AccessWhereUniqueInput;
  update: AccessUpdateWithoutDisablingDiscountsInput;
  create: AccessCreateWithoutDisablingDiscountsInput;
};

export type AccessUpdateWithWhereUniqueWithoutDisablingDiscountsInput = {
  where: AccessWhereUniqueInput;
  data: AccessUpdateWithoutDisablingDiscountsInput;
};

export type AccessUpdateManyWithWhereWithoutDisablingDiscountsInput = {
  where: AccessScalarWhereInput;
  data: AccessUpdateManyMutationInput;
};

export type DiscountCreateWithoutDiscountProposalsInput = {
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  paywallId: Scalars['String'];
  days: Scalars['Int'];
  relativePosition: RelativePositionEnum;
  position: Scalars['Int'];
  requiredAccesses?: Maybe<AccessCreateNestedManyWithoutRequiringDiscountsInput>;
  disabledAccesses?: Maybe<AccessCreateNestedManyWithoutDisablingDiscountsInput>;
};

export type DiscountCreateOrConnectWithoutDiscountProposalsInput = {
  where: DiscountWhereUniqueInput;
  create: DiscountCreateWithoutDiscountProposalsInput;
};

export type ProfileCreateWithoutDiscountProposalInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutDiscountProposalInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutDiscountProposalInput;
};

export type DiscountUpsertWithoutDiscountProposalsInput = {
  update: DiscountUpdateWithoutDiscountProposalsInput;
  create: DiscountCreateWithoutDiscountProposalsInput;
};

export type DiscountUpdateWithoutDiscountProposalsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  paywallId?: Maybe<StringFieldUpdateOperationsInput>;
  days?: Maybe<IntFieldUpdateOperationsInput>;
  relativePosition?: Maybe<EnumRelativePositionEnumFieldUpdateOperationsInput>;
  position?: Maybe<IntFieldUpdateOperationsInput>;
  requiredAccesses?: Maybe<AccessUpdateManyWithoutRequiringDiscountsInput>;
  disabledAccesses?: Maybe<AccessUpdateManyWithoutDisablingDiscountsInput>;
};

export type ProfileUpsertWithoutDiscountProposalInput = {
  update: ProfileUpdateWithoutDiscountProposalInput;
  create: ProfileCreateWithoutDiscountProposalInput;
};

export type ProfileUpdateWithoutDiscountProposalInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  sessions?: Maybe<SessionUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
};

export type NestedEnumInAppPurchasePlatformFilter = {
  equals?: Maybe<InAppPurchasePlatform>;
  in?: Maybe<Array<InAppPurchasePlatform>>;
  notIn?: Maybe<Array<InAppPurchasePlatform>>;
  not?: Maybe<NestedEnumInAppPurchasePlatformFilter>;
};

export type InAppPurchaseCreateWithoutInAppPurchaseActivationInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  remindBeforeResubscription?: Maybe<Scalars['Int']>;
  planId?: Maybe<InAppPurchaseCreateplanIdInput>;
  access: AccessCreateNestedOneWithoutInAppPurchaseInput;
};

export type InAppPurchaseCreateOrConnectWithoutInAppPurchaseActivationInput = {
  where: InAppPurchaseWhereUniqueInput;
  create: InAppPurchaseCreateWithoutInAppPurchaseActivationInput;
};

export type ActivationCreateWithoutInAppPurchaseActivationInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  access?: Maybe<AccessCreateNestedOneWithoutActivationsInput>;
  code?: Maybe<CodeCreateNestedOneWithoutActivationInput>;
  profile?: Maybe<ProfileCreateNestedOneWithoutActivationsInput>;
  promo?: Maybe<PromoCreateNestedOneWithoutActivationsInput>;
  titles?: Maybe<TitleCreateNestedManyWithoutActivationsInput>;
};

export type ActivationCreateOrConnectWithoutInAppPurchaseActivationInput = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateWithoutInAppPurchaseActivationInput;
};

export type InAppPurchaseUpsertWithoutInAppPurchaseActivationInput = {
  update: InAppPurchaseUpdateWithoutInAppPurchaseActivationInput;
  create: InAppPurchaseCreateWithoutInAppPurchaseActivationInput;
};

export type InAppPurchaseUpdateWithoutInAppPurchaseActivationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  remindBeforeResubscription?: Maybe<NullableIntFieldUpdateOperationsInput>;
  planId?: Maybe<InAppPurchaseUpdateplanIdInput>;
  access?: Maybe<AccessUpdateOneRequiredWithoutInAppPurchaseInput>;
};

export type ActivationUpsertWithoutInAppPurchaseActivationInput = {
  update: ActivationUpdateWithoutInAppPurchaseActivationInput;
  create: ActivationCreateWithoutInAppPurchaseActivationInput;
};

export type ActivationUpdateWithoutInAppPurchaseActivationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  access?: Maybe<AccessUpdateOneWithoutActivationsInput>;
  code?: Maybe<CodeUpdateOneWithoutActivationInput>;
  profile?: Maybe<ProfileUpdateOneWithoutActivationsInput>;
  promo?: Maybe<PromoUpdateOneWithoutActivationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutActivationsInput>;
};

export type TitleUpdateWithoutCollectionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type SoundCreateManyFileInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
};

export type TrackCreateManyFileInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  titleId: Scalars['String'];
  positives?: Maybe<TrackCreateManypositivesInput>;
};

export type SoundUpdateWithoutFileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutDefaultSoundInput>;
};

export type TrackUpdateWithoutFileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  soundMuted?: Maybe<BoolFieldUpdateOperationsInput>;
  silenceStart?: Maybe<DecimalFieldUpdateOperationsInput>;
  silenceEnd?: Maybe<DecimalFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  positives?: Maybe<TrackUpdatepositivesInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutTracksInput>;
  favoriteBy?: Maybe<FavoriteUpdateManyWithoutTrackInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutTrackInput>;
};

export type TitleCreateManyDefaultSoundInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  adultsOnly?: Maybe<Scalars['Boolean']>;
  header?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Decimal']>;
  purchaseUrl?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  showWinner?: Maybe<Scalars['Boolean']>;
  paywallId?: Maybe<Scalars['String']>;
};

export type TitleUpdateWithoutDefaultSoundInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type OpeningCreateManyTitleInput = {
  id?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type PurchaseCreateManyTitleInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type TrackCreateManyTitleInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  soundMuted?: Maybe<Scalars['Boolean']>;
  silenceStart?: Maybe<Scalars['Decimal']>;
  silenceEnd?: Maybe<Scalars['Decimal']>;
  position?: Maybe<Scalars['Decimal']>;
  fileId: Scalars['String'];
  positives?: Maybe<TrackCreateManypositivesInput>;
};

export type InAppPurchaseActivationCreateNestedOneWithoutActivationInput = {
  create?: Maybe<InAppPurchaseActivationCreateWithoutActivationInput>;
  connectOrCreate?: Maybe<InAppPurchaseActivationCreateOrConnectWithoutActivationInput>;
  connect?: Maybe<InAppPurchaseActivationWhereUniqueInput>;
};

export type AccessCreateNestedOneWithoutActivationsInput = {
  create?: Maybe<AccessCreateWithoutActivationsInput>;
  connectOrCreate?: Maybe<AccessCreateOrConnectWithoutActivationsInput>;
  connect?: Maybe<AccessWhereUniqueInput>;
};

export type CodeCreateNestedOneWithoutActivationInput = {
  create?: Maybe<CodeCreateWithoutActivationInput>;
  connectOrCreate?: Maybe<CodeCreateOrConnectWithoutActivationInput>;
  connect?: Maybe<CodeWhereUniqueInput>;
};

export type ProfileCreateNestedOneWithoutActivationsInput = {
  create?: Maybe<ProfileCreateWithoutActivationsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutActivationsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type PromoCreateNestedOneWithoutActivationsInput = {
  create?: Maybe<PromoCreateWithoutActivationsInput>;
  connectOrCreate?: Maybe<PromoCreateOrConnectWithoutActivationsInput>;
  connect?: Maybe<PromoWhereUniqueInput>;
};

export type OpeningUpdateWithoutTitleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutOpeningsInput>;
};

export type PurchaseUpdateWithoutTitleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutPurchasesInput>;
};

export type TrackUpdateWithoutTitleInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  soundMuted?: Maybe<BoolFieldUpdateOperationsInput>;
  silenceStart?: Maybe<DecimalFieldUpdateOperationsInput>;
  silenceEnd?: Maybe<DecimalFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  positives?: Maybe<TrackUpdatepositivesInput>;
  file?: Maybe<FileUpdateOneRequiredWithoutTracksInput>;
  favoriteBy?: Maybe<FavoriteUpdateManyWithoutTrackInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutTrackInput>;
};

export type AccessUpdateWithoutTitlesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type ActivationUpdateWithoutTitlesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateOneWithoutActivationInput>;
  access?: Maybe<AccessUpdateOneWithoutActivationsInput>;
  code?: Maybe<CodeUpdateOneWithoutActivationInput>;
  profile?: Maybe<ProfileUpdateOneWithoutActivationsInput>;
  promo?: Maybe<PromoUpdateOneWithoutActivationsInput>;
};

export type CollectionUpdateWithoutTitlesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
};

export type PromoUpdateWithoutTitlesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  template?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  count?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  access?: Maybe<AccessUpdateOneWithoutPromoInput>;
  activations?: Maybe<ActivationUpdateManyWithoutPromoInput>;
  codes?: Maybe<CodeUpdateManyWithoutPromoInput>;
};

export type FavoriteCreateManyTrackInput = {
  id?: Maybe<Scalars['String']>;
  addedDate: Scalars['DateTime'];
  profileId: Scalars['String'];
};

export type ListeningCreateManyTrackInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  profileId: Scalars['String'];
};

export type FavoriteUpdateWithoutTrackInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  addedDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneRequiredWithoutFavoritesInput>;
};

export type ListeningUpdateWithoutTrackInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneRequiredWithoutListeningsInput>;
};

export type TitleCreateNestedManyWithoutActivationsInput = {
  create?: Maybe<Array<TitleCreateWithoutActivationsInput>>;
  connectOrCreate?: Maybe<Array<TitleCreateOrConnectWithoutActivationsInput>>;
  connect?: Maybe<Array<TitleWhereUniqueInput>>;
};

export type ActivationCreateManyPromoInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  codeId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  accessId?: Maybe<Scalars['String']>;
};

export type CodeCreateManyPromoInput = {
  id?: Maybe<Scalars['String']>;
  used?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
};

export type ActivationUpdateWithoutPromoInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateOneWithoutActivationInput>;
  access?: Maybe<AccessUpdateOneWithoutActivationsInput>;
  code?: Maybe<CodeUpdateOneWithoutActivationInput>;
  profile?: Maybe<ProfileUpdateOneWithoutActivationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutActivationsInput>;
};

export type CodeUpdateWithoutPromoInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  used?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  activation?: Maybe<ActivationUpdateOneWithoutCodeInput>;
};

export type TitleUpdateWithoutPromosInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
};

export type TitleUpdateWithoutActivationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  accesses?: Maybe<AccessUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type EnumVerificationTypeFilter = {
  equals?: Maybe<VerificationType>;
  in?: Maybe<Array<VerificationType>>;
  notIn?: Maybe<Array<VerificationType>>;
  not?: Maybe<NestedEnumVerificationTypeFilter>;
};

export type ActivationCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  codeId?: Maybe<Scalars['String']>;
  promoId?: Maybe<Scalars['String']>;
  accessId?: Maybe<Scalars['String']>;
};

export type FavoriteCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  addedDate: Scalars['DateTime'];
  trackId: Scalars['String'];
};

export type ListeningCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  trackId: Scalars['String'];
};

export type OpeningCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  lastPlayed?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  titleId: Scalars['String'];
};

export type PurchaseCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  titleId: Scalars['String'];
};

export type UserCreateNestedOneWithoutSessionInput = {
  create?: Maybe<UserCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSessionInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type SessionCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export enum VerificationType {
  Code = 'CODE'
}

export type VerificationCreateManyProfileInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  type: VerificationType;
  value: Scalars['String'];
};

export type ActivationUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateOneWithoutActivationInput>;
  access?: Maybe<AccessUpdateOneWithoutActivationsInput>;
  code?: Maybe<CodeUpdateOneWithoutActivationInput>;
  promo?: Maybe<PromoUpdateOneWithoutActivationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutActivationsInput>;
};

export type FavoriteUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  addedDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  track?: Maybe<TrackUpdateOneRequiredWithoutFavoriteByInput>;
};

export type ListeningUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
  track?: Maybe<TrackUpdateOneRequiredWithoutListeningsInput>;
};

export type OpeningUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutOpeningsInput>;
};

export type PurchaseUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<TitleUpdateOneRequiredWithoutPurchasedByInput>;
};

export type SessionUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  User?: Maybe<UserUpdateOneWithoutSessionInput>;
};

export type SessionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
};

export type VerificationUpdateWithoutProfileInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVerificationTypeFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type VerificationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumVerificationTypeFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ProfileCreateNestedOneWithoutSessionsInput = {
  create?: Maybe<ProfileCreateWithoutSessionsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutSessionsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
};

export type SessionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  token: Scalars['String'];
  profileId: Scalars['String'];
};

export type SessionUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneRequiredWithoutSessionsInput>;
};

export type QuestionCreateManyParentInput = {
  id?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  position?: Maybe<Scalars['Decimal']>;
  screen?: Maybe<QuestionScreen>;
};

export type QuestionUpdateWithoutParentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  body?: Maybe<StringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  screen?: Maybe<EnumQuestionScreenFieldUpdateOperationsInput>;
  questions?: Maybe<QuestionUpdateManyWithoutParentInput>;
};

export type ActivationCreateManyAccessInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  useAllTitles?: Maybe<Scalars['Boolean']>;
  activatedAt: Scalars['DateTime'];
  showInMyCollection?: Maybe<Scalars['Boolean']>;
  codeId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  promoId?: Maybe<Scalars['String']>;
};

export type ActivationUpdateWithoutAccessInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiration?: Maybe<DateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  activatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  inAppPurchaseActivation?: Maybe<InAppPurchaseActivationUpdateOneWithoutActivationInput>;
  code?: Maybe<CodeUpdateOneWithoutActivationInput>;
  profile?: Maybe<ProfileUpdateOneWithoutActivationsInput>;
  promo?: Maybe<PromoUpdateOneWithoutActivationsInput>;
  titles?: Maybe<TitleUpdateManyWithoutActivationsInput>;
};

export type TitleUpdateWithoutAccessesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  adultsOnly?: Maybe<BoolFieldUpdateOperationsInput>;
  header?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  isFree?: Maybe<BoolFieldUpdateOperationsInput>;
  subtitle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  position?: Maybe<NullableDecimalFieldUpdateOperationsInput>;
  purchaseUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sku?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastPlayed?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  showWinner?: Maybe<BoolFieldUpdateOperationsInput>;
  paywallId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  defaultSound?: Maybe<SoundUpdateOneWithoutTitlesInput>;
  openings?: Maybe<OpeningUpdateManyWithoutTitleInput>;
  purchasedBy?: Maybe<PurchaseUpdateManyWithoutTitleInput>;
  tracks?: Maybe<TrackUpdateManyWithoutTitleInput>;
  activations?: Maybe<ActivationUpdateManyWithoutTitlesInput>;
  collections?: Maybe<CollectionUpdateManyWithoutTitlesInput>;
  promos?: Maybe<PromoUpdateManyWithoutTitlesInput>;
};

export type DiscountUpdateWithoutRequiredAccessesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  paywallId?: Maybe<StringFieldUpdateOperationsInput>;
  days?: Maybe<IntFieldUpdateOperationsInput>;
  relativePosition?: Maybe<EnumRelativePositionEnumFieldUpdateOperationsInput>;
  position?: Maybe<IntFieldUpdateOperationsInput>;
  discountProposals?: Maybe<DiscountProposalUpdateManyWithoutDiscountInput>;
  disabledAccesses?: Maybe<AccessUpdateManyWithoutDisablingDiscountsInput>;
};

export type DiscountUpdateWithoutDisabledAccessesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  paywallId?: Maybe<StringFieldUpdateOperationsInput>;
  days?: Maybe<IntFieldUpdateOperationsInput>;
  relativePosition?: Maybe<EnumRelativePositionEnumFieldUpdateOperationsInput>;
  position?: Maybe<IntFieldUpdateOperationsInput>;
  requiredAccesses?: Maybe<AccessUpdateManyWithoutRequiringDiscountsInput>;
  discountProposals?: Maybe<DiscountProposalUpdateManyWithoutDiscountInput>;
};

export type InAppPurchaseActivationCreateManyInAppPurchaseInput = {
  id?: Maybe<Scalars['String']>;
  willRenew: Scalars['Boolean'];
  shouldCancel?: Maybe<Scalars['Boolean']>;
  purchaseId: Scalars['String'];
  platform?: Maybe<InAppPurchasePlatform>;
  activationId: Scalars['String'];
};

export type InAppPurchaseActivationUpdateWithoutInAppPurchaseInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  willRenew?: Maybe<BoolFieldUpdateOperationsInput>;
  shouldCancel?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  purchaseId?: Maybe<StringFieldUpdateOperationsInput>;
  platform?: Maybe<EnumInAppPurchasePlatformFieldUpdateOperationsInput>;
  activation?: Maybe<ActivationUpdateOneRequiredWithoutInAppPurchaseActivationInput>;
};

export type DiscountProposalCreateManyDiscountInput = {
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type AccessUpdateWithoutRequiringDiscountsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  disablingDiscounts?: Maybe<DiscountUpdateManyWithoutDisabledAccessesInput>;
};

export type DiscountProposalUpdateWithoutDiscountInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  profile?: Maybe<ProfileUpdateOneWithoutDiscountProposalInput>;
};

export type AccessUpdateWithoutDisablingDiscountsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  expiration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  expirationDate?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useBy?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  useAllTitles?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  showInMyCollection?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  remindAfterSubscription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutAccessInput>;
  inAppPurchase?: Maybe<InAppPurchaseUpdateOneWithoutAccessInput>;
  promo?: Maybe<PromoUpdateOneWithoutAccessInput>;
  subscription?: Maybe<MagentaSubscriptionUpdateOneWithoutAccessInput>;
  titles?: Maybe<TitleUpdateManyWithoutAccessesInput>;
  requiringDiscounts?: Maybe<DiscountUpdateManyWithoutRequiredAccessesInput>;
};

export type TrackCreateManypositivesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type NestedEnumVerificationTypeFilter = {
  equals?: Maybe<VerificationType>;
  in?: Maybe<Array<VerificationType>>;
  notIn?: Maybe<Array<VerificationType>>;
  not?: Maybe<NestedEnumVerificationTypeFilter>;
};

export type UserCreateWithoutSessionInput = {
  id?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  password: Scalars['String'];
};

export type UserCreateOrConnectWithoutSessionInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSessionInput;
};

export type UserUpdateOneWithoutSessionInput = {
  create?: Maybe<UserCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSessionInput>;
  upsert?: Maybe<UserUpsertWithoutSessionInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutSessionInput>;
};

export type EnumVerificationTypeFieldUpdateOperationsInput = {
  set?: Maybe<VerificationType>;
};

export type ProfileCreateWithoutSessionsInput = {
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  activations?: Maybe<ActivationCreateNestedManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteCreateNestedManyWithoutProfileInput>;
  listenings?: Maybe<ListeningCreateNestedManyWithoutProfileInput>;
  openings?: Maybe<OpeningCreateNestedManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseCreateNestedManyWithoutProfileInput>;
  verifications?: Maybe<VerificationCreateNestedManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalCreateNestedOneWithoutProfileInput>;
};

export type ProfileCreateOrConnectWithoutSessionsInput = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateWithoutSessionsInput;
};

export type ProfileUpdateOneRequiredWithoutSessionsInput = {
  create?: Maybe<ProfileCreateWithoutSessionsInput>;
  connectOrCreate?: Maybe<ProfileCreateOrConnectWithoutSessionsInput>;
  upsert?: Maybe<ProfileUpsertWithoutSessionsInput>;
  connect?: Maybe<ProfileWhereUniqueInput>;
  update?: Maybe<ProfileUpdateWithoutSessionsInput>;
};

export type UserUpsertWithoutSessionInput = {
  update: UserUpdateWithoutSessionInput;
  create: UserCreateWithoutSessionInput;
};

export type UserUpdateWithoutSessionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  username?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ProfileUpsertWithoutSessionsInput = {
  update: ProfileUpdateWithoutSessionsInput;
  create: ProfileCreateWithoutSessionsInput;
};

export type ProfileUpdateWithoutSessionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  uid?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  lastName?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  anonymousId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  activations?: Maybe<ActivationUpdateManyWithoutProfileInput>;
  favorites?: Maybe<FavoriteUpdateManyWithoutProfileInput>;
  listenings?: Maybe<ListeningUpdateManyWithoutProfileInput>;
  openings?: Maybe<OpeningUpdateManyWithoutProfileInput>;
  purchases?: Maybe<PurchaseUpdateManyWithoutProfileInput>;
  verifications?: Maybe<VerificationUpdateManyWithoutProfileInput>;
  discountProposal?: Maybe<DiscountProposalUpdateOneWithoutProfileInput>;
};

export type Query = {
  __typename?: 'Query';
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  adminCollection?: Maybe<Collection>;
  adminCollections: Array<Collection>;
  adminCollectionsCount?: Maybe<Scalars['Int']>;
  adminFile?: Maybe<File>;
  adminFiles: Array<File>;
  adminFilesCount?: Maybe<Scalars['Int']>;
  sounds: Array<Sound>;
  adminSound?: Maybe<Sound>;
  adminSounds: Array<Sound>;
  adminSoundsCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Title>;
  titles: Array<Title>;
  myTitles?: Maybe<Array<Maybe<Title>>>;
  adminTitle?: Maybe<Title>;
  adminTitles: Array<Title>;
  adminTitlesCount?: Maybe<Scalars['Int']>;
  track?: Maybe<Track>;
  tracks: Array<Track>;
  adminTrack?: Maybe<Track>;
  adminTracks: Array<Track>;
  adminTracksCount?: Maybe<Scalars['Int']>;
  adminFeedback?: Maybe<Feedback>;
  adminFeedbacks: Array<Feedback>;
  adminFeedbacksCount?: Maybe<Scalars['Int']>;
  adminPromo?: Maybe<Promo>;
  adminPromos: Array<Promo>;
  adminPromosCount?: Maybe<Scalars['Int']>;
  adminCode?: Maybe<Code>;
  adminCodes: Array<Code>;
  adminCodesCount?: Maybe<Scalars['Int']>;
  adminOpening?: Maybe<Opening>;
  adminOpenings: Array<Opening>;
  adminOpeningsCount?: Maybe<Scalars['Int']>;
  myFavorites?: Maybe<Array<Maybe<Favorite>>>;
  adminFavorite?: Maybe<Favorite>;
  adminFavorites: Array<Favorite>;
  adminFavoritesCount?: Maybe<Scalars['Int']>;
  adminPurchase?: Maybe<Purchase>;
  adminPurchases: Array<Purchase>;
  adminPurchasesCount?: Maybe<Scalars['Int']>;
  adminActivation?: Maybe<Activation>;
  adminActivations: Array<Activation>;
  adminActivationsCount?: Maybe<Scalars['Int']>;
  activations: Array<Activation>;
  profile?: Maybe<Profile>;
  adminProfile?: Maybe<Profile>;
  adminProfiles: Array<Profile>;
  adminProfilesCount?: Maybe<Scalars['Int']>;
  adminListening?: Maybe<Listening>;
  adminListenings: Array<Listening>;
  adminListeningsCount?: Maybe<Scalars['Int']>;
  adminUser?: Maybe<User>;
  adminUsers: Array<User>;
  adminUsersCount?: Maybe<Scalars['Int']>;
  parameters: Array<Parameter>;
  adminParameter?: Maybe<Parameter>;
  adminParameters: Array<Parameter>;
  adminParametersCount?: Maybe<Scalars['Int']>;
  questions: Array<Question>;
  adminQuestion?: Maybe<Question>;
  adminQuestions: Array<Question>;
  adminQuestionsCount?: Maybe<Scalars['Int']>;
  adminAccess?: Maybe<Access>;
  adminAccesses: Array<Access>;
  adminAccessesCount?: Maybe<Scalars['Int']>;
  adminInAppPurchase?: Maybe<InAppPurchase>;
  adminInAppPurchases: Array<InAppPurchase>;
  adminInAppPurchasesCount?: Maybe<Scalars['Int']>;
  adminMagentaSubscription?: Maybe<MagentaSubscription>;
  adminMagentaSubscriptions: Array<MagentaSubscription>;
  adminMagentaSubscriptionsCount?: Maybe<Scalars['Int']>;
  adminDiscount?: Maybe<Discount>;
  adminDiscounts: Array<Discount>;
  adminDiscountsCount?: Maybe<Scalars['Int']>;
  randomDiscount?: Maybe<Discount>;
  adminDiscountProposal?: Maybe<DiscountProposal>;
  adminDiscountProposals: Array<DiscountProposal>;
  adminDiscountProposalsCount?: Maybe<Scalars['Int']>;
  adminInAppPurchaseActivation?: Maybe<InAppPurchaseActivation>;
  adminInAppPurchaseActivations: Array<InAppPurchaseActivation>;
  adminInAppPurchaseActivationsCount?: Maybe<Scalars['Int']>;
};


export type QueryCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type QueryCollectionsArgs = {
  where?: Maybe<CollectionWhereInput>;
  orderBy?: Maybe<Array<CollectionOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CollectionWhereUniqueInput>;
};


export type QueryAdminCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type QueryAdminCollectionsArgs = {
  where?: Maybe<CollectionWhereInput>;
  orderBy?: Maybe<Array<CollectionOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CollectionWhereUniqueInput>;
};


export type QueryAdminCollectionsCountArgs = {
  where?: Maybe<CollectionWhereInput>;
  orderBy?: Maybe<CollectionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CollectionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminFileArgs = {
  where: FileWhereUniqueInput;
};


export type QueryAdminFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<Array<FileOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FileWhereUniqueInput>;
};


export type QueryAdminFilesCountArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<FileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FileWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QuerySoundsArgs = {
  where?: Maybe<SoundWhereInput>;
  orderBy?: Maybe<Array<SoundOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SoundWhereUniqueInput>;
};


export type QueryAdminSoundArgs = {
  where: SoundWhereUniqueInput;
};


export type QueryAdminSoundsArgs = {
  where?: Maybe<SoundWhereInput>;
  orderBy?: Maybe<Array<SoundOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SoundWhereUniqueInput>;
};


export type QueryAdminSoundsCountArgs = {
  where?: Maybe<SoundWhereInput>;
  orderBy?: Maybe<SoundOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SoundWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryTitleArgs = {
  where: TitleWhereUniqueInput;
};


export type QueryTitlesArgs = {
  where?: Maybe<TitleWhereInput>;
  orderBy?: Maybe<Array<TitleOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TitleWhereUniqueInput>;
};


export type QueryMyTitlesArgs = {
  adultsOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryAdminTitleArgs = {
  where: TitleWhereUniqueInput;
};


export type QueryAdminTitlesArgs = {
  where?: Maybe<TitleWhereInput>;
  orderBy?: Maybe<Array<TitleOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TitleWhereUniqueInput>;
};


export type QueryAdminTitlesCountArgs = {
  where?: Maybe<TitleWhereInput>;
  orderBy?: Maybe<TitleOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TitleWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type QueryTracksArgs = {
  where?: Maybe<TrackWhereInput>;
  orderBy?: Maybe<Array<TrackOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TrackWhereUniqueInput>;
};


export type QueryAdminTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type QueryAdminTracksArgs = {
  where?: Maybe<TrackWhereInput>;
  orderBy?: Maybe<Array<TrackOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TrackWhereUniqueInput>;
};


export type QueryAdminTracksCountArgs = {
  where?: Maybe<TrackWhereInput>;
  orderBy?: Maybe<TrackOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TrackWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminFeedbackArgs = {
  where: FeedbackWhereUniqueInput;
};


export type QueryAdminFeedbacksArgs = {
  where?: Maybe<FeedbackWhereInput>;
  orderBy?: Maybe<Array<FeedbackOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FeedbackWhereUniqueInput>;
};


export type QueryAdminFeedbacksCountArgs = {
  where?: Maybe<FeedbackWhereInput>;
  orderBy?: Maybe<FeedbackOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FeedbackWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminPromoArgs = {
  where: PromoWhereUniqueInput;
};


export type QueryAdminPromosArgs = {
  where?: Maybe<PromoWhereInput>;
  orderBy?: Maybe<Array<PromoOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PromoWhereUniqueInput>;
};


export type QueryAdminPromosCountArgs = {
  where?: Maybe<PromoWhereInput>;
  orderBy?: Maybe<PromoOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PromoWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminCodeArgs = {
  where: CodeWhereUniqueInput;
};


export type QueryAdminCodesArgs = {
  where?: Maybe<CodeWhereInput>;
  orderBy?: Maybe<Array<CodeOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CodeWhereUniqueInput>;
};


export type QueryAdminCodesCountArgs = {
  where?: Maybe<CodeWhereInput>;
  orderBy?: Maybe<CodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CodeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminOpeningArgs = {
  where: OpeningWhereUniqueInput;
};


export type QueryAdminOpeningsArgs = {
  where?: Maybe<OpeningWhereInput>;
  orderBy?: Maybe<Array<OpeningOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OpeningWhereUniqueInput>;
};


export type QueryAdminOpeningsCountArgs = {
  where?: Maybe<OpeningWhereInput>;
  orderBy?: Maybe<OpeningOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OpeningWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryMyFavoritesArgs = {
  adultsOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryAdminFavoriteArgs = {
  where: FavoriteWhereUniqueInput;
};


export type QueryAdminFavoritesArgs = {
  where?: Maybe<FavoriteWhereInput>;
  orderBy?: Maybe<Array<FavoriteOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FavoriteWhereUniqueInput>;
};


export type QueryAdminFavoritesCountArgs = {
  where?: Maybe<FavoriteWhereInput>;
  orderBy?: Maybe<FavoriteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<FavoriteWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminPurchaseArgs = {
  where: PurchaseWhereUniqueInput;
};


export type QueryAdminPurchasesArgs = {
  where?: Maybe<PurchaseWhereInput>;
  orderBy?: Maybe<Array<PurchaseOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PurchaseWhereUniqueInput>;
};


export type QueryAdminPurchasesCountArgs = {
  where?: Maybe<PurchaseWhereInput>;
  orderBy?: Maybe<PurchaseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PurchaseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminActivationArgs = {
  where: ActivationWhereUniqueInput;
};


export type QueryAdminActivationsArgs = {
  where?: Maybe<ActivationWhereInput>;
  orderBy?: Maybe<Array<ActivationOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ActivationWhereUniqueInput>;
};


export type QueryAdminActivationsCountArgs = {
  where?: Maybe<ActivationWhereInput>;
  orderBy?: Maybe<ActivationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ActivationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryActivationsArgs = {
  where?: Maybe<ActivationWhereInput>;
  orderBy?: Maybe<Array<ActivationOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ActivationWhereUniqueInput>;
};


export type QueryAdminProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type QueryAdminProfilesArgs = {
  where?: Maybe<ProfileWhereInput>;
  orderBy?: Maybe<Array<ProfileOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ProfileWhereUniqueInput>;
};


export type QueryAdminProfilesCountArgs = {
  where?: Maybe<ProfileWhereInput>;
  orderBy?: Maybe<ProfileOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ProfileWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminListeningArgs = {
  where: ListeningWhereUniqueInput;
};


export type QueryAdminListeningsArgs = {
  where?: Maybe<ListeningWhereInput>;
  orderBy?: Maybe<Array<ListeningOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ListeningWhereUniqueInput>;
};


export type QueryAdminListeningsCountArgs = {
  where?: Maybe<ListeningWhereInput>;
  orderBy?: Maybe<ListeningOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ListeningWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryAdminUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
};


export type QueryAdminUsersCountArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryParametersArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ParameterWhereUniqueInput>;
};


export type QueryAdminParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type QueryAdminParametersArgs = {
  where?: Maybe<ParameterWhereInput>;
  orderBy?: Maybe<Array<ParameterOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ParameterWhereUniqueInput>;
};


export type QueryAdminParametersCountArgs = {
  where?: Maybe<ParameterWhereInput>;
  orderBy?: Maybe<ParameterOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<ParameterWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryQuestionsArgs = {
  where?: Maybe<QuestionWhereInput>;
  orderBy?: Maybe<Array<QuestionOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<QuestionWhereUniqueInput>;
};


export type QueryAdminQuestionArgs = {
  where: QuestionWhereUniqueInput;
};


export type QueryAdminQuestionsArgs = {
  where?: Maybe<QuestionWhereInput>;
  orderBy?: Maybe<Array<QuestionOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<QuestionWhereUniqueInput>;
};


export type QueryAdminQuestionsCountArgs = {
  where?: Maybe<QuestionWhereInput>;
  orderBy?: Maybe<QuestionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<QuestionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminAccessArgs = {
  where: AccessWhereUniqueInput;
};


export type QueryAdminAccessesArgs = {
  where?: Maybe<AccessWhereInput>;
  orderBy?: Maybe<Array<AccessOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccessWhereUniqueInput>;
};


export type QueryAdminAccessesCountArgs = {
  where?: Maybe<AccessWhereInput>;
  orderBy?: Maybe<AccessOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccessWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminInAppPurchaseArgs = {
  where: InAppPurchaseWhereUniqueInput;
};


export type QueryAdminInAppPurchasesArgs = {
  where?: Maybe<InAppPurchaseWhereInput>;
  orderBy?: Maybe<Array<InAppPurchaseOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<InAppPurchaseWhereUniqueInput>;
};


export type QueryAdminInAppPurchasesCountArgs = {
  where?: Maybe<InAppPurchaseWhereInput>;
  orderBy?: Maybe<InAppPurchaseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<InAppPurchaseWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminMagentaSubscriptionArgs = {
  where: MagentaSubscriptionWhereUniqueInput;
};


export type QueryAdminMagentaSubscriptionsArgs = {
  where?: Maybe<MagentaSubscriptionWhereInput>;
  orderBy?: Maybe<Array<MagentaSubscriptionOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<MagentaSubscriptionWhereUniqueInput>;
};


export type QueryAdminMagentaSubscriptionsCountArgs = {
  where?: Maybe<MagentaSubscriptionWhereInput>;
  orderBy?: Maybe<MagentaSubscriptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<MagentaSubscriptionWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminDiscountArgs = {
  where: DiscountWhereUniqueInput;
};


export type QueryAdminDiscountsArgs = {
  where?: Maybe<DiscountWhereInput>;
  orderBy?: Maybe<Array<DiscountOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<DiscountWhereUniqueInput>;
};


export type QueryAdminDiscountsCountArgs = {
  where?: Maybe<DiscountWhereInput>;
  orderBy?: Maybe<DiscountOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<DiscountWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminDiscountProposalArgs = {
  where: DiscountProposalWhereUniqueInput;
};


export type QueryAdminDiscountProposalsArgs = {
  where?: Maybe<DiscountProposalWhereInput>;
  orderBy?: Maybe<Array<DiscountProposalOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<DiscountProposalWhereUniqueInput>;
};


export type QueryAdminDiscountProposalsCountArgs = {
  where?: Maybe<DiscountProposalWhereInput>;
  orderBy?: Maybe<DiscountProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<DiscountProposalWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryAdminInAppPurchaseActivationArgs = {
  where: InAppPurchaseActivationWhereUniqueInput;
};


export type QueryAdminInAppPurchaseActivationsArgs = {
  where?: Maybe<InAppPurchaseActivationWhereInput>;
  orderBy?: Maybe<Array<InAppPurchaseActivationOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<InAppPurchaseActivationWhereUniqueInput>;
};


export type QueryAdminInAppPurchaseActivationsCountArgs = {
  where?: Maybe<InAppPurchaseActivationWhereInput>;
  orderBy?: Maybe<InAppPurchaseActivationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<InAppPurchaseActivationWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreateOneCollection: Collection;
  adminUpdateOneCollection?: Maybe<Collection>;
  adminUpdateManyCollection: AffectedRowsOutput;
  adminUpsertOneCollection: Collection;
  adminDeleteOneCollection?: Maybe<Collection>;
  adminDeleteManyCollection: AffectedRowsOutput;
  upload?: Maybe<FilePayload>;
  migrateFiles?: Maybe<Scalars['String']>;
  adminCreateOneFile: File;
  adminUpdateOneFile?: Maybe<File>;
  adminUpdateManyFile: AffectedRowsOutput;
  adminUpsertOneFile: File;
  adminDeleteOneFile?: Maybe<File>;
  adminDeleteManyFile: AffectedRowsOutput;
  adminCreateOneSound: Sound;
  adminUpdateOneSound?: Maybe<Sound>;
  adminUpdateManySound: AffectedRowsOutput;
  adminUpsertOneSound: Sound;
  adminDeleteOneSound?: Maybe<Sound>;
  adminDeleteManySound?: Maybe<AffectedRowsOutput>;
  adminCreateOneTitle: Title;
  adminUpdateOneTitle?: Maybe<Title>;
  adminUpdateManyTitle: AffectedRowsOutput;
  adminUpsertOneTitle: Title;
  adminDeleteOneTitle?: Maybe<Title>;
  adminDeleteManyTitle?: Maybe<AffectedRowsOutput>;
  likeTrack?: Maybe<Track>;
  dislikeTrack?: Maybe<Track>;
  adminCreateOneTrack: Track;
  adminUpdateOneTrack?: Maybe<Track>;
  adminUpdateManyTrack: AffectedRowsOutput;
  adminUpsertOneTrack: Track;
  adminDeleteOneTrack?: Maybe<Track>;
  adminDeleteManyTrack?: Maybe<AffectedRowsOutput>;
  sendFeedback?: Maybe<Feedback>;
  adminCreateOneFeedback: Feedback;
  adminUpdateOneFeedback?: Maybe<Feedback>;
  adminUpdateManyFeedback: AffectedRowsOutput;
  adminUpsertOneFeedback: Feedback;
  adminDeleteOneFeedback?: Maybe<Feedback>;
  adminDeleteManyFeedback: AffectedRowsOutput;
  adminCreateOnePromo?: Maybe<Promo>;
  adminUpdateOnePromo?: Maybe<Promo>;
  adminUpdateManyPromo: AffectedRowsOutput;
  adminUpsertOnePromo: Promo;
  adminDeleteOnePromo?: Maybe<Promo>;
  adminDeleteManyPromo: AffectedRowsOutput;
  migratePromos?: Maybe<Scalars['String']>;
  adminCreateOneCode: Code;
  adminUpdateOneCode?: Maybe<Code>;
  adminUpdateManyCode: AffectedRowsOutput;
  adminUpsertOneCode: Code;
  adminDeleteOneCode?: Maybe<Code>;
  adminDeleteManyCode: AffectedRowsOutput;
  updateLastOpened?: Maybe<Opening>;
  adminCreateOneOpening: Opening;
  adminUpdateOneOpening?: Maybe<Opening>;
  adminUpdateManyOpening: AffectedRowsOutput;
  adminUpsertOneOpening: Opening;
  adminDeleteOneOpening?: Maybe<Opening>;
  adminDeleteManyOpening: AffectedRowsOutput;
  adminCreateOneFavorite: Favorite;
  adminUpdateOneFavorite?: Maybe<Favorite>;
  adminUpdateManyFavorite: AffectedRowsOutput;
  adminUpsertOneFavorite: Favorite;
  adminDeleteOneFavorite?: Maybe<Favorite>;
  adminDeleteManyFavorite: AffectedRowsOutput;
  adminCreateOnePurchase: Purchase;
  adminUpdateOnePurchase?: Maybe<Purchase>;
  adminUpdateManyPurchase: AffectedRowsOutput;
  adminUpsertOnePurchase: Purchase;
  adminDeleteOnePurchase?: Maybe<Purchase>;
  adminDeleteManyPurchase: AffectedRowsOutput;
  purchaseTitle: Array<PurchaseOrActivation>;
  migratePurchases?: Maybe<Scalars['String']>;
  adminCreateOneActivation?: Maybe<Activation>;
  adminUpdateOneActivation?: Maybe<Activation>;
  adminUpdateManyActivation: AffectedRowsOutput;
  adminUpsertOneActivation: Activation;
  adminDeleteOneActivation?: Maybe<Activation>;
  adminDeleteManyActivation: AffectedRowsOutput;
  applyPromocode?: Maybe<Activation>;
  applySubscription?: Maybe<Activation>;
  deleteProfile?: Maybe<Profile>;
  disassociateAnonymousProfile?: Maybe<Profile>;
  mergeAnonymousProfile?: Maybe<Profile>;
  updateProfile?: Maybe<Profile>;
  adminCreateOneProfile: Profile;
  adminUpdateOneProfile?: Maybe<Profile>;
  adminUpdateManyProfile: AffectedRowsOutput;
  adminUpsertOneProfile: Profile;
  adminDeleteOneProfile?: Maybe<Profile>;
  adminDeleteManyProfile: AffectedRowsOutput;
  updateListenCount?: Maybe<Listening>;
  adminCreateOneListening: Listening;
  adminUpdateOneListening?: Maybe<Listening>;
  adminUpdateManyListening: AffectedRowsOutput;
  adminUpsertOneListening: Listening;
  adminDeleteOneListening?: Maybe<Listening>;
  adminDeleteManyListening: AffectedRowsOutput;
  createOneUser?: Maybe<User>;
  adminCreateOneUser: User;
  adminUpdateOneUser?: Maybe<User>;
  adminUpdateManyUser: AffectedRowsOutput;
  adminUpsertOneUser: User;
  adminDeleteOneUser?: Maybe<User>;
  adminDeleteManyUser: AffectedRowsOutput;
  login: AuthPayload;
  adminCreateOneParameter: Parameter;
  adminUpdateOneParameter?: Maybe<Parameter>;
  adminUpdateManyParameter: AffectedRowsOutput;
  adminUpsertOneParameter: Parameter;
  adminDeleteOneParameter?: Maybe<Parameter>;
  adminDeleteManyParameter: AffectedRowsOutput;
  adminCreateOneQuestion: Question;
  adminUpdateOneQuestion?: Maybe<Question>;
  adminUpdateManyQuestion: AffectedRowsOutput;
  adminUpsertOneQuestion: Question;
  adminDeleteOneQuestion?: Maybe<Question>;
  adminDeleteManyQuestion: AffectedRowsOutput;
  adminCreateOneAccess: Access;
  adminUpdateOneAccess?: Maybe<Access>;
  adminUpdateManyAccess: AffectedRowsOutput;
  adminUpsertOneAccess: Access;
  adminDeleteOneAccess?: Maybe<Access>;
  adminDeleteManyAccess: AffectedRowsOutput;
  adminCreateOneInAppPurchase: InAppPurchase;
  adminUpdateOneInAppPurchase?: Maybe<InAppPurchase>;
  adminUpdateManyInAppPurchase: AffectedRowsOutput;
  adminUpsertOneInAppPurchase: InAppPurchase;
  adminDeleteOneInAppPurchase?: Maybe<InAppPurchase>;
  adminDeleteManyInAppPurchase: AffectedRowsOutput;
  adminCreateOneMagentaSubscription: MagentaSubscription;
  adminUpdateOneMagentaSubscription?: Maybe<MagentaSubscription>;
  adminUpdateManyMagentaSubscription: AffectedRowsOutput;
  adminUpsertOneMagentaSubscription: MagentaSubscription;
  adminDeleteOneMagentaSubscription?: Maybe<MagentaSubscription>;
  adminDeleteManyMagentaSubscription: AffectedRowsOutput;
  adminCreateOneDiscount: Discount;
  adminUpdateOneDiscount?: Maybe<Discount>;
  adminUpdateManyDiscount: AffectedRowsOutput;
  adminUpsertOneDiscount: Discount;
  adminDeleteOneDiscount?: Maybe<Discount>;
  adminDeleteManyDiscount: AffectedRowsOutput;
  discount?: Maybe<Discount>;
  adminCreateOneDiscountProposal: DiscountProposal;
  adminUpdateOneDiscountProposal?: Maybe<DiscountProposal>;
  adminUpdateManyDiscountProposal: AffectedRowsOutput;
  adminUpsertOneDiscountProposal: DiscountProposal;
  adminDeleteOneDiscountProposal?: Maybe<DiscountProposal>;
  adminDeleteManyDiscountProposal: AffectedRowsOutput;
  adminCreateOneInAppPurchaseActivation: InAppPurchaseActivation;
  adminUpdateOneInAppPurchaseActivation?: Maybe<InAppPurchaseActivation>;
  adminUpdateManyInAppPurchaseActivation: AffectedRowsOutput;
  adminUpsertOneInAppPurchaseActivation: InAppPurchaseActivation;
  adminDeleteOneInAppPurchaseActivation?: Maybe<InAppPurchaseActivation>;
  adminDeleteManyInAppPurchaseActivation: AffectedRowsOutput;
  token: TokenResult;
  migrateFirebaseToken: ProfilePayload;
  sendVerification?: Maybe<VerificationSent>;
  loginWithVerification: VerificationLoginResult;
  loginWithAnonymousId?: Maybe<ProfilePayload>;
};


export type MutationAdminCreateOneCollectionArgs = {
  data: CollectionCreateInput;
};


export type MutationAdminUpdateOneCollectionArgs = {
  data: CollectionUpdateInput;
  where: CollectionWhereUniqueInput;
};


export type MutationAdminUpdateManyCollectionArgs = {
  data: CollectionUpdateManyMutationInput;
  where?: Maybe<CollectionWhereInput>;
};


export type MutationAdminUpsertOneCollectionArgs = {
  where: CollectionWhereUniqueInput;
  create: CollectionCreateInput;
  update: CollectionUpdateInput;
};


export type MutationAdminDeleteOneCollectionArgs = {
  where: CollectionWhereUniqueInput;
};


export type MutationAdminDeleteManyCollectionArgs = {
  where?: Maybe<CollectionWhereInput>;
};


export type MutationUploadArgs = {
  name: Scalars['String'];
  contentType?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
};


export type MutationAdminCreateOneFileArgs = {
  data: FileCreateInput;
};


export type MutationAdminUpdateOneFileArgs = {
  data: FileUpdateInput;
  where: FileWhereUniqueInput;
};


export type MutationAdminUpdateManyFileArgs = {
  data: FileUpdateManyMutationInput;
  where?: Maybe<FileWhereInput>;
};


export type MutationAdminUpsertOneFileArgs = {
  where: FileWhereUniqueInput;
  create: FileCreateInput;
  update: FileUpdateInput;
};


export type MutationAdminDeleteOneFileArgs = {
  where: FileWhereUniqueInput;
};


export type MutationAdminDeleteManyFileArgs = {
  where?: Maybe<FileWhereInput>;
};


export type MutationAdminCreateOneSoundArgs = {
  data: SoundCreateInput;
};


export type MutationAdminUpdateOneSoundArgs = {
  data: SoundUpdateInput;
  where: SoundWhereUniqueInput;
};


export type MutationAdminUpdateManySoundArgs = {
  data: SoundUpdateManyMutationInput;
  where?: Maybe<SoundWhereInput>;
};


export type MutationAdminUpsertOneSoundArgs = {
  where: SoundWhereUniqueInput;
  create: SoundCreateInput;
  update: SoundUpdateInput;
};


export type MutationAdminDeleteOneSoundArgs = {
  where: SoundWhereUniqueInput;
};


export type MutationAdminDeleteManySoundArgs = {
  where?: Maybe<SoundWhereInput>;
};


export type MutationAdminCreateOneTitleArgs = {
  data: TitleCreateInput;
};


export type MutationAdminUpdateOneTitleArgs = {
  data: TitleUpdateInput;
  where: TitleWhereUniqueInput;
};


export type MutationAdminUpdateManyTitleArgs = {
  data: TitleUpdateManyMutationInput;
  where?: Maybe<TitleWhereInput>;
};


export type MutationAdminUpsertOneTitleArgs = {
  where: TitleWhereUniqueInput;
  create: TitleCreateInput;
  update: TitleUpdateInput;
};


export type MutationAdminDeleteOneTitleArgs = {
  where: TitleWhereUniqueInput;
};


export type MutationAdminDeleteManyTitleArgs = {
  where?: Maybe<TitleWhereInput>;
};


export type MutationLikeTrackArgs = {
  id: Scalars['ID'];
};


export type MutationDislikeTrackArgs = {
  id: Scalars['ID'];
};


export type MutationAdminCreateOneTrackArgs = {
  data: TrackCreateInput;
};


export type MutationAdminUpdateOneTrackArgs = {
  data: TrackUpdateInput;
  where: TrackWhereUniqueInput;
};


export type MutationAdminUpdateManyTrackArgs = {
  data: TrackUpdateManyMutationInput;
  where?: Maybe<TrackWhereInput>;
};


export type MutationAdminUpsertOneTrackArgs = {
  where: TrackWhereUniqueInput;
  create: TrackCreateInput;
  update: TrackUpdateInput;
};


export type MutationAdminDeleteOneTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type MutationAdminDeleteManyTrackArgs = {
  where?: Maybe<TrackWhereInput>;
};


export type MutationSendFeedbackArgs = {
  phone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  message: Scalars['String'];
};


export type MutationAdminCreateOneFeedbackArgs = {
  data: FeedbackCreateInput;
};


export type MutationAdminUpdateOneFeedbackArgs = {
  data: FeedbackUpdateInput;
  where: FeedbackWhereUniqueInput;
};


export type MutationAdminUpdateManyFeedbackArgs = {
  data: FeedbackUpdateManyMutationInput;
  where?: Maybe<FeedbackWhereInput>;
};


export type MutationAdminUpsertOneFeedbackArgs = {
  where: FeedbackWhereUniqueInput;
  create: FeedbackCreateInput;
  update: FeedbackUpdateInput;
};


export type MutationAdminDeleteOneFeedbackArgs = {
  where: FeedbackWhereUniqueInput;
};


export type MutationAdminDeleteManyFeedbackArgs = {
  where?: Maybe<FeedbackWhereInput>;
};


export type MutationAdminCreateOnePromoArgs = {
  data: PromoCreateInput;
};


export type MutationAdminUpdateOnePromoArgs = {
  data: PromoUpdateInput;
  where: PromoWhereUniqueInput;
};


export type MutationAdminUpdateManyPromoArgs = {
  data: PromoUpdateManyMutationInput;
  where?: Maybe<PromoWhereInput>;
};


export type MutationAdminUpsertOnePromoArgs = {
  where: PromoWhereUniqueInput;
  create: PromoCreateInput;
  update: PromoUpdateInput;
};


export type MutationAdminDeleteOnePromoArgs = {
  where: PromoWhereUniqueInput;
};


export type MutationAdminDeleteManyPromoArgs = {
  where?: Maybe<PromoWhereInput>;
};


export type MutationAdminCreateOneCodeArgs = {
  data: CodeCreateInput;
};


export type MutationAdminUpdateOneCodeArgs = {
  data: CodeUpdateInput;
  where: CodeWhereUniqueInput;
};


export type MutationAdminUpdateManyCodeArgs = {
  data: CodeUpdateManyMutationInput;
  where?: Maybe<CodeWhereInput>;
};


export type MutationAdminUpsertOneCodeArgs = {
  where: CodeWhereUniqueInput;
  create: CodeCreateInput;
  update: CodeUpdateInput;
};


export type MutationAdminDeleteOneCodeArgs = {
  where: CodeWhereUniqueInput;
};


export type MutationAdminDeleteManyCodeArgs = {
  where?: Maybe<CodeWhereInput>;
};


export type MutationUpdateLastOpenedArgs = {
  titleId: Scalars['ID'];
  lastPlayed?: Maybe<Scalars['DateTime']>;
};


export type MutationAdminCreateOneOpeningArgs = {
  data: OpeningCreateInput;
};


export type MutationAdminUpdateOneOpeningArgs = {
  data: OpeningUpdateInput;
  where: OpeningWhereUniqueInput;
};


export type MutationAdminUpdateManyOpeningArgs = {
  data: OpeningUpdateManyMutationInput;
  where?: Maybe<OpeningWhereInput>;
};


export type MutationAdminUpsertOneOpeningArgs = {
  where: OpeningWhereUniqueInput;
  create: OpeningCreateInput;
  update: OpeningUpdateInput;
};


export type MutationAdminDeleteOneOpeningArgs = {
  where: OpeningWhereUniqueInput;
};


export type MutationAdminDeleteManyOpeningArgs = {
  where?: Maybe<OpeningWhereInput>;
};


export type MutationAdminCreateOneFavoriteArgs = {
  data: FavoriteCreateInput;
};


export type MutationAdminUpdateOneFavoriteArgs = {
  data: FavoriteUpdateInput;
  where: FavoriteWhereUniqueInput;
};


export type MutationAdminUpdateManyFavoriteArgs = {
  data: FavoriteUpdateManyMutationInput;
  where?: Maybe<FavoriteWhereInput>;
};


export type MutationAdminUpsertOneFavoriteArgs = {
  where: FavoriteWhereUniqueInput;
  create: FavoriteCreateInput;
  update: FavoriteUpdateInput;
};


export type MutationAdminDeleteOneFavoriteArgs = {
  where: FavoriteWhereUniqueInput;
};


export type MutationAdminDeleteManyFavoriteArgs = {
  where?: Maybe<FavoriteWhereInput>;
};


export type MutationAdminCreateOnePurchaseArgs = {
  data: PurchaseCreateInput;
};


export type MutationAdminUpdateOnePurchaseArgs = {
  data: PurchaseUpdateInput;
  where: PurchaseWhereUniqueInput;
};


export type MutationAdminUpdateManyPurchaseArgs = {
  data: PurchaseUpdateManyMutationInput;
  where?: Maybe<PurchaseWhereInput>;
};


export type MutationAdminUpsertOnePurchaseArgs = {
  where: PurchaseWhereUniqueInput;
  create: PurchaseCreateInput;
  update: PurchaseUpdateInput;
};


export type MutationAdminDeleteOnePurchaseArgs = {
  where: PurchaseWhereUniqueInput;
};


export type MutationAdminDeleteManyPurchaseArgs = {
  where?: Maybe<PurchaseWhereInput>;
};


export type MutationPurchaseTitleArgs = {
  email: Scalars['String'];
  sku: Scalars['String'];
};


export type MutationAdminCreateOneActivationArgs = {
  data: ActivationCreateInput;
};


export type MutationAdminUpdateOneActivationArgs = {
  data: ActivationUpdateInput;
  where: ActivationWhereUniqueInput;
};


export type MutationAdminUpdateManyActivationArgs = {
  data: ActivationUpdateManyMutationInput;
  where?: Maybe<ActivationWhereInput>;
};


export type MutationAdminUpsertOneActivationArgs = {
  where: ActivationWhereUniqueInput;
  create: ActivationCreateInput;
  update: ActivationUpdateInput;
};


export type MutationAdminDeleteOneActivationArgs = {
  where: ActivationWhereUniqueInput;
};


export type MutationAdminDeleteManyActivationArgs = {
  where?: Maybe<ActivationWhereInput>;
};


export type MutationApplyPromocodeArgs = {
  email?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};


export type MutationApplySubscriptionArgs = {
  email?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
};


export type MutationDisassociateAnonymousProfileArgs = {
  anonymousId: Scalars['String'];
};


export type MutationMergeAnonymousProfileArgs = {
  anonymousId: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationAdminCreateOneProfileArgs = {
  data: ProfileCreateInput;
};


export type MutationAdminUpdateOneProfileArgs = {
  data: ProfileUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationAdminUpdateManyProfileArgs = {
  data: ProfileUpdateManyMutationInput;
  where?: Maybe<ProfileWhereInput>;
};


export type MutationAdminUpsertOneProfileArgs = {
  where: ProfileWhereUniqueInput;
  create: ProfileCreateInput;
  update: ProfileUpdateInput;
};


export type MutationAdminDeleteOneProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type MutationAdminDeleteManyProfileArgs = {
  where?: Maybe<ProfileWhereInput>;
};


export type MutationUpdateListenCountArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationAdminCreateOneListeningArgs = {
  data: ListeningCreateInput;
};


export type MutationAdminUpdateOneListeningArgs = {
  data: ListeningUpdateInput;
  where: ListeningWhereUniqueInput;
};


export type MutationAdminUpdateManyListeningArgs = {
  data: ListeningUpdateManyMutationInput;
  where?: Maybe<ListeningWhereInput>;
};


export type MutationAdminUpsertOneListeningArgs = {
  where: ListeningWhereUniqueInput;
  create: ListeningCreateInput;
  update: ListeningUpdateInput;
};


export type MutationAdminDeleteOneListeningArgs = {
  where: ListeningWhereUniqueInput;
};


export type MutationAdminDeleteManyListeningArgs = {
  where?: Maybe<ListeningWhereInput>;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationAdminCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationAdminUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationAdminUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: Maybe<UserWhereInput>;
};


export type MutationAdminUpsertOneUserArgs = {
  where: UserWhereUniqueInput;
  create: UserCreateInput;
  update: UserUpdateInput;
};


export type MutationAdminDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationAdminDeleteManyUserArgs = {
  where?: Maybe<UserWhereInput>;
};


export type MutationLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAdminCreateOneParameterArgs = {
  data: ParameterCreateInput;
};


export type MutationAdminUpdateOneParameterArgs = {
  data: ParameterUpdateInput;
  where: ParameterWhereUniqueInput;
};


export type MutationAdminUpdateManyParameterArgs = {
  data: ParameterUpdateManyMutationInput;
  where?: Maybe<ParameterWhereInput>;
};


export type MutationAdminUpsertOneParameterArgs = {
  where: ParameterWhereUniqueInput;
  create: ParameterCreateInput;
  update: ParameterUpdateInput;
};


export type MutationAdminDeleteOneParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type MutationAdminDeleteManyParameterArgs = {
  where?: Maybe<ParameterWhereInput>;
};


export type MutationAdminCreateOneQuestionArgs = {
  data: QuestionCreateInput;
};


export type MutationAdminUpdateOneQuestionArgs = {
  data: QuestionUpdateInput;
  where: QuestionWhereUniqueInput;
};


export type MutationAdminUpdateManyQuestionArgs = {
  data: QuestionUpdateManyMutationInput;
  where?: Maybe<QuestionWhereInput>;
};


export type MutationAdminUpsertOneQuestionArgs = {
  where: QuestionWhereUniqueInput;
  create: QuestionCreateInput;
  update: QuestionUpdateInput;
};


export type MutationAdminDeleteOneQuestionArgs = {
  where: QuestionWhereUniqueInput;
};


export type MutationAdminDeleteManyQuestionArgs = {
  where?: Maybe<QuestionWhereInput>;
};


export type MutationAdminCreateOneAccessArgs = {
  data: AccessCreateInput;
};


export type MutationAdminUpdateOneAccessArgs = {
  data: AccessUpdateInput;
  where: AccessWhereUniqueInput;
};


export type MutationAdminUpdateManyAccessArgs = {
  data: AccessUpdateManyMutationInput;
  where?: Maybe<AccessWhereInput>;
};


export type MutationAdminUpsertOneAccessArgs = {
  where: AccessWhereUniqueInput;
  create: AccessCreateInput;
  update: AccessUpdateInput;
};


export type MutationAdminDeleteOneAccessArgs = {
  where: AccessWhereUniqueInput;
};


export type MutationAdminDeleteManyAccessArgs = {
  where?: Maybe<AccessWhereInput>;
};


export type MutationAdminCreateOneInAppPurchaseArgs = {
  data: InAppPurchaseCreateInput;
};


export type MutationAdminUpdateOneInAppPurchaseArgs = {
  data: InAppPurchaseUpdateInput;
  where: InAppPurchaseWhereUniqueInput;
};


export type MutationAdminUpdateManyInAppPurchaseArgs = {
  data: InAppPurchaseUpdateManyMutationInput;
  where?: Maybe<InAppPurchaseWhereInput>;
};


export type MutationAdminUpsertOneInAppPurchaseArgs = {
  where: InAppPurchaseWhereUniqueInput;
  create: InAppPurchaseCreateInput;
  update: InAppPurchaseUpdateInput;
};


export type MutationAdminDeleteOneInAppPurchaseArgs = {
  where: InAppPurchaseWhereUniqueInput;
};


export type MutationAdminDeleteManyInAppPurchaseArgs = {
  where?: Maybe<InAppPurchaseWhereInput>;
};


export type MutationAdminCreateOneMagentaSubscriptionArgs = {
  data: MagentaSubscriptionCreateInput;
};


export type MutationAdminUpdateOneMagentaSubscriptionArgs = {
  data: MagentaSubscriptionUpdateInput;
  where: MagentaSubscriptionWhereUniqueInput;
};


export type MutationAdminUpdateManyMagentaSubscriptionArgs = {
  data: MagentaSubscriptionUpdateManyMutationInput;
  where?: Maybe<MagentaSubscriptionWhereInput>;
};


export type MutationAdminUpsertOneMagentaSubscriptionArgs = {
  where: MagentaSubscriptionWhereUniqueInput;
  create: MagentaSubscriptionCreateInput;
  update: MagentaSubscriptionUpdateInput;
};


export type MutationAdminDeleteOneMagentaSubscriptionArgs = {
  where: MagentaSubscriptionWhereUniqueInput;
};


export type MutationAdminDeleteManyMagentaSubscriptionArgs = {
  where?: Maybe<MagentaSubscriptionWhereInput>;
};


export type MutationAdminCreateOneDiscountArgs = {
  data: DiscountCreateInput;
};


export type MutationAdminUpdateOneDiscountArgs = {
  data: DiscountUpdateInput;
  where: DiscountWhereUniqueInput;
};


export type MutationAdminUpdateManyDiscountArgs = {
  data: DiscountUpdateManyMutationInput;
  where?: Maybe<DiscountWhereInput>;
};


export type MutationAdminUpsertOneDiscountArgs = {
  where: DiscountWhereUniqueInput;
  create: DiscountCreateInput;
  update: DiscountUpdateInput;
};


export type MutationAdminDeleteOneDiscountArgs = {
  where: DiscountWhereUniqueInput;
};


export type MutationAdminDeleteManyDiscountArgs = {
  where?: Maybe<DiscountWhereInput>;
};


export type MutationDiscountArgs = {
  showProposed: Scalars['Boolean'];
};


export type MutationAdminCreateOneDiscountProposalArgs = {
  data: DiscountProposalCreateInput;
};


export type MutationAdminUpdateOneDiscountProposalArgs = {
  data: DiscountProposalUpdateInput;
  where: DiscountProposalWhereUniqueInput;
};


export type MutationAdminUpdateManyDiscountProposalArgs = {
  data: DiscountProposalUpdateManyMutationInput;
  where?: Maybe<DiscountProposalWhereInput>;
};


export type MutationAdminUpsertOneDiscountProposalArgs = {
  where: DiscountProposalWhereUniqueInput;
  create: DiscountProposalCreateInput;
  update: DiscountProposalUpdateInput;
};


export type MutationAdminDeleteOneDiscountProposalArgs = {
  where: DiscountProposalWhereUniqueInput;
};


export type MutationAdminDeleteManyDiscountProposalArgs = {
  where?: Maybe<DiscountProposalWhereInput>;
};


export type MutationAdminCreateOneInAppPurchaseActivationArgs = {
  data: InAppPurchaseActivationCreateInput;
};


export type MutationAdminUpdateOneInAppPurchaseActivationArgs = {
  data: InAppPurchaseActivationUpdateInput;
  where: InAppPurchaseActivationWhereUniqueInput;
};


export type MutationAdminUpdateManyInAppPurchaseActivationArgs = {
  data: InAppPurchaseActivationUpdateManyMutationInput;
  where?: Maybe<InAppPurchaseActivationWhereInput>;
};


export type MutationAdminUpsertOneInAppPurchaseActivationArgs = {
  where: InAppPurchaseActivationWhereUniqueInput;
  create: InAppPurchaseActivationCreateInput;
  update: InAppPurchaseActivationUpdateInput;
};


export type MutationAdminDeleteOneInAppPurchaseActivationArgs = {
  where: InAppPurchaseActivationWhereUniqueInput;
};


export type MutationAdminDeleteManyInAppPurchaseActivationArgs = {
  where?: Maybe<InAppPurchaseActivationWhereInput>;
};


export type MutationTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationMigrateFirebaseTokenArgs = {
  uid: Scalars['String'];
};


export type MutationSendVerificationArgs = {
  email: Scalars['String'];
  anonymousId?: Maybe<Scalars['String']>;
};


export type MutationLoginWithVerificationArgs = {
  email: Scalars['String'];
  verification: Scalars['String'];
};


export type MutationLoginWithAnonymousIdArgs = {
  anonymousId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activation?: Maybe<Activation>;
  profileCreated?: Maybe<ProfilePayload>;
};


export type SubscriptionActivationArgs = {
  profileId?: Maybe<Scalars['String']>;
};


export type SubscriptionProfileCreatedArgs = {
  anonymousId: Scalars['String'];
};

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string } };

export type UploadMutationVariables = Exact<{
  name: Scalars['String'];
  duration: Scalars['Float'];
  contentType?: Maybe<Scalars['String']>;
}>;


export type UploadMutation = { __typename?: 'Mutation', upload?: Maybe<{ __typename?: 'FilePayload', url: string, file: { __typename?: 'File', id: string, name: string, path: string, mimetype: string, duration?: Maybe<number> } }> };


export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UploadDocument = gql`
    mutation upload($name: String!, $duration: Float!, $contentType: String) {
  upload(name: $name, duration: $duration, contentType: $contentType) {
    file {
      id
      name
      path
      mimetype
      duration
    }
    url
  }
}
    `;
export type UploadMutationFn = Apollo.MutationFunction<UploadMutation, UploadMutationVariables>;

/**
 * __useUploadMutation__
 *
 * To run a mutation, you first call `useUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMutation, { data, loading, error }] = useUploadMutation({
 *   variables: {
 *      name: // value for 'name'
 *      duration: // value for 'duration'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUploadMutation(baseOptions?: Apollo.MutationHookOptions<UploadMutation, UploadMutationVariables>) {
        return Apollo.useMutation<UploadMutation, UploadMutationVariables>(UploadDocument, baseOptions);
      }
export type UploadMutationHookResult = ReturnType<typeof useUploadMutation>;
export type UploadMutationResult = Apollo.MutationResult<UploadMutation>;
export type UploadMutationOptions = Apollo.BaseMutationOptions<UploadMutation, UploadMutationVariables>;