import React from 'react';
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  minLength,
  NumberInput,
  ReferenceField,
  required,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {QuestionScreen} from '../generated/graphql';
import {FormValuesContextProvider} from '../utils/FormValuesContext';
import {validatePosition} from '../utils/validatePosition';

const questionKeys = [
  {id: QuestionScreen.More, name: QuestionScreen.More},
  {id: QuestionScreen.Story, name: QuestionScreen.Story},
];

export const QuestionList = (props: any) => (
  <HNList {...props}>
    <Datagrid>
      <TextField source="body" />
      <TextField source="position" />
      <ReferenceField label="Parent" source="parent" reference="Question">
        <TextField source="body" />
      </ReferenceField>
      <TextField source="screen" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const validateText = [required(), minLength(1)];

const QuestionForm = (props: any) => (
  <HNForm {...props}>
    <TextInput source="body" validate={validateText} multiline fullWidth />
    <NumberInput source="position" validate={validatePosition} />
    <HNReferenceInput source="parent" reference="Question" allowEmpty>
      <SelectInput optionText="body" />
    </HNReferenceInput>
    <SelectInput source="screen" choices={questionKeys} />
  </HNForm>
);

const QuestionTitle = ({record}: any) => (
  <span>
    {record && record.body ? `Question "${record.body}"` : 'Create Question'}
  </span>
);

export const QuestionCreate = (props: any) => (
  <FormValuesContextProvider>
    <Create title={<QuestionTitle />} {...props}>
      <QuestionForm />
    </Create>
  </FormValuesContextProvider>
);

export const QuestionEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<QuestionTitle />} {...props}>
      <QuestionForm />
    </Edit>
  </FormValuesContextProvider>
);
