import React from 'react';
import {
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import HNReferenceInput from '../components/HNReferenceInput';
import {FormValuesContextProvider} from '../utils/FormValuesContext';

export const MagentaSubscriptionList = (props: any) => (
  <HNList {...props} sort={{field: 'activatedAt', order: 'DESC'}}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="sku" />
      <ReferenceField reference="Access" source="access" label="Access">
        <ChipField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </HNList>
);

const MagentaSubscriptionTitle = ({record}: any) => (
  <span>
    {record && record.access && record.name
      ? `Magenta subscription "${record.access.name}"`
      : 'Create Magenta subscription'}
  </span>
);

const MagentaSubscriptionForm = (props: any) => (
  <HNForm {...props}>
    <TextInput source="name" isRequired />
    <TextInput source="sku" isRequired />
    <HNReferenceInput source="access" reference="Access">
      <SelectInput optionText="name" />
    </HNReferenceInput>
  </HNForm>
);

export const MagentaSubscriptionCreate = (props: any) => (
  <Create title={<MagentaSubscriptionTitle />} {...props}>
    <MagentaSubscriptionForm />
  </Create>
);

export const MagentaSubscriptionEdit = (props: any) => (
  <FormValuesContextProvider>
    <Edit title={<MagentaSubscriptionTitle />} {...props}>
      <MagentaSubscriptionForm />
    </Edit>
  </FormValuesContextProvider>
);
