import React from 'react';
import {Datagrid, DeleteButton, TextField} from 'react-admin';
import HNList from '../components/HNList';

export const FeedbackList = (props: any) => (
  <HNList {...props} sort={{field: 'date', order: 'DESC'}}>
    <Datagrid>
      <TextField label="Email" source="email" />
      <TextField label="Phone" source="phone" />
      <TextField source="date" />
      <TextField label="Message" source="message" />
      <DeleteButton />
    </Datagrid>
  </HNList>
);
