import React from 'react';
import {ReferenceInput} from 'react-admin';

const HNReferenceInput: React.FC<any> = ({children, ...props}: any) => (
  <ReferenceInput perPage={300} {...props}>
    {children}
  </ReferenceInput>
);

export default HNReferenceInput;
