import * as R from 'ramda';
import React from 'react';
import {
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  FormDataConsumer,
  NumberInput,
  TextInput,
} from 'react-admin';
import {ColorInput} from 'react-admin-color-input';
import HNForm from '../components/HNForm';
import HNList from '../components/HNList';
import {ParameterKey} from '../generated/graphql';
import validateColor from '../utils/validateColor';
import {validatePosition} from '../utils/validatePosition';

const keys: Record<ParameterKey, {id: ParameterKey; name: string}> = {
  [ParameterKey.CardColor]: {
    id: ParameterKey.CardColor,
    name: 'My Collection color',
  },
  [ParameterKey.LoginConfirmationText]: {
    id: ParameterKey.LoginConfirmationText,
    name: 'Login confirmation text',
  },
  [ParameterKey.ContactInfoText]: {
    id: ParameterKey.ContactInfoText,
    name: 'Contact info text',
  },
  [ParameterKey.OnboardingTextSlide_1]: {
    id: ParameterKey.OnboardingTextSlide_1,
    name: 'Onboarding text slide 1',
  },
  [ParameterKey.OnboardingTextSlide_2]: {
    id: ParameterKey.OnboardingTextSlide_2,
    name: 'Onboarding text slide 2',
  },
  [ParameterKey.OnboardingTextSlide_3]: {
    id: ParameterKey.OnboardingTextSlide_3,
    name: 'Onboarding text slide 3',
  },
  [ParameterKey.OnboardingTextSlide_4]: {
    id: ParameterKey.OnboardingTextSlide_4,
    name: 'Onboarding text slide 4',
  },
  [ParameterKey.OnboardingTextSlide_5]: {
    id: ParameterKey.OnboardingTextSlide_5,
    name: 'Onboarding text slide 5',
  },
  [ParameterKey.OnboardingDescriptionSlide_1]: {
    id: ParameterKey.OnboardingDescriptionSlide_1,
    name: 'Onboarding description slide 1',
  },
  [ParameterKey.OnboardingDescriptionSlide_2]: {
    id: ParameterKey.OnboardingDescriptionSlide_2,
    name: 'Onboarding description slide 2',
  },
  [ParameterKey.OnboardingDescriptionSlide_3]: {
    id: ParameterKey.OnboardingDescriptionSlide_3,
    name: 'Onboarding description slide 3',
  },
  [ParameterKey.OnboardingDescriptionSlide_4]: {
    id: ParameterKey.OnboardingDescriptionSlide_4,
    name: 'Onboarding description slide 4',
  },
  [ParameterKey.OnboardingDescriptionSlide_5]: {
    id: ParameterKey.OnboardingDescriptionSlide_5,
    name: 'Onboarding description slide 5',
  },
  [ParameterKey.HeaderFontSize]: {
    id: ParameterKey.HeaderFontSize,
    name: 'Header font size',
  },
  [ParameterKey.HeaderText]: {id: ParameterKey.HeaderText, name: 'Header text'},
  [ParameterKey.MyCollectionRemoveDescription]: {
    id: ParameterKey.MyCollectionRemoveDescription,
    name: 'Remove from my collection description',
  },
  [ParameterKey.MyCollectionRemoveHeader]: {
    id: ParameterKey.MyCollectionRemoveHeader,
    name: 'Remove from my collection header',
  },
  [ParameterKey.FeedbackEmail]: {
    id: ParameterKey.FeedbackEmail,
    name: 'Email to send feedback to',
  },
  [ParameterKey.PurchaseText]: {
    id: ParameterKey.PurchaseText,
    name: 'Purchase text',
  },

  [ParameterKey.PlayerIntroHeader]: {
    id: ParameterKey.PlayerIntroHeader,
    name: 'Player intro header',
  },
  [ParameterKey.PlayerIntroDescription]: {
    id: ParameterKey.PlayerIntroDescription,
    name: 'Player intro description',
  },
  [ParameterKey.ShowPromo]: {
    id: ParameterKey.ShowPromo,
    name: 'Show Promo',
  },
  [ParameterKey.LoginText]: {
    id: ParameterKey.LoginText,
    name: 'Login Text',
  },
  [ParameterKey.PadlockIcon]: {
    id: ParameterKey.PadlockIcon,
    name: 'Show Padlock Icon',
  },

  [ParameterKey.FreeTitlesPopupTitle]: {
    id: ParameterKey.FreeTitlesPopupTitle,
    name: 'Free titles title',
  },
  [ParameterKey.FreeTitlesPopupMarkdown]: {
    id: ParameterKey.FreeTitlesPopupMarkdown,
    name: 'Free titles content',
  },
  [ParameterKey.FreeTitlesPopupDuration]: {
    id: ParameterKey.FreeTitlesPopupDuration,
    name: 'Delay in sec before showing free titles popup',
  },
  [ParameterKey.AndroidSubscriptionMarkdown]: {
    id: ParameterKey.AndroidSubscriptionMarkdown,
    name: 'Android Subscription Markdown',
  },
  [ParameterKey.IosSubscriptionMarkdown]: {
    id: ParameterKey.IosSubscriptionMarkdown,
    name: 'IOS Subscription Markdown',
  },
  [ParameterKey.DaysAfterLastDiscount]: {
    id: ParameterKey.DaysAfterLastDiscount,
    name: 'Days after previous upgrade popup',
  },
  [ParameterKey.SubscriptionReminderText]: {
    id: ParameterKey.SubscriptionReminderText,
    name: 'Subscription notification email body($subscription$ is where subscription name will be inserted. $name$ is where username will be inserted)',
  },
  [ParameterKey.SubscriptionReminderTitle]: {
    id: ParameterKey.SubscriptionReminderTitle,
    name: 'Subscription notification email subject',
  },
  [ParameterKey.IapSubscriptionUpgradeText]: {
    id: ParameterKey.IapSubscriptionUpgradeText,
    name: 'Subscription upgrade email body($subscription$ is where subscription name will be inserted. $name$ is where username will be inserted)',
  },
  [ParameterKey.IapSubscriptionUpgradeTitle]: {
    id: ParameterKey.IapSubscriptionUpgradeTitle,
    name: 'Subscription upgrade email subject',
  },
  [ParameterKey.WebsiteSubscriptionAllTitlesText]: {
    id: ParameterKey.WebsiteSubscriptionAllTitlesText,
    name: 'Website subscription all titles email body',
  },
  [ParameterKey.WebsiteSubscriptionAllTitlesTitle]: {
    id: ParameterKey.WebsiteSubscriptionAllTitlesTitle,
    name: 'Website subscription all titles email subject',
  },
  [ParameterKey.WebsiteSubscriptionUpgradeText]: {
    id: ParameterKey.WebsiteSubscriptionUpgradeText,
    name: 'Website subscription non-all titles upgrade email body',
  },
  [ParameterKey.WebsiteSubscriptionUpgradeTitle]: {
    id: ParameterKey.WebsiteSubscriptionUpgradeTitle,
    name: 'Website subscription non-all titles upgrade email subject',
  },
  [ParameterKey.EnableWebsiteSubscriptionEmail]: {
    id: ParameterKey.EnableWebsiteSubscriptionEmail,
    name: 'Enable website subscription emails(Y/N)',
  },
  [ParameterKey.LoginAfterIapHeader]: {
    id: ParameterKey.LoginAfterIapHeader,
    name: 'Login after IAP Header',
  },
  [ParameterKey.LoginAfterIapDescription]: {
    id: ParameterKey.LoginAfterIapDescription,
    name: 'Login after IAP Description',
  },
  [ParameterKey.EnableWebsiteSubscriptionEmail]: {
    id: ParameterKey.EnableWebsiteSubscriptionEmail,
    name: 'Enable website subscription emails(Y/N)',
  },
  [ParameterKey.CodeVereficationText]: {
    id: ParameterKey.CodeVereficationText,
    name: 'Code Verification Text',
  },
  [ParameterKey.ShowTutorial]: {
    id: ParameterKey.ShowTutorial,
    name: 'Show tutorial(Y/N)',
  },
  [ParameterKey.ManageSubscriptionsText]: {
    id: ParameterKey.ManageSubscriptionsText,
    name: 'Manage subscriptions text',
  },
  [ParameterKey.DeleteAccountButton]: {
    id: ParameterKey.DeleteAccountButton,
    name: 'Delete account button',
  },
  [ParameterKey.DeleteAccountTitle]: {
    id: ParameterKey.DeleteAccountTitle,
    name: 'Delete account header',
  },
  [ParameterKey.DeleteAccountDescription]: {
    id: ParameterKey.DeleteAccountDescription,
    name: 'Delete account description',
  },
};

interface ParameterKeyFieldProps {
  record?: {
    key: ParameterKey;
  };
  source?: string;
}
const ParameterKeyField: React.FC<ParameterKeyFieldProps> = ({record}) => (
  <span>{record?.key && keys[record.key]?.name}</span>
);
const ParameterValueField: React.FC<any> = ({record}) => (
  <span>{record?.notUsed ? 'Not set' : record?.value}</span>
);

export const ParameterList = (props: any) => (
  <HNList {...props} bulkActionButtons={false}>
    <Datagrid>
      <ParameterKeyField source="key" />
      <ParameterValueField source="value" />
      <EditButton />
    </Datagrid>
  </HNList>
);

const ParameterForm = (props: any) => (
  <HNForm {...props}>
    <FormDataConsumer>
      {({formData}: {formData: any}) => {
        switch (formData.key) {
          case ParameterKey.CardColor:
            return (
              <ColorInput
                label="Colour"
                source="value"
                picker="Sketch"
                validate={validateColor}
              />
            );
          case ParameterKey.HeaderFontSize:
            return (
              <NumberInput
                source="value"
                parse={(v: number) => `${v}`}
                format={(v: string) => +v}
                validate={validatePosition}
              />
            );
          case ParameterKey.CodeVereficationText:
          case ParameterKey.SubscriptionReminderText:
          case ParameterKey.MyCollectionRemoveDescription:
          case ParameterKey.PlayerIntroDescription:
          case ParameterKey.FreeTitlesPopupMarkdown:
          case ParameterKey.IosSubscriptionMarkdown:
          case ParameterKey.AndroidSubscriptionMarkdown:
          case ParameterKey.WebsiteSubscriptionAllTitlesText:
          case ParameterKey.WebsiteSubscriptionAllTitlesTitle:
          case ParameterKey.WebsiteSubscriptionUpgradeText:
          case ParameterKey.WebsiteSubscriptionUpgradeTitle:
          case ParameterKey.IapSubscriptionUpgradeText:
          case ParameterKey.IapSubscriptionUpgradeTitle:
          case ParameterKey.LoginAfterIapHeader:
          case ParameterKey.LoginAfterIapDescription:
          case ParameterKey.DeleteAccountTitle:
          case ParameterKey.DeleteAccountDescription:
            return <TextInput source="value" multiline fullWidth />;
          default:
            return <TextInput source="value" fullWidth />;
        }
      }}
    </FormDataConsumer>
    <BooleanInput source="notUsed" />
  </HNForm>
);

const ParameterTitle = ({
  record,
}: {
  record?: {
    key: ParameterKey;
  };
}) => {
  return (
    <span>
      {record && record.key
        ? `Parameter "${keys[record.key].name}"`
        : 'Create Parameter'}
    </span>
  );
};

export const ParameterEdit = (props: any) => (
  <Edit title={<ParameterTitle />} {...props}>
    <ParameterForm />
  </Edit>
);
